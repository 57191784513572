/* tslint:disable */
/* eslint-disable */
/**
 * MTS API
 * Member Tree System REST APIs
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 資格・認定保持者詳細
 * @export
 * @interface AcquisitionQualificationInfo
 */
export interface AcquisitionQualificationInfo {
    /**
     * ID
     * @type {number}
     * @memberof AcquisitionQualificationInfo
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof AcquisitionQualificationInfo
     */
    conferenceDoctorsId: number;
    /**
     * 資格・認定ID
     * @type {number}
     * @memberof AcquisitionQualificationInfo
     */
    qualificationsId: number;
    /**
     * 資格・認定番号
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    qualificationNumber: string;
    /**
     * 取得年月日
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    dateFrom: string;
    /**
     * 次回更新年月日
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    dateTo?: string;
    /**
     * 更新年月日
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    updateDate?: string;
    /**
     * 状態
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    status: string;
    /**
     * 備考
     * @type {string}
     * @memberof AcquisitionQualificationInfo
     */
    note?: string;
}
/**
 * 資格・認定保持者概要
 * @export
 * @interface AcquisitionQualificationOverview
 */
export interface AcquisitionQualificationOverview {
    /**
     * ID
     * @type {number}
     * @memberof AcquisitionQualificationOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof AcquisitionQualificationOverview
     */
    conferenceDoctorsId: number;
    /**
     * 資格・認定ID
     * @type {number}
     * @memberof AcquisitionQualificationOverview
     */
    qualificationsId: number;
    /**
     * 資格・認定番号
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    qualificationNumber: string;
    /**
     * 取得年月日
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    dateFrom: string;
    /**
     * 次回更新年月日
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    dateTo?: string;
    /**
     * 更新年月日
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    updateDate?: string;
    /**
     * 状態
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    status: string;
    /**
     * 備考
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    note?: string;
    /**
     * 
     * @type {DoctorDetail}
     * @memberof AcquisitionQualificationOverview
     */
    memberDetail?: DoctorDetail;
    /**
     * 勤務先
     * @type {Array<WorkAddress>}
     * @memberof AcquisitionQualificationOverview
     */
    workAddresses?: Array<WorkAddress>;
    /**
     * 資格・認定名
     * @type {string}
     * @memberof AcquisitionQualificationOverview
     */
    qualificationName?: string;
}
/**
 * 運営管理情報詳細
 * @export
 * @interface AdminDetail
 */
export interface AdminDetail {
    /**
     * ID
     * @type {number}
     * @memberof AdminDetail
     */
    id?: number;
    /**
     * 会社名
     * @type {string}
     * @memberof AdminDetail
     */
    companyName: string;
    /**
     * 事業所名
     * @type {string}
     * @memberof AdminDetail
     */
    officeName: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof AdminDetail
     */
    postalCode: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof AdminDetail
     */
    prefectures: string;
    /**
     * 住所1
     * @type {string}
     * @memberof AdminDetail
     */
    address1: string;
    /**
     * 住所2(マンション・ビル名)
     * @type {string}
     * @memberof AdminDetail
     */
    address2?: string;
    /**
     * TEL
     * @type {string}
     * @memberof AdminDetail
     */
    tel: string;
    /**
     * FAX
     * @type {string}
     * @memberof AdminDetail
     */
    fax?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminDetail
     */
    mail: string;
    /**
     * 問い合わせ時間FROM
     * @type {string}
     * @memberof AdminDetail
     */
    receptionTimeFrom: string;
    /**
     * 問い合わせ時間TO
     * @type {string}
     * @memberof AdminDetail
     */
    receptionTimeTo: string;
    /**
     * 署名
     * @type {string}
     * @memberof AdminDetail
     */
    signature?: string;
}
/**
 * 運営からのお知らせ詳細
 * @export
 * @interface AdminInfoDetail
 */
export interface AdminInfoDetail {
    /**
     * ID
     * @type {number}
     * @memberof AdminInfoDetail
     */
    id?: number;
    /**
     * タイトル
     * @type {string}
     * @memberof AdminInfoDetail
     */
    title: string;
    /**
     * 本文
     * @type {string}
     * @memberof AdminInfoDetail
     */
    content?: string;
    /**
     * お知らせ対象のサービス会員ID
     * @type {string}
     * @memberof AdminInfoDetail
     */
    conferenceDoctorsIdCsv?: string;
    /**
     * お知らせ対象の学会ID
     * @type {string}
     * @memberof AdminInfoDetail
     */
    conferenceIdCsv?: string;
    /**
     * 配信日時
     * @type {string}
     * @memberof AdminInfoDetail
     */
    sendDatetime?: string;
    /**
     * 掲載終了日
     * @type {string}
     * @memberof AdminInfoDetail
     */
    endDate?: string;
    /**
     * お知らせ配信通知メールを送信するか否か
     * @type {boolean}
     * @memberof AdminInfoDetail
     */
    infoNoticeMailFlag?: boolean;
}
/**
 * 運営からのお知らせ概要
 * @export
 * @interface AdminInfoOverview
 */
export interface AdminInfoOverview {
    /**
     * ID
     * @type {number}
     * @memberof AdminInfoOverview
     */
    id?: number;
    /**
     * 配信日時
     * @type {string}
     * @memberof AdminInfoOverview
     */
    sendDatetime?: string;
    /**
     * タイトル
     * @type {string}
     * @memberof AdminInfoOverview
     */
    title?: string;
    /**
     * 内容
     * @type {string}
     * @memberof AdminInfoOverview
     */
    content?: string;
}
/**
 * 運営管理ユーザー詳細
 * @export
 * @interface AdminUserDetail
 */
export interface AdminUserDetail {
    /**
     * ID
     * @type {number}
     * @memberof AdminUserDetail
     */
    id?: number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof AdminUserDetail
     */
    user: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminUserDetail
     */
    mail: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof AdminUserDetail
     */
    tel?: string;
    /**
     * 管理者区分
     * @type {string}
     * @memberof AdminUserDetail
     */
    type?: AdminUserDetailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUserDetailTypeEnum {
    Administrator = 'administrator',
    General = 'general'
}

/**
 * 運営管理ユーザ情報
 * @export
 * @interface AdminUserInfo
 */
export interface AdminUserInfo {
    /**
     * ID
     * @type {number}
     * @memberof AdminUserInfo
     */
    id?: number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof AdminUserInfo
     */
    user?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminUserInfo
     */
    mail?: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof AdminUserInfo
     */
    newPassword?: string;
}
/**
 * 運営管理ユーザープロファイル
 * @export
 * @interface AdminUserProfile
 */
export interface AdminUserProfile {
    /**
     * 運営管理ユーザーID
     * @type {number}
     * @memberof AdminUserProfile
     */
    id?: number;
    /**
     * ユーザー名
     * @type {string}
     * @memberof AdminUserProfile
     */
    user?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AdminUserProfile
     */
    mail?: string;
    /**
     * 権限種別
     * @type {string}
     * @memberof AdminUserProfile
     */
    type?: AdminUserProfileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminUserProfileTypeEnum {
    Administrator = 'administrator',
    General = 'general'
}

/**
 * 年会費入金状態
 * @export
 * @interface AnnualFeeOverview
 */
export interface AnnualFeeOverview {
    /**
     * ID
     * @type {number}
     * @memberof AnnualFeeOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof AnnualFeeOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    firstNameEn?: string;
    /**
     * 年度
     * @type {number}
     * @memberof AnnualFeeOverview
     */
    fiscalYear?: number;
    /**
     * 内容
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    paymentContent?: string;
    /**
     * 請求額
     * @type {number}
     * @memberof AnnualFeeOverview
     */
    billAmount?: number;
    /**
     * 入金状態
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    paymentStatus?: string;
    /**
     * 入金方法
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    paymentType?: string;
    /**
     * 入金日
     * @type {string}
     * @memberof AnnualFeeOverview
     */
    paymentDate?: string;
}
/**
 * 会員番号の重複チェックパラメータ
 * @export
 * @interface CheckConferenceNumberParam
 */
export interface CheckConferenceNumberParam {
    /**
     * 会員番号
     * @type {string}
     * @memberof CheckConferenceNumberParam
     */
    conferenceNumber: string;
    /**
     * 重複を無視するID
     * @type {number}
     * @memberof CheckConferenceNumberParam
     */
    ignoreId?: number;
}
/**
 * メールアドレスの重複チェックパラメータ
 * @export
 * @interface CheckEmailParam
 */
export interface CheckEmailParam {
    /**
     * メールアドレス
     * @type {string}
     * @memberof CheckEmailParam
     */
    email: string;
    /**
     * 重複を無視するID
     * @type {number}
     * @memberof CheckEmailParam
     */
    ignoreId?: number;
}
/**
 * サブドメインの重複チェックパラメータ
 * @export
 * @interface CheckSubdomainParam
 */
export interface CheckSubdomainParam {
    /**
     * サブドメイン
     * @type {string}
     * @memberof CheckSubdomainParam
     */
    subdomain: string;
    /**
     * 重複を無視する学会ID
     * @type {number}
     * @memberof CheckSubdomainParam
     */
    ignoreId?: number;
}
/**
 * 学会情報(サービス会員表示分)
 * @export
 * @interface ConferenceDetail
 */
export interface ConferenceDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceDetail
     */
    id?: number;
    /**
     * マネジメントID
     * @type {string}
     * @memberof ConferenceDetail
     */
    managementId?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof ConferenceDetail
     */
    juridicalPersonality?: string;
    /**
     * 学会名
     * @type {string}
     * @memberof ConferenceDetail
     */
    term: string;
    /**
     * 学会名カナ
     * @type {string}
     * @memberof ConferenceDetail
     */
    termKana?: string;
    /**
     * 学会名英語
     * @type {string}
     * @memberof ConferenceDetail
     */
    termEn?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof ConferenceDetail
     */
    postalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof ConferenceDetail
     */
    prefectures?: string;
    /**
     * アドレス1
     * @type {string}
     * @memberof ConferenceDetail
     */
    address1?: string;
    /**
     * アドレス2
     * @type {string}
     * @memberof ConferenceDetail
     */
    address2?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof ConferenceDetail
     */
    tel?: string;
    /**
     * FAX
     * @type {string}
     * @memberof ConferenceDetail
     */
    fax?: string;
    /**
     * 受信用メールアドレス
     * @type {string}
     * @memberof ConferenceDetail
     */
    mail?: string;
    /**
     * URL
     * @type {string}
     * @memberof ConferenceDetail
     */
    url?: string;
    /**
     * インボイス登録番号
     * @type {string}
     * @memberof ConferenceDetail
     */
    invoiceNumber?: string;
    /**
     * 問い合わせ時間From
     * @type {string}
     * @memberof ConferenceDetail
     */
    receptionTimeFrom?: string;
    /**
     * 問い合わせ時間To
     * @type {string}
     * @memberof ConferenceDetail
     */
    receptionTimeTo?: string;
    /**
     * サービス開始年月日
     * @type {string}
     * @memberof ConferenceDetail
     */
    entryDate?: string;
    /**
     * 有効期限年月日
     * @type {string}
     * @memberof ConferenceDetail
     */
    expirationDate?: string;
    /**
     * サービス利用終了年月日
     * @type {string}
     * @memberof ConferenceDetail
     */
    endDate?: string;
    /**
     * サービス廃止年月日
     * @type {string}
     * @memberof ConferenceDetail
     */
    abolitionDate?: string;
    /**
     * 支払い方法
     * @type {string}
     * @memberof ConferenceDetail
     */
    paymentType?: string;
    /**
     * 追加アップロードサイズ
     * @type {string}
     * @memberof ConferenceDetail
     */
    addFileUploadSize?: string;
    /**
     * 年度
     * @type {number}
     * @memberof ConferenceDetail
     */
    fiscalYear?: number;
    /**
     * 年度開始月
     * @type {number}
     * @memberof ConferenceDetail
     */
    fiscalYearStartMonth?: number;
    /**
     * 年度開始日
     * @type {number}
     * @memberof ConferenceDetail
     */
    fiscalYearStartDay?: number;
    /**
     * 会員歴更新処理の種類
     * @type {string}
     * @memberof ConferenceDetail
     */
    memberYearsAddType?: string;
    /**
     * メンテナスフラグ
     * @type {boolean}
     * @memberof ConferenceDetail
     */
    maintenanceFlg?: boolean;
    /**
     * ロゴファイル名
     * @type {string}
     * @memberof ConferenceDetail
     */
    logoFileName?: string;
    /**
     * 角印印影ファイル名
     * @type {string}
     * @memberof ConferenceDetail
     */
    stampFileName?: string;
    /**
     * 利用規約表示設定フラグ
     * @type {boolean}
     * @memberof ConferenceDetail
     */
    termsOfServiceFlg?: boolean;
    /**
     * 演題システムの利用フラグ
     * @type {boolean}
     * @memberof ConferenceDetail
     */
    useEndaiFlg?: boolean;
    /**
     * 学会/その他組織の表記フラグ
     * @type {string}
     * @memberof ConferenceDetail
     */
    organization?: string;
    /**
     * 販売事業者(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawCompany?: string;
    /**
     * 代表責任者(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawResponsiblePerson?: string;
    /**
     * 所在地(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawAddress?: string;
    /**
     * 電話番号(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawTel?: string;
    /**
     * FAX(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawFax?: string;
    /**
     * メールアドレス(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawMail?: string;
    /**
     * ホームページURL(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawUrl?: string;
    /**
     * 取り扱い品(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawProduct?: string;
    /**
     * 販売価格(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawPrice?: string;
    /**
     * 商品代金以外の必要料金(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawOtherPrice?: string;
    /**
     * 支払い方法(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawPayment?: string;
    /**
     * 代金の支払い時期(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawPaymentTime?: string;
    /**
     * 役務提供時期(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawServiceAvailability?: string;
    /**
     * キャンセル・返金について(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawCancel?: string;
    /**
     * 決済業務等の外部委託(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceDetail
     */
    lawOutsourcing?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ConferenceDetail
     */
    remarks?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDetail
     */
    indicateInvoice?: boolean;
}
/**
 * サービス会員詳細
 * @export
 * @interface ConferenceDoctorDetail
 */
export interface ConferenceDoctorDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    conferencesId: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    firstNameEn?: string;
    /**
     * 性別
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    sex?: string;
    /**
     * 職種ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    jobId?: number;
    /**
     * 生年月日
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    birthday?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    mail: string;
    /**
     * サブメールアドレス
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    subMail?: string;
    /**
     * サブメールアドレス宛にもメールを送付するか否か
     * @type {boolean}
     * @memberof ConferenceDoctorDetail
     */
    sendToSubMailFlag?: boolean;
    /**
     * 国家資格番号(医籍登録番号など)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    doctorNumber?: string;
    /**
     * 最終学歴
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    finalEducation?: string;
    /**
     * 卒業大学
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    graduatedUniversity?: string;
    /**
     * 卒業年
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    graduatedYear?: number;
    /**
     * 医師免許取得年
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    getDoctorLicenseYear?: number;
    /**
     * 基本領域ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    medicalSpecialtyCategoriesId?: number;
    /**
     * 専門領域詳細
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    medicalSpecialtyDetailsIdCsv?: string;
    /**
     * 基本領域備考
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    medicalSpecialty?: string;
    /**
     * 専門領域・職種ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    conferenceSpecializedAreasId?: number;
    /**
     * 専門領域備考
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    conferenceSpecializedAreas?: string;
    /**
     * 送付先
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    destination?: string;
    /**
     * 郵便番号(海外在住は任意)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    postalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    prefectures?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    address1?: string;
    /**
     * 住所2(マンション・ビル名)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    address2?: string;
    /**
     * TEL
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    tel?: string;
    /**
     * 携帯
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    mobilePhone?: string;
    /**
     * FAX
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    fax?: string;
    /**
     * 在住区分
     * @type {boolean}
     * @memberof ConferenceDoctorDetail
     */
    destinationIsLivingAbroad?: boolean;
    /**
     * 郵便番号(海外在住は任意)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    destinationPostalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    destinationPrefectures?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    destinationAddress1?: string;
    /**
     * 住所2(マンション・ビル名)
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    destinationAddress2?: string;
    /**
     * 会員状態
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    status: string;
    /**
     * 停止年月日
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    stopDate?: string;
    /**
     * 退会詳細メモ
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    stopReason?: string;
    /**
     * 会員承認可否
     * @type {boolean}
     * @memberof ConferenceDoctorDetail
     */
    approvalFlag?: boolean;
    /**
     * 入会年月日
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    entryDate: string;
    /**
     * 会員歴(年)
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    memberYears?: number;
    /**
     * 入金督促開始日
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    expirationDate?: string;
    /**
     * 会員番号
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    conferenceNumber?: string;
    /**
     * 会員・会費種別ID
     * @type {number}
     * @memberof ConferenceDoctorDetail
     */
    memberTypesId?: number;
    /**
     * 留学中か否か
     * @type {boolean}
     * @memberof ConferenceDoctorDetail
     */
    studyAbroad?: boolean;
    /**
     * 備考1
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    remarks1?: string;
    /**
     * 備考2
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    remarks2?: string;
    /**
     * 備考3
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    remarks3?: string;
    /**
     * 備考4
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    remarks4?: string;
    /**
     * 備考5
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    remarks5?: string;
    /**
     * 自動支払ステータス
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    recurringCreditStatus?: string;
    /**
     * GMO会員ID
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    gmoMemberId?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof ConferenceDoctorDetail
     */
    modified?: string;
    /**
     * 勤務先
     * @type {Array<WorkAddress>}
     * @memberof ConferenceDoctorDetail
     */
    workAddresses?: Array<WorkAddress>;
    /**
     * 在住区分
     * @type {boolean}
     * @memberof ConferenceDoctorDetail
     */
    isLivingAbroad?: boolean;
}
/**
 * サービス会員プロファイル情報
 * @export
 * @interface ConferenceDoctorUserProfile
 */
export interface ConferenceDoctorUserProfile {
    /**
     * サービス会員ID
     * @type {number}
     * @memberof ConferenceDoctorUserProfile
     */
    id?: number;
    /**
     * 学会会員ID
     * @type {number}
     * @memberof ConferenceDoctorUserProfile
     */
    conferenceDoctorsId?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceDoctorUserProfile
     */
    conferencesId?: number;
    /**
     * 学会名
     * @type {string}
     * @memberof ConferenceDoctorUserProfile
     */
    conferenceName?: string;
    /**
     * 組織種類
     * @type {string}
     * @memberof ConferenceDoctorUserProfile
     */
    organization?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ConferenceDoctorUserProfile
     */
    mail?: string;
}
/**
 * 
 * @export
 * @interface ConferenceDoctors
 */
export interface ConferenceDoctors {
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    conferencesId?: number;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    doctorsId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    lastNameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    firstNameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    lastNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    firstNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    sex?: ConferenceDoctorsSexEnum;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    job?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    prefectures?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    tel?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    doctorNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    graduatedUniversity?: string;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    graduatedYear?: number;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    getDoctorLicenseYear?: number;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    medicalSpecialtyCategoriesId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    medicalSpecialtyDetailsIdCsv?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    medicalSpecialty?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    entryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    expirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    conferenceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    memberTypesId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    approvalFlag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    status?: ConferenceDoctorsStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    studyAbroad?: string;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    memberYears?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    destination?: ConferenceDoctorsDestinationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    destinationIsLivingAbroad?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    destinationPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    destinationPrefectures?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    destinationAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    destinationAddress2?: string;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    conferenceSpecializedAreasId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    stopDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    stopReason?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    remarks1?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    remarks2?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    remarks3?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    remarks4?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    remarks5?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    recurringCreditStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    recurringId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    gmoMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    deletedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    modified?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    invited?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConferenceDoctors
     */
    jobId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    conferenceSpecializedAreas?: string;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    subMail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    sendToSubMailFlag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConferenceDoctors
     */
    finalEducation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceDoctors
     */
    livingAbroad?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ConferenceDoctorsSexEnum {
    Male = 'Male',
    Female = 'Female'
}
/**
    * @export
    * @enum {string}
    */
export enum ConferenceDoctorsStatusEnum {
    Member = 'member',
    Stop = 'stop',
    EtcetraStop = 'etcetra_stop',
    Withdrawal = 'Withdrawal',
    WithdrawalDeath = 'Withdrawal_death',
    WithdrawalUnpaid = 'Withdrawal_unpaid',
    WithdrawalEtcetra = 'Withdrawal_etcetra'
}
/**
    * @export
    * @enum {string}
    */
export enum ConferenceDoctorsDestinationEnum {
    Home = 'home',
    MedicalOffice = 'medical_office',
    OtherDestination = 'other_destination',
    NoDestination = 'no_destination'
}

/**
 * 学会お知らせ詳細
 * @export
 * @interface ConferenceInfoDetail
 */
export interface ConferenceInfoDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    conferencesId: number;
    /**
     * タイトル
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    title: string;
    /**
     * 本文
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    content?: string;
    /**
     * お知らせ対象のサービス会員ID
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    conferenceDoctorsIdCsv?: string;
    /**
     * お知らせ対象の会員種別
     * @type {Array<number>}
     * @memberof ConferenceInfoDetail
     */
    memberTypesIds?: Array<number>;
    /**
     * お知らせ配信名簿ID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    listId?: number;
    /**
     * 配信日時
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    sendDatetime?: string;
    /**
     * 掲載終了日
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    endDate?: string;
    /**
     * 委員会ID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    positionMeetingsId?: number;
    /**
     * 理事・役員会ID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    officerMeetingsId?: number;
    /**
     * 学術集会・セミナーID
     * @type {number}
     * @memberof ConferenceInfoDetail
     */
    meetingsId?: number;
    /**
     * お知らせ配信通知メールを送信するか否か
     * @type {boolean}
     * @memberof ConferenceInfoDetail
     */
    infoNoticeMailFlag?: boolean;
    /**
     * 資料1
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    fileName1?: string;
    /**
     * 資料2
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    fileName2?: string;
    /**
     * 資料2
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    fileName3?: string;
    /**
     * 資料4
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    fileName4?: string;
    /**
     * 資料5
     * @type {string}
     * @memberof ConferenceInfoDetail
     */
    fileName5?: string;
    /**
     * 自動作成のお知らせか否か
     * @type {boolean}
     * @memberof ConferenceInfoDetail
     */
    autoFlg?: boolean;
}
/**
 * 学会お知らせ配信リスト詳細
 * @export
 * @interface ConferenceInfoSendListDetail
 */
export interface ConferenceInfoSendListDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceInfoSendListDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceInfoSendListDetail
     */
    conferencesId: number;
    /**
     * グループ名
     * @type {string}
     * @memberof ConferenceInfoSendListDetail
     */
    title: string;
    /**
     * お知らせ対象のサービス会員ID
     * @type {string}
     * @memberof ConferenceInfoSendListDetail
     */
    conferenceDoctorsIdCsv?: string;
    /**
     * お知らせ対象のサービス会員情報
     * @type {Array<ConferenceDoctorDetail>}
     * @memberof ConferenceInfoSendListDetail
     */
    conferenceDoctorDetails?: Array<ConferenceDoctorDetail>;
}
/**
 * お知らせ配信先リスト
 * @export
 * @interface ConferenceInfoSendListOverview
 */
export interface ConferenceInfoSendListOverview {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceInfoSendListOverview
     */
    id?: number;
    /**
     * グループ名
     * @type {string}
     * @memberof ConferenceInfoSendListOverview
     */
    title?: string;
}
/**
 * 学会管理情報
 * @export
 * @interface ConferenceManagementDetail
 */
export interface ConferenceManagementDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceManagementDetail
     */
    id?: number;
    /**
     * マネジメントID
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    managementId?: string;
    /**
     * 法人格
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    juridicalPersonality?: string;
    /**
     * 学会名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    term: string;
    /**
     * 学会名カナ
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    termKana?: string;
    /**
     * 学会名英語
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    termEn?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    postalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    prefectures?: string;
    /**
     * アドレス1
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    address1?: string;
    /**
     * アドレス2
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    address2?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    tel?: string;
    /**
     * FAX
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    fax?: string;
    /**
     * 受信用メールアドレス
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    mail?: string;
    /**
     * URL
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    url?: string;
    /**
     * インボイス登録番号
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    invoiceNumber?: string;
    /**
     * 問い合わせ時間From
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    receptionTimeFrom?: string;
    /**
     * 問い合わせ時間To
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    receptionTimeTo?: string;
    /**
     * サービス開始年月日
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    entryDate?: string;
    /**
     * 有効期限年月日
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    expirationDate?: string;
    /**
     * サービス利用終了年月日
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    endDate?: string;
    /**
     * サービス廃止年月日
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    abolitionDate?: string;
    /**
     * 支払い方法
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    paymentType?: string;
    /**
     * 追加アップロードサイズ
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    addFileUploadSize?: string;
    /**
     * 年度
     * @type {number}
     * @memberof ConferenceManagementDetail
     */
    fiscalYear?: number;
    /**
     * 年度開始月
     * @type {number}
     * @memberof ConferenceManagementDetail
     */
    fiscalYearStartMonth?: number;
    /**
     * 年度開始日
     * @type {number}
     * @memberof ConferenceManagementDetail
     */
    fiscalYearStartDay?: number;
    /**
     * 会員歴更新処理の種類
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    memberYearsAddType?: string;
    /**
     * メンテナスフラグ
     * @type {boolean}
     * @memberof ConferenceManagementDetail
     */
    maintenanceFlg?: boolean;
    /**
     * ロゴファイル名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    logoFileName?: string;
    /**
     * 角印印影ファイル名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    stampFileName?: string;
    /**
     * 利用規約表示設定フラグ
     * @type {boolean}
     * @memberof ConferenceManagementDetail
     */
    termsOfServiceFlg?: boolean;
    /**
     * 演題システムの利用フラグ
     * @type {boolean}
     * @memberof ConferenceManagementDetail
     */
    useEndaiFlg?: boolean;
    /**
     * 学会/その他組織の表記フラグ
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    organization?: string;
    /**
     * 販売事業者(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawCompany?: string;
    /**
     * 代表責任者(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawResponsiblePerson?: string;
    /**
     * 所在地(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawAddress?: string;
    /**
     * 電話番号(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawTel?: string;
    /**
     * FAX(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawFax?: string;
    /**
     * メールアドレス(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawMail?: string;
    /**
     * ホームページURL(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawUrl?: string;
    /**
     * 取り扱い品(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawProduct?: string;
    /**
     * 販売価格(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawPrice?: string;
    /**
     * 商品代金以外の必要料金(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawOtherPrice?: string;
    /**
     * 支払い方法(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawPayment?: string;
    /**
     * 代金の支払い時期(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawPaymentTime?: string;
    /**
     * 役務提供時期(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawServiceAvailability?: string;
    /**
     * キャンセル・返金について(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawCancel?: string;
    /**
     * 決済業務等の外部委託(特商法に基づく表記)
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    lawOutsourcing?: string;
    /**
     * 備考
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    remarks?: string;
    /**
     * GMOサイトID
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    gmoSiteId?: string;
    /**
     * GMOサイトパスワード
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    gmoSitePass?: string;
    /**
     * GMOショップID
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    gmoShopId?: string;
    /**
     * GMOショップパスワード
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    gmoShopPass?: string;
    /**
     * サブドメイン
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    subdomain: string;
    /**
     * 署名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    signature?: string;
    /**
     * 振込先銀行名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankName?: string;
    /**
     * 振込先銀行コード
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankCode?: string;
    /**
     * 振込先支店名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankBranchName?: string;
    /**
     * 振込先支店コード
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankBranchCode?: string;
    /**
     * 振込先口座種別
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankAccountType?: string;
    /**
     * 振込先口座番号
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankAccountNumber?: string;
    /**
     * 振込先口座名
     * @type {string}
     * @memberof ConferenceManagementDetail
     */
    bankAccountName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceManagementDetail
     */
    indicateInvoice?: boolean;
}
/**
 * 会員数情報
 * @export
 * @interface ConferenceMembership
 */
export interface ConferenceMembership {
    /**
     * 会員数
     * @type {number}
     * @memberof ConferenceMembership
     */
    totalCount?: number;
    /**
     * 会員･会費種別 会員数
     * @type {Array<MemberTypeCount>}
     * @memberof ConferenceMembership
     */
    memberTypeCounts?: Array<MemberTypeCount>;
}
/**
 * その他請求者情報
 * @export
 * @interface ConferenceNotificationOverview
 */
export interface ConferenceNotificationOverview {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceNotificationOverview
     */
    id?: number;
    /**
     * お知らせの種類
     * @type {string}
     * @memberof ConferenceNotificationOverview
     */
    type?: ConferenceNotificationOverviewTypeEnum;
    /**
     * お知らせのタイトル
     * @type {string}
     * @memberof ConferenceNotificationOverview
     */
    title?: string;
    /**
     * 配信日時
     * @type {string}
     * @memberof ConferenceNotificationOverview
     */
    sendDatetime?: string;
    /**
     * お知らせの宛先
     * @type {string}
     * @memberof ConferenceNotificationOverview
     */
    destination?: string;
    /**
     * 
     * @type {ConferenceUserOverview}
     * @memberof ConferenceNotificationOverview
     */
    staff?: ConferenceUserOverview;
}

/**
    * @export
    * @enum {string}
    */
export enum ConferenceNotificationOverviewTypeEnum {
    EtcInformation = 'etcInformation',
    CommitteeMeeting = 'committeeMeeting',
    Meeting = 'meeting',
    BoardMeeting = 'boardMeeting'
}

/**
 * 学会のプロファイル情報
 * @export
 * @interface ConferenceProfile
 */
export interface ConferenceProfile {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceProfile
     */
    id?: number;
    /**
     * 法人格
     * @type {string}
     * @memberof ConferenceProfile
     */
    juridicalPersonality?: string;
    /**
     * 学会名
     * @type {string}
     * @memberof ConferenceProfile
     */
    term?: string;
    /**
     * 組織タイプ
     * @type {string}
     * @memberof ConferenceProfile
     */
    organization?: string;
    /**
     * URL
     * @type {string}
     * @memberof ConferenceProfile
     */
    url?: string;
    /**
     * ロゴURL
     * @type {string}
     * @memberof ConferenceProfile
     */
    logoUrl?: string;
}
/**
 * 決済情報
 * @export
 * @interface ConferenceSettlementInfo
 */
export interface ConferenceSettlementInfo {
    /**
     * GMOショップID
     * @type {string}
     * @memberof ConferenceSettlementInfo
     */
    gmoShopId: string;
}
/**
 * 基本診療科情報
 * @export
 * @interface ConferenceSpecializedAreaDetail
 */
export interface ConferenceSpecializedAreaDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceSpecializedAreaDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceSpecializedAreaDetail
     */
    conferencesId: number;
    /**
     * 基本診療科名
     * @type {string}
     * @memberof ConferenceSpecializedAreaDetail
     */
    term: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConferenceSpecializedAreaDetail
     */
    used?: boolean;
}
/**
 * 学会ユーザー詳細
 * @export
 * @interface ConferenceUserDetail
 */
export interface ConferenceUserDetail {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceUserDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceUserDetail
     */
    conferencesId?: number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof ConferenceUserDetail
     */
    user: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ConferenceUserDetail
     */
    mail: string;
    /**
     * ログインID
     * @type {string}
     * @memberof ConferenceUserDetail
     */
    loginid?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof ConferenceUserDetail
     */
    tel?: string;
    /**
     * 管理者区分
     * @type {string}
     * @memberof ConferenceUserDetail
     */
    type?: ConferenceUserDetailTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConferenceUserDetailTypeEnum {
    Administrator = 'administrator',
    General = 'general'
}

/**
 * 学会ユーザー情報
 * @export
 * @interface ConferenceUserInfo
 */
export interface ConferenceUserInfo {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceUserInfo
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceUserInfo
     */
    conferencesId?: number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof ConferenceUserInfo
     */
    user?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ConferenceUserInfo
     */
    mail?: string;
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof ConferenceUserInfo
     */
    newPassword?: string;
}
/**
 * 学会ユーザー概要
 * @export
 * @interface ConferenceUserOverview
 */
export interface ConferenceUserOverview {
    /**
     * ID
     * @type {number}
     * @memberof ConferenceUserOverview
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceUserOverview
     */
    conferencesId?: number;
    /**
     * ユーザ名
     * @type {string}
     * @memberof ConferenceUserOverview
     */
    user: string;
}
/**
 * 学会会員プロファイル
 * @export
 * @interface ConferenceUserProfile
 */
export interface ConferenceUserProfile {
    /**
     * 学会会員ID
     * @type {number}
     * @memberof ConferenceUserProfile
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof ConferenceUserProfile
     */
    conferencesId?: number;
    /**
     * 学会名
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    conferenceName?: string;
    /**
     * 組織種類
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    organization?: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    user?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    mail?: string;
    /**
     * ログインID
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    loginId?: string;
    /**
     * 権限種別
     * @type {string}
     * @memberof ConferenceUserProfile
     */
    type?: ConferenceUserProfileTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ConferenceUserProfileTypeEnum {
    Administrator = 'administrator',
    General = 'general'
}

/**
 * 削除カード情報
 * @export
 * @interface CreditCardInfo
 */
export interface CreditCardInfo {
    /**
     * GMOカード情報トークン
     * @type {string}
     * @memberof CreditCardInfo
     */
    gmoToken?: string;
    /**
     * GMOカード登録連番
     * @type {number}
     * @memberof CreditCardInfo
     */
    gmoCardSeq?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCardInfo
     */
    defaultCard?: boolean;
}
/**
 * CSVインポートの結果情報
 * @export
 * @interface CsvImportResult
 */
export interface CsvImportResult {
    /**
     * インポート件数
     * @type {number}
     * @memberof CsvImportResult
     */
    totalCount?: number;
    /**
     * インポート成功件数
     * @type {number}
     * @memberof CsvImportResult
     */
    importCount?: number;
    /**
     * エラーメッセージリスト
     * @type {Array<string>}
     * @memberof CsvImportResult
     */
    errorMessages?: Array<string>;
}
/**
 * 
 * @export
 * @interface CvsCallbackEntity
 */
export interface CvsCallbackEntity {
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    shopID?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    shopPass?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    accessID?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    tranID?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    tranDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    cvsCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    cvsConfNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    cvsReceiptNo?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    paymentTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    receiptDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    errCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    errInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    payType?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    orderID?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    finishDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    accessPass?: string;
    /**
     * 
     * @type {string}
     * @memberof CvsCallbackEntity
     */
    tax?: string;
}
/**
 * 決済情報
 * @export
 * @interface CvsPaymentInfo
 */
export interface CvsPaymentInfo {
    /**
     * 支払先コンビニコード
     * @type {string}
     * @memberof CvsPaymentInfo
     */
    convenienceCode: string;
    /**
     * 氏名
     * @type {string}
     * @memberof CvsPaymentInfo
     */
    customerName: string;
    /**
     * 氏名(フリガナ)
     * @type {string}
     * @memberof CvsPaymentInfo
     */
    customerNameKana: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof CvsPaymentInfo
     */
    tel: string;
    /**
     * 支払額
     * @type {number}
     * @memberof CvsPaymentInfo
     */
    amount: number;
}
/**
 * コンビニ決済結果
 * @export
 * @interface CvsPaymentResult
 */
export interface CvsPaymentResult {
    /**
     * 支払先コンビニコード
     * @type {string}
     * @memberof CvsPaymentResult
     */
    convenienceCode: string;
    /**
     * 確認番号
     * @type {string}
     * @memberof CvsPaymentResult
     */
    confNo: string;
    /**
     * 受付番号
     * @type {string}
     * @memberof CvsPaymentResult
     */
    receiptNo: string;
    /**
     * 支払期限日時
     * @type {string}
     * @memberof CvsPaymentResult
     */
    paymentTerm: string;
    /**
     * 決済日付
     * @type {string}
     * @memberof CvsPaymentResult
     */
    tranDate: string;
    /**
     * 払込票URL
     * @type {string}
     * @memberof CvsPaymentResult
     */
    receiptUrl?: string;
}
/**
 * 会員詳細
 * @export
 * @interface DoctorDetail
 */
export interface DoctorDetail {
    /**
     * ID
     * @type {number}
     * @memberof DoctorDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof DoctorDetail
     */
    conferencesId: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof DoctorDetail
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof DoctorDetail
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof DoctorDetail
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof DoctorDetail
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof DoctorDetail
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof DoctorDetail
     */
    firstNameEn?: string;
    /**
     * 性別
     * @type {string}
     * @memberof DoctorDetail
     */
    sex?: string;
    /**
     * 職種ID
     * @type {number}
     * @memberof DoctorDetail
     */
    jobId?: number;
    /**
     * 生年月日
     * @type {string}
     * @memberof DoctorDetail
     */
    birthday?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof DoctorDetail
     */
    mail: string;
    /**
     * サブメールアドレス
     * @type {string}
     * @memberof DoctorDetail
     */
    subMail?: string;
    /**
     * サブメールアドレス宛にもメールを送付するか否か
     * @type {boolean}
     * @memberof DoctorDetail
     */
    sendToSubMailFlag?: boolean;
    /**
     * 国家資格番号(医籍登録番号など)
     * @type {string}
     * @memberof DoctorDetail
     */
    doctorNumber?: string;
    /**
     * 最終学歴
     * @type {string}
     * @memberof DoctorDetail
     */
    finalEducation?: string;
    /**
     * 卒業大学
     * @type {string}
     * @memberof DoctorDetail
     */
    graduatedUniversity?: string;
    /**
     * 卒業年
     * @type {number}
     * @memberof DoctorDetail
     */
    graduatedYear?: number;
    /**
     * 医師免許取得年
     * @type {number}
     * @memberof DoctorDetail
     */
    getDoctorLicenseYear?: number;
    /**
     * 基本領域ID
     * @type {number}
     * @memberof DoctorDetail
     */
    medicalSpecialtyCategoriesId?: number;
    /**
     * 専門領域詳細
     * @type {string}
     * @memberof DoctorDetail
     */
    medicalSpecialtyDetailsIdCsv?: string;
    /**
     * 基本領域備考
     * @type {string}
     * @memberof DoctorDetail
     */
    medicalSpecialty?: string;
    /**
     * 専門領域・職種ID
     * @type {number}
     * @memberof DoctorDetail
     */
    conferenceSpecializedAreasId?: number;
    /**
     * 専門領域備考
     * @type {string}
     * @memberof DoctorDetail
     */
    conferenceSpecializedAreas?: string;
    /**
     * 送付先
     * @type {string}
     * @memberof DoctorDetail
     */
    destination?: string;
    /**
     * 郵便番号(海外在住は任意)
     * @type {string}
     * @memberof DoctorDetail
     */
    postalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof DoctorDetail
     */
    prefectures?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof DoctorDetail
     */
    address1?: string;
    /**
     * 住所2(マンション・ビル名)
     * @type {string}
     * @memberof DoctorDetail
     */
    address2?: string;
    /**
     * TEL
     * @type {string}
     * @memberof DoctorDetail
     */
    tel?: string;
    /**
     * 携帯
     * @type {string}
     * @memberof DoctorDetail
     */
    mobilePhone?: string;
    /**
     * FAX
     * @type {string}
     * @memberof DoctorDetail
     */
    fax?: string;
    /**
     * 在住区分
     * @type {boolean}
     * @memberof DoctorDetail
     */
    destinationIsLivingAbroad?: boolean;
    /**
     * 郵便番号(海外在住は任意)
     * @type {string}
     * @memberof DoctorDetail
     */
    destinationPostalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof DoctorDetail
     */
    destinationPrefectures?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof DoctorDetail
     */
    destinationAddress1?: string;
    /**
     * 住所2(マンション・ビル名)
     * @type {string}
     * @memberof DoctorDetail
     */
    destinationAddress2?: string;
    /**
     * 会員状態
     * @type {string}
     * @memberof DoctorDetail
     */
    status: string;
    /**
     * 停止年月日
     * @type {string}
     * @memberof DoctorDetail
     */
    stopDate?: string;
    /**
     * 退会詳細メモ
     * @type {string}
     * @memberof DoctorDetail
     */
    stopReason?: string;
    /**
     * 会員承認可否
     * @type {boolean}
     * @memberof DoctorDetail
     */
    approvalFlag?: boolean;
    /**
     * 入会年月日
     * @type {string}
     * @memberof DoctorDetail
     */
    entryDate: string;
    /**
     * 会員歴(年)
     * @type {number}
     * @memberof DoctorDetail
     */
    memberYears?: number;
    /**
     * 入金督促開始日
     * @type {string}
     * @memberof DoctorDetail
     */
    expirationDate?: string;
    /**
     * 会員番号
     * @type {string}
     * @memberof DoctorDetail
     */
    conferenceNumber?: string;
    /**
     * 会員・会費種別ID
     * @type {number}
     * @memberof DoctorDetail
     */
    memberTypesId?: number;
    /**
     * 留学中か否か
     * @type {boolean}
     * @memberof DoctorDetail
     */
    studyAbroad?: boolean;
    /**
     * 備考1
     * @type {string}
     * @memberof DoctorDetail
     */
    remarks1?: string;
    /**
     * 備考2
     * @type {string}
     * @memberof DoctorDetail
     */
    remarks2?: string;
    /**
     * 備考3
     * @type {string}
     * @memberof DoctorDetail
     */
    remarks3?: string;
    /**
     * 備考4
     * @type {string}
     * @memberof DoctorDetail
     */
    remarks4?: string;
    /**
     * 備考5
     * @type {string}
     * @memberof DoctorDetail
     */
    remarks5?: string;
    /**
     * 自動支払ステータス
     * @type {string}
     * @memberof DoctorDetail
     */
    recurringCreditStatus?: string;
    /**
     * GMO会員ID
     * @type {string}
     * @memberof DoctorDetail
     */
    gmoMemberId?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof DoctorDetail
     */
    modified?: string;
    /**
     * 在住区分
     * @type {boolean}
     * @memberof DoctorDetail
     */
    isLivingAbroad?: boolean;
}
/**
 * 学術集会・セミナー参加詳細
 * @export
 * @interface EntryMeetingDetail
 */
export interface EntryMeetingDetail {
    /**
     * ID
     * @type {number}
     * @memberof EntryMeetingDetail
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof EntryMeetingDetail
     */
    conferenceDoctorsId: number;
    /**
     * 学術集会・セミナーID
     * @type {number}
     * @memberof EntryMeetingDetail
     */
    meetingsId: number;
    /**
     * 参加(予定)
     * @type {string}
     * @memberof EntryMeetingDetail
     */
    probablyAttend?: EntryMeetingDetailProbablyAttendEnum;
    /**
     * 参加(実績)
     * @type {string}
     * @memberof EntryMeetingDetail
     */
    actuallyAttend?: EntryMeetingDetailActuallyAttendEnum;
    /**
     * 合否
     * @type {number}
     * @memberof EntryMeetingDetail
     */
    meetingResult?: number;
    /**
     * 備考
     * @type {string}
     * @memberof EntryMeetingDetail
     */
    remarks?: string;
    /**
     * 
     * @type {ConferenceDoctorDetail}
     * @memberof EntryMeetingDetail
     */
    memberDetail?: ConferenceDoctorDetail;
}

/**
    * @export
    * @enum {string}
    */
export enum EntryMeetingDetailProbablyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntryMeetingDetailActuallyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}

/**
 * 参加情報
 * @export
 * @interface EntryMeetingForm
 */
export interface EntryMeetingForm {
    /**
     * 参加(予定)
     * @type {string}
     * @memberof EntryMeetingForm
     */
    probablyAttend?: EntryMeetingFormProbablyAttendEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntryMeetingFormProbablyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}

/**
 * 学術集会･セミナー情報
 * @export
 * @interface EntryMeetingOverview
 */
export interface EntryMeetingOverview {
    /**
     * ミーティングID
     * @type {number}
     * @memberof EntryMeetingOverview
     */
    id?: number;
    /**
     * エントリーID
     * @type {number}
     * @memberof EntryMeetingOverview
     */
    entryMeetingId?: number;
    /**
     * 名称
     * @type {string}
     * @memberof EntryMeetingOverview
     */
    meetingName?: string;
    /**
     * 開催期間(From)
     * @type {string}
     * @memberof EntryMeetingOverview
     */
    dateFrom?: string;
    /**
     * 開催期間(To)
     * @type {string}
     * @memberof EntryMeetingOverview
     */
    dateTo?: string;
    /**
     * 参加(予定)
     * @type {string}
     * @memberof EntryMeetingOverview
     */
    probablyAttend?: string;
    /**
     * 参加(実績)
     * @type {string}
     * @memberof EntryMeetingOverview
     */
    actuallyAttend?: string;
    /**
     * 合否
     * @type {number}
     * @memberof EntryMeetingOverview
     */
    meetingResult?: number;
}
/**
 * 
 * @export
 * @interface EntryMeetings
 */
export interface EntryMeetings {
    /**
     * 
     * @type {number}
     * @memberof EntryMeetings
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EntryMeetings
     */
    conferenceDoctorsId?: number;
    /**
     * 
     * @type {number}
     * @memberof EntryMeetings
     */
    meetingsId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    attendanceCardNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    probablyAttend?: EntryMeetingsProbablyAttendEnum;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    actuallyAttend?: EntryMeetingsActuallyAttendEnum;
    /**
     * 
     * @type {number}
     * @memberof EntryMeetings
     */
    meetingResult?: number;
    /**
     * 
     * @type {number}
     * @memberof EntryMeetings
     */
    paymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    entryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    remarks?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntryMeetings
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    deletedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryMeetings
     */
    modified?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EntryMeetingsProbablyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntryMeetingsActuallyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}

/**
 * 理事・役員会出席者詳細
 * @export
 * @interface EntryOfficerMeetingDetail
 */
export interface EntryOfficerMeetingDetail {
    /**
     * 理事・役員会出席情報ID
     * @type {number}
     * @memberof EntryOfficerMeetingDetail
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof EntryOfficerMeetingDetail
     */
    conferenceDoctorsId: number;
    /**
     * 理事・役員会開催ID
     * @type {number}
     * @memberof EntryOfficerMeetingDetail
     */
    officerMeetingsId: number;
    /**
     * 理事・役員会名簿ID
     * @type {number}
     * @memberof EntryOfficerMeetingDetail
     */
    officerQualificationsId?: number;
    /**
     * 役職
     * @type {string}
     * @memberof EntryOfficerMeetingDetail
     */
    term?: string;
    /**
     * 参加(予定)
     * @type {string}
     * @memberof EntryOfficerMeetingDetail
     */
    probablyAttend?: EntryOfficerMeetingDetailProbablyAttendEnum;
    /**
     * 参加(実績)
     * @type {string}
     * @memberof EntryOfficerMeetingDetail
     */
    actuallyAttend?: EntryOfficerMeetingDetailActuallyAttendEnum;
    /**
     * 交通費(円)
     * @type {number}
     * @memberof EntryOfficerMeetingDetail
     */
    transportationFee?: number;
    /**
     * 備考
     * @type {string}
     * @memberof EntryOfficerMeetingDetail
     */
    remarks?: string;
    /**
     * 
     * @type {ConferenceDoctorDetail}
     * @memberof EntryOfficerMeetingDetail
     */
    memberDetail?: ConferenceDoctorDetail;
}

/**
    * @export
    * @enum {string}
    */
export enum EntryOfficerMeetingDetailProbablyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntryOfficerMeetingDetailActuallyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}

/**
 * 委員会出席者詳細
 * @export
 * @interface EntryPositionMeetingDetail
 */
export interface EntryPositionMeetingDetail {
    /**
     * 委員会出席情報ID
     * @type {number}
     * @memberof EntryPositionMeetingDetail
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof EntryPositionMeetingDetail
     */
    conferenceDoctorsId: number;
    /**
     * 委員会開催ID
     * @type {number}
     * @memberof EntryPositionMeetingDetail
     */
    positionMeetingsId: number;
    /**
     * 委員会名簿ID
     * @type {number}
     * @memberof EntryPositionMeetingDetail
     */
    positionQualificationsId?: number;
    /**
     * 役職
     * @type {string}
     * @memberof EntryPositionMeetingDetail
     */
    term?: string;
    /**
     * 参加(予定)
     * @type {string}
     * @memberof EntryPositionMeetingDetail
     */
    probablyAttend?: EntryPositionMeetingDetailProbablyAttendEnum;
    /**
     * 参加(実績)
     * @type {string}
     * @memberof EntryPositionMeetingDetail
     */
    actuallyAttend?: EntryPositionMeetingDetailActuallyAttendEnum;
    /**
     * 交通費(円)
     * @type {number}
     * @memberof EntryPositionMeetingDetail
     */
    transportationFee?: number;
    /**
     * 備考
     * @type {string}
     * @memberof EntryPositionMeetingDetail
     */
    remarks?: string;
    /**
     * 
     * @type {ConferenceDoctorDetail}
     * @memberof EntryPositionMeetingDetail
     */
    memberDetail?: ConferenceDoctorDetail;
}

/**
    * @export
    * @enum {string}
    */
export enum EntryPositionMeetingDetailProbablyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}
/**
    * @export
    * @enum {string}
    */
export enum EntryPositionMeetingDetailActuallyAttendEnum {
    Unregistered = 'UNREGISTERED',
    Attendance = 'ATTENDANCE',
    Absence = 'ABSENCE'
}

/**
 * 支払いID
 * @export
 * @interface EtcBillingPersonOverview
 */
export interface EtcBillingPersonOverview {
    /**
     * 支払いID
     * @type {number}
     * @memberof EtcBillingPersonOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof EtcBillingPersonOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 学会 その他請求者 ID
     * @type {number}
     * @memberof EtcBillingPersonOverview
     */
    signEtcPaymentId?: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    firstNameEn?: string;
    /**
     * 職種
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    job?: string;
    /**
     * その他請求名
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    billingName?: string;
    /**
     * 請求開始年月日
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    billingDateFrom?: string;
    /**
     * 請求終了年月日
     * @type {string}
     * @memberof EtcBillingPersonOverview
     */
    billingDateTo?: string;
}
/**
 * その他入金状態
 * @export
 * @interface EtcFeeOverview
 */
export interface EtcFeeOverview {
    /**
     * 支払いID
     * @type {number}
     * @memberof EtcFeeOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof EtcFeeOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof EtcFeeOverview
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof EtcFeeOverview
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof EtcFeeOverview
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof EtcFeeOverview
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof EtcFeeOverview
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof EtcFeeOverview
     */
    firstNameEn?: string;
    /**
     * 年度
     * @type {number}
     * @memberof EtcFeeOverview
     */
    fiscalYear?: number;
    /**
     * その他請求名
     * @type {string}
     * @memberof EtcFeeOverview
     */
    billingName?: string;
    /**
     * 内容
     * @type {string}
     * @memberof EtcFeeOverview
     */
    paymentContent?: string;
    /**
     * 請求額
     * @type {number}
     * @memberof EtcFeeOverview
     */
    billAmount?: number;
    /**
     * 入金状態
     * @type {string}
     * @memberof EtcFeeOverview
     */
    paymentStatus?: string;
    /**
     * 入金方法
     * @type {string}
     * @memberof EtcFeeOverview
     */
    paymentType?: string;
    /**
     * 入金日
     * @type {string}
     * @memberof EtcFeeOverview
     */
    paymentDate?: string;
}
/**
 * その他請求マスター情報
 * @export
 * @interface EtcPaymentDetail
 */
export interface EtcPaymentDetail {
    /**
     * ID
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    conferencesId: number;
    /**
     * 請求名称
     * @type {string}
     * @memberof EtcPaymentDetail
     */
    term: string;
    /**
     * 支払額
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    etceteraFee: number;
    /**
     * 請求月
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    doctorPaymentCreateMonth?: number;
    /**
     * 請求日
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    doctorPaymentCreateDay?: number;
    /**
     * 支払月(月)
     * @type {string}
     * @memberof EtcPaymentDetail
     */
    doctorPaymentMonth?: string;
    /**
     * 支払日(日)
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    doctorPaymentDay?: number;
    /**
     * 入金単位区分
     * @type {number}
     * @memberof EtcPaymentDetail
     */
    paymentUnitType?: number;
    /**
     * 税種別
     * @type {string}
     * @memberof EtcPaymentDetail
     */
    tax?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EtcPaymentDetail
     */
    used?: boolean;
}
/**
 * クレジットカード情報
 * @export
 * @interface GmoCardInfo
 */
export interface GmoCardInfo {
    /**
     * クレジットカードブランド
     * @type {string}
     * @memberof GmoCardInfo
     */
    gmoCardBrand?: string;
    /**
     * クレジットカード番号(マスク)
     * @type {string}
     * @memberof GmoCardInfo
     */
    gmoCardNumber?: string;
    /**
     * クレジットカード有効期限
     * @type {string}
     * @memberof GmoCardInfo
     */
    gmoCardExpire?: string;
    /**
     * カード名義人
     * @type {string}
     * @memberof GmoCardInfo
     */
    gmoCardholderName?: string;
    /**
     * GMOカード登録連番
     * @type {number}
     * @memberof GmoCardInfo
     */
    gmoCardSeq: number;
    /**
     * 
     * @type {boolean}
     * @memberof GmoCardInfo
     */
    defaultCard?: boolean;
}
/**
 * サービス会員招待
 * @export
 * @interface InviteMember
 */
export interface InviteMember {
    /**
     * サービス会員IDリスト
     * @type {Array<number>}
     * @memberof InviteMember
     */
    conferenceDoctorsIds?: Array<number>;
    /**
     * 未招待のすべてのユーザーに送信するか否か
     * @type {boolean}
     * @memberof InviteMember
     */
    sendToNotInviteAllMember?: boolean;
}
/**
 * 請求書基本情報
 * @export
 * @interface InvoiceDetail
 */
export interface InvoiceDetail {
    /**
     * 支払いID
     * @type {number}
     * @memberof InvoiceDetail
     */
    doctorPaymentId?: number;
    /**
     * 内容
     * @type {string}
     * @memberof InvoiceDetail
     */
    invoiceName?: string;
    /**
     * 請求額
     * @type {number}
     * @memberof InvoiceDetail
     */
    invoiceAmount?: number;
    /**
     * 学会名
     * @type {string}
     * @memberof InvoiceDetail
     */
    lawCompany?: string;
    /**
     * 住所
     * @type {string}
     * @memberof InvoiceDetail
     */
    lawAddress?: string;
    /**
     * TEL
     * @type {string}
     * @memberof InvoiceDetail
     */
    lawTel?: string;
    /**
     * 会員番号
     * @type {string}
     * @memberof InvoiceDetail
     */
    conferenceNumber?: string;
    /**
     * 会員姓名
     * @type {string}
     * @memberof InvoiceDetail
     */
    fullName?: string;
    /**
     * 勤務先施設名
     * @type {string}
     * @memberof InvoiceDetail
     */
    facilityName?: string;
    /**
     * 医局名
     * @type {string}
     * @memberof InvoiceDetail
     */
    medicalOfficeName?: string;
    /**
     * 会員資格
     * @type {string}
     * @memberof InvoiceDetail
     */
    memberType?: string;
    /**
     * 請求額
     * @type {number}
     * @memberof InvoiceDetail
     */
    billAmount?: number;
    /**
     * 振込先銀行名
     * @type {string}
     * @memberof InvoiceDetail
     */
    bankName?: string;
    /**
     * 振込先支店名
     * @type {string}
     * @memberof InvoiceDetail
     */
    bankBranchName?: string;
    /**
     * 振込先口座種別
     * @type {string}
     * @memberof InvoiceDetail
     */
    bankAccountType?: string;
    /**
     * 振込先口座番号
     * @type {string}
     * @memberof InvoiceDetail
     */
    bankAccountNumber?: string;
    /**
     * 振込先口座名
     * @type {string}
     * @memberof InvoiceDetail
     */
    bankAccountName?: string;
    /**
     * 支払い期限
     * @type {string}
     * @memberof InvoiceDetail
     */
    deadline?: string;
    /**
     * 発行日
     * @type {string}
     * @memberof InvoiceDetail
     */
    invoiceDate?: string;
    /**
     * 税種別
     * @type {string}
     * @memberof InvoiceDetail
     */
    tax?: string;
    /**
     * インボイス登録番号
     * @type {string}
     * @memberof InvoiceDetail
     */
    invoiceNumber?: string;
}
/**
 * 職種マスタ詳細
 * @export
 * @interface JobDetail
 */
export interface JobDetail {
    /**
     * ID
     * @type {number}
     * @memberof JobDetail
     */
    id?: number;
    /**
     * 名称
     * @type {string}
     * @memberof JobDetail
     */
    term: string;
    /**
     * 組織ID
     * @type {number}
     * @memberof JobDetail
     */
    conferencesId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JobDetail
     */
    used?: boolean;
}
/**
 * メールテンプレート情報
 * @export
 * @interface MailTemplateDetail
 */
export interface MailTemplateDetail {
    /**
     * ID
     * @type {number}
     * @memberof MailTemplateDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof MailTemplateDetail
     */
    conferencesId?: number;
    /**
     * コード(テンプレートの種別)
     * @type {string}
     * @memberof MailTemplateDetail
     */
    code: string;
    /**
     * タイトル
     * @type {string}
     * @memberof MailTemplateDetail
     */
    title: string;
    /**
     * 本文
     * @type {string}
     * @memberof MailTemplateDetail
     */
    body: string;
    /**
     * 備考
     * @type {string}
     * @memberof MailTemplateDetail
     */
    remarks?: string;
    /**
     * 備考(学会)
     * @type {string}
     * @memberof MailTemplateDetail
     */
    remarksConference?: string;
}
/**
 * 基本領域マスタ詳細
 * @export
 * @interface MedicalSpecialtyCategoryDetail
 */
export interface MedicalSpecialtyCategoryDetail {
    /**
     * ID
     * @type {number}
     * @memberof MedicalSpecialtyCategoryDetail
     */
    id?: number;
    /**
     * 名称
     * @type {string}
     * @memberof MedicalSpecialtyCategoryDetail
     */
    term: string;
    /**
     * 組織ID
     * @type {number}
     * @memberof MedicalSpecialtyCategoryDetail
     */
    conferencesId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalSpecialtyCategoryDetail
     */
    used?: boolean;
}
/**
 * 学術集会・セミナー詳細
 * @export
 * @interface MeetingDetail
 */
export interface MeetingDetail {
    /**
     * ID
     * @type {number}
     * @memberof MeetingDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof MeetingDetail
     */
    conferencesId: number;
    /**
     * 名称
     * @type {string}
     * @memberof MeetingDetail
     */
    term: string;
    /**
     * 名称(カナ)
     * @type {string}
     * @memberof MeetingDetail
     */
    termKana: string;
    /**
     * 担当者名(委員長名など)
     * @type {string}
     * @memberof MeetingDetail
     */
    contactName?: string;
    /**
     * 開催地
     * @type {string}
     * @memberof MeetingDetail
     */
    venue: string;
    /**
     * 主催施設
     * @type {string}
     * @memberof MeetingDetail
     */
    organizer?: string;
    /**
     * その他支払いID(出席費用)
     * @type {number}
     * @memberof MeetingDetail
     */
    etceteraPaymentsId?: number;
    /**
     * 開催案内通知日
     * @type {string}
     * @memberof MeetingDetail
     */
    releaseDate?: string;
    /**
     * 申し込み期間FROM
     * @type {string}
     * @memberof MeetingDetail
     */
    entryDateFrom?: string;
    /**
     * 申し込み期間TO
     * @type {string}
     * @memberof MeetingDetail
     */
    entryDateTo?: string;
    /**
     * 開催FROM
     * @type {string}
     * @memberof MeetingDetail
     */
    dateFrom: string;
    /**
     * 開催TO
     * @type {string}
     * @memberof MeetingDetail
     */
    dateTo: string;
    /**
     * 開催時間FROM
     * @type {string}
     * @memberof MeetingDetail
     */
    timeFrom?: string;
    /**
     * 開催時間TO
     * @type {string}
     * @memberof MeetingDetail
     */
    timeTo?: string;
    /**
     * 受付時間FROM
     * @type {string}
     * @memberof MeetingDetail
     */
    receptionTimeFrom?: string;
    /**
     * 受付時間TO
     * @type {string}
     * @memberof MeetingDetail
     */
    receptionTimeTo?: string;
    /**
     * URL
     * @type {string}
     * @memberof MeetingDetail
     */
    url?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof MeetingDetail
     */
    postalCode?: string;
    /**
     * 都道府県
     * @type {string}
     * @memberof MeetingDetail
     */
    prefectures?: string;
    /**
     * 住所1
     * @type {string}
     * @memberof MeetingDetail
     */
    address1?: string;
    /**
     * 住所2
     * @type {string}
     * @memberof MeetingDetail
     */
    address2?: string;
    /**
     * TEL
     * @type {string}
     * @memberof MeetingDetail
     */
    tel?: string;
    /**
     * FAX
     * @type {string}
     * @memberof MeetingDetail
     */
    fax?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof MeetingDetail
     */
    mail?: string;
    /**
     * 開催備考
     * @type {string}
     * @memberof MeetingDetail
     */
    meetingRemarks?: string;
    /**
     * 会場1:会場名
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1Term?: string;
    /**
     * 会場1:TEL
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1Tel?: string;
    /**
     * 会場1:郵便番号
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1PostalCode?: string;
    /**
     * 会場1:都道府県
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1Prefectures?: string;
    /**
     * 会場1:住所1
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1Address1?: string;
    /**
     * 会場1:住所2
     * @type {string}
     * @memberof MeetingDetail
     */
    venue1Address2?: string;
    /**
     * 会場2:会場名
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2Term?: string;
    /**
     * 会場2:TEL
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2Tel?: string;
    /**
     * 会場2:郵便番号
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2PostalCode?: string;
    /**
     * 会場2:都道府県
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2Prefectures?: string;
    /**
     * 会場2:住所1
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2Address1?: string;
    /**
     * 会場2:住所2
     * @type {string}
     * @memberof MeetingDetail
     */
    venue2Address2?: string;
    /**
     * 会場3:会場名
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3Term?: string;
    /**
     * 会場3:TEL
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3Tel?: string;
    /**
     * 会場3:郵便番号
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3PostalCode?: string;
    /**
     * 会場3:都道府県
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3Prefectures?: string;
    /**
     * 会場3:住所1
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3Address1?: string;
    /**
     * 会場3:住所2
     * @type {string}
     * @memberof MeetingDetail
     */
    venue3Address2?: string;
    /**
     * 運営情報:社名
     * @type {string}
     * @memberof MeetingDetail
     */
    operationCompany?: string;
    /**
     * 運営情報:担当者
     * @type {string}
     * @memberof MeetingDetail
     */
    operationManager?: string;
    /**
     * 運営情報:TEL
     * @type {string}
     * @memberof MeetingDetail
     */
    operationTel?: string;
    /**
     * 運営情報:FAX
     * @type {string}
     * @memberof MeetingDetail
     */
    operationFax?: string;
    /**
     * 運営情報:メールアドレス
     * @type {string}
     * @memberof MeetingDetail
     */
    operationMail?: string;
    /**
     * 運営情報:備考
     * @type {string}
     * @memberof MeetingDetail
     */
    remarks?: string;
    /**
     * 共有URL情報
     * @type {Array<MeetingsSharedUrl>}
     * @memberof MeetingDetail
     */
    sharedUrls?: Array<MeetingsSharedUrl>;
    /**
     * 資料1
     * @type {string}
     * @memberof MeetingDetail
     */
    fileName1?: string;
    /**
     * 資料2
     * @type {string}
     * @memberof MeetingDetail
     */
    fileName2?: string;
    /**
     * 資料3
     * @type {string}
     * @memberof MeetingDetail
     */
    fileName3?: string;
    /**
     * 資料4
     * @type {string}
     * @memberof MeetingDetail
     */
    fileName4?: string;
    /**
     * 資料5
     * @type {string}
     * @memberof MeetingDetail
     */
    fileName5?: string;
    /**
     * 出席予定者だけに情報表示するか否か
     * @type {boolean}
     * @memberof MeetingDetail
     */
    attendOnlyFlg?: boolean;
}
/**
 * 共有URL情報
 * @export
 * @interface MeetingsSharedUrl
 */
export interface MeetingsSharedUrl {
    /**
     * 
     * @type {number}
     * @memberof MeetingsSharedUrl
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingsSharedUrl
     */
    meetingsId?: number;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    sharedUrlName?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    sharedUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    disclosureDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    disclosureTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    disclosureDateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    disclosureTimeTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingsSharedUrl
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    deletedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingsSharedUrl
     */
    modified?: string;
}
/**
 * 会員･会費種別 会員数
 * @export
 * @interface MemberTypeCount
 */
export interface MemberTypeCount {
    /**
     * 会員･会費種別名
     * @type {string}
     * @memberof MemberTypeCount
     */
    memberTypeName?: string;
    /**
     * 会員数
     * @type {number}
     * @memberof MemberTypeCount
     */
    count?: number;
}
/**
 * 会員･会費種別情報
 * @export
 * @interface MemberTypeDetail
 */
export interface MemberTypeDetail {
    /**
     * ID
     * @type {number}
     * @memberof MemberTypeDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof MemberTypeDetail
     */
    conferencesId: number;
    /**
     * 名称
     * @type {string}
     * @memberof MemberTypeDetail
     */
    term: string;
    /**
     * 入金額(円)
     * @type {number}
     * @memberof MemberTypeDetail
     */
    fee: number;
    /**
     * 請求月
     * @type {number}
     * @memberof MemberTypeDetail
     */
    doctorPaymentCreateMonth: number;
    /**
     * 請求日
     * @type {number}
     * @memberof MemberTypeDetail
     */
    doctorPaymentCreateDay: number;
    /**
     * 支払月
     * @type {number}
     * @memberof MemberTypeDetail
     */
    doctorPaymentMonth: number;
    /**
     * 支払日
     * @type {number}
     * @memberof MemberTypeDetail
     */
    doctorPaymentDay: number;
    /**
     * 税区分
     * @type {string}
     * @memberof MemberTypeDetail
     */
    tax?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberTypeDetail
     */
    used?: boolean;
}
/**
 * 会員種別(セレクトコンポーネント用)
 * @export
 * @interface MemberTypeItem
 */
export interface MemberTypeItem {
    /**
     * メンバータイプID
     * @type {number}
     * @memberof MemberTypeItem
     */
    id?: number;
    /**
     * 会員種別名
     * @type {string}
     * @memberof MemberTypeItem
     */
    term?: string;
}
/**
 * 新規請求情報
 * @export
 * @interface NewPaymentParam
 */
export interface NewPaymentParam {
    /**
     * 請求マスタID
     * @type {number}
     * @memberof NewPaymentParam
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof NewPaymentParam
     */
    conferencesId: number;
    /**
     * サービス会員ID一覧
     * @type {Array<number>}
     * @memberof NewPaymentParam
     */
    conferenceDoctorsIds: Array<number>;
    /**
     * 年度
     * @type {number}
     * @memberof NewPaymentParam
     */
    fiscalYear: number;
}
/**
 * 理事･役員会詳細
 * @export
 * @interface OfficerDetail
 */
export interface OfficerDetail {
    /**
     * ID
     * @type {number}
     * @memberof OfficerDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof OfficerDetail
     */
    conferencesId: number;
    /**
     * 名称
     * @type {string}
     * @memberof OfficerDetail
     */
    term: string;
    /**
     * 理事･役員会発足日
     * @type {string}
     * @memberof OfficerDetail
     */
    dateFrom?: string;
}
/**
 * 理事･役員会開催詳細
 * @export
 * @interface OfficerMeetingDetail
 */
export interface OfficerMeetingDetail {
    /**
     * ID
     * @type {number}
     * @memberof OfficerMeetingDetail
     */
    id?: number;
    /**
     * 理事･役員会ID
     * @type {number}
     * @memberof OfficerMeetingDetail
     */
    officersId: number;
    /**
     * 開催名称
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    term: string;
    /**
     * 開催場所
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    venue?: string;
    /**
     * 開催日
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    meetingDate: string;
    /**
     * 開催時間FROM
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    meetingTimeFrom: string;
    /**
     * 開催時間TO
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    meetingTimeTo: string;
    /**
     * 開催案内通知日
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    releaseDate?: string;
    /**
     * 備考
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    remarks?: string;
    /**
     * 資料1
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    fileName1?: string;
    /**
     * 資料2
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    fileName2?: string;
    /**
     * 資料3
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    fileName3?: string;
    /**
     * 資料4
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    fileName4?: string;
    /**
     * 資料5
     * @type {string}
     * @memberof OfficerMeetingDetail
     */
    fileName5?: string;
}
/**
 * 理事･役員会名簿情報
 * @export
 * @interface OfficerQualificationDetail
 */
export interface OfficerQualificationDetail {
    /**
     * ID
     * @type {number}
     * @memberof OfficerQualificationDetail
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof OfficerQualificationDetail
     */
    conferenceDoctorsId: number;
    /**
     * 理事･役員会ID
     * @type {number}
     * @memberof OfficerQualificationDetail
     */
    officersId: number;
    /**
     * 役職
     * @type {string}
     * @memberof OfficerQualificationDetail
     */
    officerTerm?: string;
    /**
     * 任期状態
     * @type {string}
     * @memberof OfficerQualificationDetail
     */
    status?: string;
    /**
     * 任期就任日
     * @type {string}
     * @memberof OfficerQualificationDetail
     */
    tenureDateFrom: string;
    /**
     * 任期満了日
     * @type {string}
     * @memberof OfficerQualificationDetail
     */
    tenureDateTo?: string;
    /**
     * 備考
     * @type {string}
     * @memberof OfficerQualificationDetail
     */
    remarks?: string;
    /**
     * 
     * @type {ConferenceDoctorDetail}
     * @memberof OfficerQualificationDetail
     */
    memberDetail?: ConferenceDoctorDetail;
}
/**
 * 理事･役員会情報
 * @export
 * @interface OfficerQualificationOverview
 */
export interface OfficerQualificationOverview {
    /**
     * ID
     * @type {number}
     * @memberof OfficerQualificationOverview
     */
    id?: number;
    /**
     * 理事･役員会ID
     * @type {number}
     * @memberof OfficerQualificationOverview
     */
    officersId?: number;
    /**
     * 理事･役員会名
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    executiveName?: string;
    /**
     * 役職
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    position?: string;
    /**
     * 状態
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    status?: string;
    /**
     * 任期就任日
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    termDateFrom?: string;
    /**
     * 任期満了日
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    termDateTo?: string;
    /**
     * 備考
     * @type {string}
     * @memberof OfficerQualificationOverview
     */
    remarks?: string;
}
/**
 * 
 * @export
 * @interface PageAcquisitionQualificationInfo
 */
export interface PageAcquisitionQualificationInfo {
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationInfo
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationInfo
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAcquisitionQualificationInfo
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationInfo
     */
    size?: number;
    /**
     * 
     * @type {Array<AcquisitionQualificationInfo>}
     * @memberof PageAcquisitionQualificationInfo
     */
    content?: Array<AcquisitionQualificationInfo>;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationInfo
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationInfo
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAcquisitionQualificationInfo
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationInfo
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationInfo
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationInfo
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAcquisitionQualificationOverview
 */
export interface PageAcquisitionQualificationOverview {
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAcquisitionQualificationOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<AcquisitionQualificationOverview>}
     * @memberof PageAcquisitionQualificationOverview
     */
    content?: Array<AcquisitionQualificationOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAcquisitionQualificationOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAcquisitionQualificationOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAcquisitionQualificationOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAdminInfoDetail
 */
export interface PageAdminInfoDetail {
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAdminInfoDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<AdminInfoDetail>}
     * @memberof PageAdminInfoDetail
     */
    content?: Array<AdminInfoDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAdminInfoDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAdminInfoOverview
 */
export interface PageAdminInfoOverview {
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAdminInfoOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<AdminInfoOverview>}
     * @memberof PageAdminInfoOverview
     */
    content?: Array<AdminInfoOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminInfoOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAdminInfoOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminInfoOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAdminUserDetail
 */
export interface PageAdminUserDetail {
    /**
     * 
     * @type {number}
     * @memberof PageAdminUserDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminUserDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAdminUserDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAdminUserDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<AdminUserDetail>}
     * @memberof PageAdminUserDetail
     */
    content?: Array<AdminUserDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageAdminUserDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAdminUserDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAdminUserDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminUserDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminUserDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAdminUserDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageAnnualFeeOverview
 */
export interface PageAnnualFeeOverview {
    /**
     * 
     * @type {number}
     * @memberof PageAnnualFeeOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAnnualFeeOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageAnnualFeeOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageAnnualFeeOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<AnnualFeeOverview>}
     * @memberof PageAnnualFeeOverview
     */
    content?: Array<AnnualFeeOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageAnnualFeeOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageAnnualFeeOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageAnnualFeeOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnnualFeeOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnnualFeeOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageAnnualFeeOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceDoctorDetail
 */
export interface PageConferenceDoctorDetail {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceDoctorDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceDoctorDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceDoctorDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceDoctorDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceDoctorDetail>}
     * @memberof PageConferenceDoctorDetail
     */
    content?: Array<ConferenceDoctorDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceDoctorDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceDoctorDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceDoctorDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceDoctorDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceDoctorDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceDoctorDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceInfoSendListOverview
 */
export interface PageConferenceInfoSendListOverview {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceInfoSendListOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceInfoSendListOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceInfoSendListOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceInfoSendListOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceInfoSendListOverview>}
     * @memberof PageConferenceInfoSendListOverview
     */
    content?: Array<ConferenceInfoSendListOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceInfoSendListOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceInfoSendListOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceInfoSendListOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceInfoSendListOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceInfoSendListOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceInfoSendListOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceManagementDetail
 */
export interface PageConferenceManagementDetail {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceManagementDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceManagementDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceManagementDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceManagementDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceManagementDetail>}
     * @memberof PageConferenceManagementDetail
     */
    content?: Array<ConferenceManagementDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceManagementDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceManagementDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceManagementDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceManagementDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceManagementDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceManagementDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceNotificationOverview
 */
export interface PageConferenceNotificationOverview {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceNotificationOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceNotificationOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceNotificationOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceNotificationOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceNotificationOverview>}
     * @memberof PageConferenceNotificationOverview
     */
    content?: Array<ConferenceNotificationOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceNotificationOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceNotificationOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceNotificationOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceNotificationOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceNotificationOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceNotificationOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceSpecializedAreaDetail
 */
export interface PageConferenceSpecializedAreaDetail {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceSpecializedAreaDetail>}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    content?: Array<ConferenceSpecializedAreaDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceSpecializedAreaDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageConferenceUserDetail
 */
export interface PageConferenceUserDetail {
    /**
     * 
     * @type {number}
     * @memberof PageConferenceUserDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceUserDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageConferenceUserDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceUserDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<ConferenceUserDetail>}
     * @memberof PageConferenceUserDetail
     */
    content?: Array<ConferenceUserDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceUserDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConferenceUserDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageConferenceUserDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceUserDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceUserDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConferenceUserDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageEntryMeetingDetail
 */
export interface PageEntryMeetingDetail {
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEntryMeetingDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<EntryMeetingDetail>}
     * @memberof PageEntryMeetingDetail
     */
    content?: Array<EntryMeetingDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEntryMeetingDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageEntryMeetingOverview
 */
export interface PageEntryMeetingOverview {
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEntryMeetingOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<EntryMeetingOverview>}
     * @memberof PageEntryMeetingOverview
     */
    content?: Array<EntryMeetingOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEntryMeetingOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEntryMeetingOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEntryMeetingOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageEtcBillingPersonOverview
 */
export interface PageEtcBillingPersonOverview {
    /**
     * 
     * @type {number}
     * @memberof PageEtcBillingPersonOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcBillingPersonOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEtcBillingPersonOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEtcBillingPersonOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<EtcBillingPersonOverview>}
     * @memberof PageEtcBillingPersonOverview
     */
    content?: Array<EtcBillingPersonOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageEtcBillingPersonOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcBillingPersonOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEtcBillingPersonOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcBillingPersonOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcBillingPersonOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcBillingPersonOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageEtcFeeOverview
 */
export interface PageEtcFeeOverview {
    /**
     * 
     * @type {number}
     * @memberof PageEtcFeeOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcFeeOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEtcFeeOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEtcFeeOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<EtcFeeOverview>}
     * @memberof PageEtcFeeOverview
     */
    content?: Array<EtcFeeOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageEtcFeeOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcFeeOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEtcFeeOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcFeeOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcFeeOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcFeeOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageEtcPaymentDetail
 */
export interface PageEtcPaymentDetail {
    /**
     * 
     * @type {number}
     * @memberof PageEtcPaymentDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcPaymentDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEtcPaymentDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEtcPaymentDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<EtcPaymentDetail>}
     * @memberof PageEtcPaymentDetail
     */
    content?: Array<EtcPaymentDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageEtcPaymentDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEtcPaymentDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEtcPaymentDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcPaymentDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcPaymentDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEtcPaymentDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageJobDetail
 */
export interface PageJobDetail {
    /**
     * 
     * @type {number}
     * @memberof PageJobDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageJobDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageJobDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageJobDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<JobDetail>}
     * @memberof PageJobDetail
     */
    content?: Array<JobDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageJobDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageJobDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageJobDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageJobDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageJobDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageJobDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageMailTemplateDetail
 */
export interface PageMailTemplateDetail {
    /**
     * 
     * @type {number}
     * @memberof PageMailTemplateDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailTemplateDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMailTemplateDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageMailTemplateDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<MailTemplateDetail>}
     * @memberof PageMailTemplateDetail
     */
    content?: Array<MailTemplateDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageMailTemplateDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMailTemplateDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMailTemplateDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailTemplateDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailTemplateDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMailTemplateDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageMedicalSpecialtyCategoryDetail
 */
export interface PageMedicalSpecialtyCategoryDetail {
    /**
     * 
     * @type {number}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<MedicalSpecialtyCategoryDetail>}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    content?: Array<MedicalSpecialtyCategoryDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMedicalSpecialtyCategoryDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageMeetingDetail
 */
export interface PageMeetingDetail {
    /**
     * 
     * @type {number}
     * @memberof PageMeetingDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeetingDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMeetingDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageMeetingDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<MeetingDetail>}
     * @memberof PageMeetingDetail
     */
    content?: Array<MeetingDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageMeetingDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMeetingDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMeetingDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMeetingDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMeetingDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMeetingDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageMemberTypeDetail
 */
export interface PageMemberTypeDetail {
    /**
     * 
     * @type {number}
     * @memberof PageMemberTypeDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMemberTypeDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageMemberTypeDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageMemberTypeDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<MemberTypeDetail>}
     * @memberof PageMemberTypeDetail
     */
    content?: Array<MemberTypeDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageMemberTypeDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMemberTypeDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageMemberTypeDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageMemberTypeDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMemberTypeDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMemberTypeDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOfficerDetail
 */
export interface PageOfficerDetail {
    /**
     * 
     * @type {number}
     * @memberof PageOfficerDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfficerDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<OfficerDetail>}
     * @memberof PageOfficerDetail
     */
    content?: Array<OfficerDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfficerDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOfficerMeetingDetail
 */
export interface PageOfficerMeetingDetail {
    /**
     * 
     * @type {number}
     * @memberof PageOfficerMeetingDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerMeetingDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfficerMeetingDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerMeetingDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<OfficerMeetingDetail>}
     * @memberof PageOfficerMeetingDetail
     */
    content?: Array<OfficerMeetingDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerMeetingDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerMeetingDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfficerMeetingDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerMeetingDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerMeetingDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerMeetingDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOfficerQualificationDetail
 */
export interface PageOfficerQualificationDetail {
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfficerQualificationDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<OfficerQualificationDetail>}
     * @memberof PageOfficerQualificationDetail
     */
    content?: Array<OfficerQualificationDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfficerQualificationDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageOfficerQualificationOverview
 */
export interface PageOfficerQualificationOverview {
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfficerQualificationOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<OfficerQualificationOverview>}
     * @memberof PageOfficerQualificationOverview
     */
    content?: Array<OfficerQualificationOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfficerQualificationOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfficerQualificationOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfficerQualificationOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePaymentDetail
 */
export interface PagePaymentDetail {
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaymentDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<PaymentDetail>}
     * @memberof PagePaymentDetail
     */
    content?: Array<PaymentDetail>;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaymentDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePaymentInfoOverview
 */
export interface PagePaymentInfoOverview {
    /**
     * 
     * @type {number}
     * @memberof PagePaymentInfoOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentInfoOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaymentInfoOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentInfoOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<PaymentInfoOverview>}
     * @memberof PagePaymentInfoOverview
     */
    content?: Array<PaymentInfoOverview>;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentInfoOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentInfoOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaymentInfoOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentInfoOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentInfoOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentInfoOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePaymentOverview
 */
export interface PagePaymentOverview {
    /**
     * 
     * @type {number}
     * @memberof PagePaymentOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaymentOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<PaymentOverview>}
     * @memberof PagePaymentOverview
     */
    content?: Array<PaymentOverview>;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaymentOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePositionDetail
 */
export interface PagePositionDetail {
    /**
     * 
     * @type {number}
     * @memberof PagePositionDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePositionDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePositionDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<PositionDetail>}
     * @memberof PagePositionDetail
     */
    content?: Array<PositionDetail>;
    /**
     * 
     * @type {number}
     * @memberof PagePositionDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePositionDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePositionMeetingDetail
 */
export interface PagePositionMeetingDetail {
    /**
     * 
     * @type {number}
     * @memberof PagePositionMeetingDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionMeetingDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePositionMeetingDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePositionMeetingDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<PositionMeetingDetail>}
     * @memberof PagePositionMeetingDetail
     */
    content?: Array<PositionMeetingDetail>;
    /**
     * 
     * @type {number}
     * @memberof PagePositionMeetingDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionMeetingDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePositionMeetingDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionMeetingDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionMeetingDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionMeetingDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePositionQualificationDetail
 */
export interface PagePositionQualificationDetail {
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePositionQualificationDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<PositionQualificationDetail>}
     * @memberof PagePositionQualificationDetail
     */
    content?: Array<PositionQualificationDetail>;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePositionQualificationDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PagePositionQualificationOverview
 */
export interface PagePositionQualificationOverview {
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePositionQualificationOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<PositionQualificationOverview>}
     * @memberof PagePositionQualificationOverview
     */
    content?: Array<PositionQualificationOverview>;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePositionQualificationOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePositionQualificationOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePositionQualificationOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageQualificationBillingPersonOverview
 */
export interface PageQualificationBillingPersonOverview {
    /**
     * 
     * @type {number}
     * @memberof PageQualificationBillingPersonOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationBillingPersonOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageQualificationBillingPersonOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationBillingPersonOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<QualificationBillingPersonOverview>}
     * @memberof PageQualificationBillingPersonOverview
     */
    content?: Array<QualificationBillingPersonOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationBillingPersonOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationBillingPersonOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageQualificationBillingPersonOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationBillingPersonOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationBillingPersonOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationBillingPersonOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageQualificationDetail
 */
export interface PageQualificationDetail {
    /**
     * 
     * @type {number}
     * @memberof PageQualificationDetail
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationDetail
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageQualificationDetail
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationDetail
     */
    size?: number;
    /**
     * 
     * @type {Array<QualificationDetail>}
     * @memberof PageQualificationDetail
     */
    content?: Array<QualificationDetail>;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationDetail
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationDetail
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageQualificationDetail
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationDetail
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationDetail
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationDetail
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageQualificationFeeOverview
 */
export interface PageQualificationFeeOverview {
    /**
     * 
     * @type {number}
     * @memberof PageQualificationFeeOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationFeeOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageQualificationFeeOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationFeeOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<QualificationFeeOverview>}
     * @memberof PageQualificationFeeOverview
     */
    content?: Array<QualificationFeeOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationFeeOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationFeeOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageQualificationFeeOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationFeeOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationFeeOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationFeeOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageQualificationOverview
 */
export interface PageQualificationOverview {
    /**
     * 
     * @type {number}
     * @memberof PageQualificationOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageQualificationOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<QualificationOverview>}
     * @memberof PageQualificationOverview
     */
    content?: Array<QualificationOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageQualificationOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageQualificationOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageQualificationOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageStopHistoryOverview
 */
export interface PageStopHistoryOverview {
    /**
     * 
     * @type {number}
     * @memberof PageStopHistoryOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStopHistoryOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStopHistoryOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStopHistoryOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<StopHistoryOverview>}
     * @memberof PageStopHistoryOverview
     */
    content?: Array<StopHistoryOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageStopHistoryOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStopHistoryOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStopHistoryOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageStopHistoryOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStopHistoryOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStopHistoryOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface PageUpdateHistoryOverview
 */
export interface PageUpdateHistoryOverview {
    /**
     * 
     * @type {number}
     * @memberof PageUpdateHistoryOverview
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUpdateHistoryOverview
     */
    totalElements?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUpdateHistoryOverview
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageUpdateHistoryOverview
     */
    size?: number;
    /**
     * 
     * @type {Array<UpdateHistoryOverview>}
     * @memberof PageUpdateHistoryOverview
     */
    content?: Array<UpdateHistoryOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageUpdateHistoryOverview
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUpdateHistoryOverview
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageUpdateHistoryOverview
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageUpdateHistoryOverview
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUpdateHistoryOverview
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUpdateHistoryOverview
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    size?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    sort?: Array<string>;
}
/**
 * パスワードリセット情報
 * @export
 * @interface PasswordResetInfo
 */
export interface PasswordResetInfo {
    /**
     * ユーザー種別
     * @type {string}
     * @memberof PasswordResetInfo
     */
    userType?: PasswordResetInfoUserTypeEnum;
    /**
     * ユーザーID
     * @type {string}
     * @memberof PasswordResetInfo
     */
    userId?: string;
    /**
     * 学会ID
     * @type {number}
     * @memberof PasswordResetInfo
     */
    conferencesId?: number;
    /**
     * トークン
     * @type {string}
     * @memberof PasswordResetInfo
     */
    token?: string;
    /**
     * 変更後のパスワード
     * @type {string}
     * @memberof PasswordResetInfo
     */
    password?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PasswordResetInfoUserTypeEnum {
    Admin = 'admin',
    Conference = 'conference',
    Member = 'member',
    System = 'system'
}

/**
 * パスワードリセットトークン詳細
 * @export
 * @interface PasswordResetTokenDetail
 */
export interface PasswordResetTokenDetail {
    /**
     * ID
     * @type {number}
     * @memberof PasswordResetTokenDetail
     */
    id?: number;
    /**
     * ユーザー種別
     * @type {string}
     * @memberof PasswordResetTokenDetail
     */
    userType?: PasswordResetTokenDetailUserTypeEnum;
    /**
     * ユーザーID
     * @type {string}
     * @memberof PasswordResetTokenDetail
     */
    userId?: string;
    /**
     * トークン有効期限日時
     * @type {string}
     * @memberof PasswordResetTokenDetail
     */
    expireTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordResetTokenDetail
     */
    valid?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PasswordResetTokenDetailUserTypeEnum {
    Admin = 'admin',
    Conference = 'conference',
    Member = 'member',
    System = 'system'
}

/**
 * 請求情報
 * @export
 * @interface PaymentDetail
 */
export interface PaymentDetail {
    /**
     * ID
     * @type {number}
     * @memberof PaymentDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof PaymentDetail
     */
    conferencesId: number;
    /**
     * 名称
     * @type {string}
     * @memberof PaymentDetail
     */
    term: string;
    /**
     * 支払額
     * @type {number}
     * @memberof PaymentDetail
     */
    fee: number;
    /**
     * 請求月
     * @type {number}
     * @memberof PaymentDetail
     */
    doctorPaymentCreateMonth?: number;
    /**
     * 請求日
     * @type {number}
     * @memberof PaymentDetail
     */
    doctorPaymentCreateDay?: number;
    /**
     * 税種別
     * @type {string}
     * @memberof PaymentDetail
     */
    tax?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDetail
     */
    used?: boolean;
}
/**
 * 支払いお知らせ情報
 * @export
 * @interface PaymentInfoOverview
 */
export interface PaymentInfoOverview {
    /**
     * ID
     * @type {number}
     * @memberof PaymentInfoOverview
     */
    id?: number;
    /**
     * 支払いID
     * @type {number}
     * @memberof PaymentInfoOverview
     */
    doctorPaymentId?: number;
    /**
     * 公開日
     * @type {string}
     * @memberof PaymentInfoOverview
     */
    created?: string;
    /**
     * 支払い内容
     * @type {string}
     * @memberof PaymentInfoOverview
     */
    paymentContent?: string;
    /**
     * 支払い期限年月日
     * @type {string}
     * @memberof PaymentInfoOverview
     */
    deadline?: string;
    /**
     * 残り日数(支払い何日前の通知か)
     * @type {number}
     * @memberof PaymentInfoOverview
     */
    diffDayCount?: number;
}
/**
 * 支払情報
 * @export
 * @interface PaymentOverview
 */
export interface PaymentOverview {
    /**
     * 支払いID
     * @type {number}
     * @memberof PaymentOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof PaymentOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof PaymentOverview
     */
    conferencesId?: number;
    /**
     * 年度
     * @type {number}
     * @memberof PaymentOverview
     */
    fiscalYear?: number;
    /**
     * 請求額
     * @type {number}
     * @memberof PaymentOverview
     */
    billAmount?: number;
    /**
     * 支払額
     * @type {number}
     * @memberof PaymentOverview
     */
    paymentAmount?: number;
    /**
     * 支払い状態
     * @type {string}
     * @memberof PaymentOverview
     */
    paymentStatus?: string;
    /**
     * 支払い方法
     * @type {string}
     * @memberof PaymentOverview
     */
    paymentType?: string;
    /**
     * 支払日
     * @type {string}
     * @memberof PaymentOverview
     */
    paymentDate?: string;
    /**
     * 支払内容
     * @type {string}
     * @memberof PaymentOverview
     */
    paymentContent?: string;
    /**
     * 支払い内容の種類
     * @type {string}
     * @memberof PaymentOverview
     */
    contentType?: PaymentOverviewContentTypeEnum;
    /**
     * 支払い実行の種別
     * @type {string}
     * @memberof PaymentOverview
     */
    paymentExecutionType?: PaymentOverviewPaymentExecutionTypeEnum;
    /**
     * 領収書対応
     * @type {boolean}
     * @memberof PaymentOverview
     */
    receiptCanBeIssued?: boolean;
    /**
     * 支払いオプション
     * @type {Array<string>}
     * @memberof PaymentOverview
     */
    availablePaymentType?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentOverviewContentTypeEnum {
    AnnualFee = 'AnnualFee',
    Qualification = 'Qualification',
    Etc = 'Etc'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentOverviewPaymentExecutionTypeEnum {
    Member = 'Member',
    Conference = 'Conference',
    Admin = 'Admin',
    DailyShell = 'DailyShell'
}

/**
 * 委員会詳細
 * @export
 * @interface PositionDetail
 */
export interface PositionDetail {
    /**
     * ID
     * @type {number}
     * @memberof PositionDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof PositionDetail
     */
    conferencesId: number;
    /**
     * 名称
     * @type {string}
     * @memberof PositionDetail
     */
    term: string;
    /**
     * 委員会発足日
     * @type {string}
     * @memberof PositionDetail
     */
    dateFrom?: string;
}
/**
 * 委員会開催詳細
 * @export
 * @interface PositionMeetingDetail
 */
export interface PositionMeetingDetail {
    /**
     * ID
     * @type {number}
     * @memberof PositionMeetingDetail
     */
    id?: number;
    /**
     * 委員会ID
     * @type {number}
     * @memberof PositionMeetingDetail
     */
    positionsId: number;
    /**
     * 開催名称
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    term: string;
    /**
     * 開催場所
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    venue?: string;
    /**
     * 開催日
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    meetingDate: string;
    /**
     * 開催時間FROM
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    meetingTimeFrom: string;
    /**
     * 開催時間TO
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    meetingTimeTo: string;
    /**
     * 開催案内通知日
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    releaseDate?: string;
    /**
     * 備考
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    remarks?: string;
    /**
     * 資料1
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    fileName1?: string;
    /**
     * 資料2
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    fileName2?: string;
    /**
     * 資料3
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    fileName3?: string;
    /**
     * 資料4
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    fileName4?: string;
    /**
     * 資料5
     * @type {string}
     * @memberof PositionMeetingDetail
     */
    fileName5?: string;
}
/**
 * 委員会名簿情報
 * @export
 * @interface PositionQualificationDetail
 */
export interface PositionQualificationDetail {
    /**
     * ID
     * @type {number}
     * @memberof PositionQualificationDetail
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof PositionQualificationDetail
     */
    conferenceDoctorsId: number;
    /**
     * 委員会ID
     * @type {number}
     * @memberof PositionQualificationDetail
     */
    positionsId: number;
    /**
     * 役職
     * @type {string}
     * @memberof PositionQualificationDetail
     */
    positionTerm?: string;
    /**
     * 任期状態
     * @type {string}
     * @memberof PositionQualificationDetail
     */
    status?: string;
    /**
     * 任期就任日
     * @type {string}
     * @memberof PositionQualificationDetail
     */
    tenureDateFrom: string;
    /**
     * 任期満了日
     * @type {string}
     * @memberof PositionQualificationDetail
     */
    tenureDateTo?: string;
    /**
     * 備考
     * @type {string}
     * @memberof PositionQualificationDetail
     */
    remarks?: string;
    /**
     * 
     * @type {ConferenceDoctorDetail}
     * @memberof PositionQualificationDetail
     */
    memberDetail?: ConferenceDoctorDetail;
}
/**
 * 委員会情報
 * @export
 * @interface PositionQualificationOverview
 */
export interface PositionQualificationOverview {
    /**
     * ID
     * @type {number}
     * @memberof PositionQualificationOverview
     */
    id?: number;
    /**
     * 委員会ID
     * @type {number}
     * @memberof PositionQualificationOverview
     */
    positionsId?: number;
    /**
     * 委員会名
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    committeeName?: string;
    /**
     * 役職
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    position?: string;
    /**
     * 状態
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    status?: string;
    /**
     * 任期就任日
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    termDateFrom?: string;
    /**
     * 任期満了日
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    termDateTo?: string;
    /**
     * 備考
     * @type {string}
     * @memberof PositionQualificationOverview
     */
    remarks?: string;
}
/**
 * 資格・認定請求者情報
 * @export
 * @interface QualificationBillingPersonOverview
 */
export interface QualificationBillingPersonOverview {
    /**
     * ID
     * @type {number}
     * @memberof QualificationBillingPersonOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof QualificationBillingPersonOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    firstNameEn?: string;
    /**
     * 勤務先
     * @type {Array<WorkAddress>}
     * @memberof QualificationBillingPersonOverview
     */
    workAddress?: Array<WorkAddress>;
    /**
     * 資格・認定請求名
     * @type {string}
     * @memberof QualificationBillingPersonOverview
     */
    billingName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QualificationBillingPersonOverview
     */
    createBilling?: boolean;
}
/**
 * 資格・認定情報
 * @export
 * @interface QualificationDetail
 */
export interface QualificationDetail {
    /**
     * ID
     * @type {number}
     * @memberof QualificationDetail
     */
    id?: number;
    /**
     * 学会ID
     * @type {number}
     * @memberof QualificationDetail
     */
    conferencesId: number;
    /**
     * 種別
     * @type {number}
     * @memberof QualificationDetail
     */
    type?: number;
    /**
     * 名称
     * @type {string}
     * @memberof QualificationDetail
     */
    term: string;
}
/**
 * 資格・認定入金状態情報
 * @export
 * @interface QualificationFeeOverview
 */
export interface QualificationFeeOverview {
    /**
     * 支払いID
     * @type {number}
     * @memberof QualificationFeeOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof QualificationFeeOverview
     */
    conferenceDoctorsId?: number;
    /**
     * 会員名 姓
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    lastName?: string;
    /**
     * 会員名 名
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    firstName?: string;
    /**
     * 会員名 姓カナ
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    lastNameKana?: string;
    /**
     * 会員名 名カナ
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    firstNameKana?: string;
    /**
     * last name(姓)
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    lastNameEn?: string;
    /**
     * first name(名)
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    firstNameEn?: string;
    /**
     * 年度
     * @type {number}
     * @memberof QualificationFeeOverview
     */
    fiscalYear?: number;
    /**
     * 資格・認定請求名
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    billingName?: string;
    /**
     * 内容
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    paymentContent?: string;
    /**
     * 請求額
     * @type {number}
     * @memberof QualificationFeeOverview
     */
    billAmount?: number;
    /**
     * 入金状態
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    paymentStatus?: string;
    /**
     * 入金方法
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    paymentType?: string;
    /**
     * 入金日
     * @type {string}
     * @memberof QualificationFeeOverview
     */
    paymentDate?: string;
}
/**
 * 資格・認定情報
 * @export
 * @interface QualificationOverview
 */
export interface QualificationOverview {
    /**
     * ID
     * @type {number}
     * @memberof QualificationOverview
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof QualificationOverview
     */
    conferenceDoctorsId: number;
    /**
     * 資格・認定ID
     * @type {number}
     * @memberof QualificationOverview
     */
    qualificationsId: number;
    /**
     * 資格・認定番号
     * @type {string}
     * @memberof QualificationOverview
     */
    qualificationNumber: string;
    /**
     * 取得年月日
     * @type {string}
     * @memberof QualificationOverview
     */
    dateFrom: string;
    /**
     * 次回更新年月日
     * @type {string}
     * @memberof QualificationOverview
     */
    dateTo?: string;
    /**
     * 更新年月日
     * @type {string}
     * @memberof QualificationOverview
     */
    updateDate?: string;
    /**
     * 状態
     * @type {string}
     * @memberof QualificationOverview
     */
    status: string;
    /**
     * 備考
     * @type {string}
     * @memberof QualificationOverview
     */
    note?: string;
    /**
     * 資格・認定名
     * @type {string}
     * @memberof QualificationOverview
     */
    qualificationName?: string;
    /**
     * 資格・認定種別(ID)
     * @type {number}
     * @memberof QualificationOverview
     */
    type?: number;
}
/**
 * 領収書基本情報
 * @export
 * @interface ReceiptDetail
 */
export interface ReceiptDetail {
    /**
     * 支払いID
     * @type {number}
     * @memberof ReceiptDetail
     */
    doctorPaymentId?: number;
    /**
     * 領収書番号
     * @type {string}
     * @memberof ReceiptDetail
     */
    receiptNo?: string;
    /**
     * 内容
     * @type {string}
     * @memberof ReceiptDetail
     */
    paymentName?: string;
    /**
     * 支払額
     * @type {number}
     * @memberof ReceiptDetail
     */
    paymentAmount?: number;
    /**
     * 支払い方法
     * @type {string}
     * @memberof ReceiptDetail
     */
    paymentType?: string;
    /**
     * 支払日
     * @type {string}
     * @memberof ReceiptDetail
     */
    paymentDate?: string;
    /**
     * 学会名
     * @type {string}
     * @memberof ReceiptDetail
     */
    lawCompany?: string;
    /**
     * 住所
     * @type {string}
     * @memberof ReceiptDetail
     */
    lawAddress?: string;
    /**
     * TEL
     * @type {string}
     * @memberof ReceiptDetail
     */
    lawTel?: string;
    /**
     * FAX
     * @type {string}
     * @memberof ReceiptDetail
     */
    lawFax?: string;
    /**
     * 発行可能
     * @type {boolean}
     * @memberof ReceiptDetail
     */
    canBeIssued?: boolean;
    /**
     * 次回発行領収書番号
     * @type {string}
     * @memberof ReceiptDetail
     */
    nextOutputNo?: string;
    /**
     * 税種別
     * @type {string}
     * @memberof ReceiptDetail
     */
    tax?: string;
    /**
     * インボイス登録番号
     * @type {string}
     * @memberof ReceiptDetail
     */
    invoiceNumber?: string;
    /**
     * サービス会員のID
     * @type {number}
     * @memberof ReceiptDetail
     */
    conferenceDoctorsId?: number;
}
/**
 * 領収書情報
 * @export
 * @interface ReceiptOverview
 */
export interface ReceiptOverview {
    /**
     * 領収書ID
     * @type {number}
     * @memberof ReceiptOverview
     */
    id?: number;
    /**
     * 領収書番号
     * @type {string}
     * @memberof ReceiptOverview
     */
    outputNo?: string;
    /**
     * 宛名
     * @type {string}
     * @memberof ReceiptOverview
     */
    freeAddress?: string;
    /**
     * 内容
     * @type {string}
     * @memberof ReceiptOverview
     */
    paymentName?: string;
    /**
     * 発行日
     * @type {string}
     * @memberof ReceiptOverview
     */
    downloadedDate?: string;
}
/**
 * セレクトコンポーネント用のtextおよびvalue(整数)
 * @export
 * @interface SelectValueInt
 */
export interface SelectValueInt {
    /**
     * text
     * @type {string}
     * @memberof SelectValueInt
     */
    text?: string;
    /**
     * value(整数)
     * @type {number}
     * @memberof SelectValueInt
     */
    value?: number;
}
/**
 * お知らせ配信名簿登録者詳細
 * @export
 * @interface SendListMemberDetail
 */
export interface SendListMemberDetail {
    /**
     * ID
     * @type {number}
     * @memberof SendListMemberDetail
     */
    id?: number;
    /**
     * 名前
     * @type {string}
     * @memberof SendListMemberDetail
     */
    userName?: string;
    /**
     * 会員番号
     * @type {string}
     * @memberof SendListMemberDetail
     */
    conferenceNumber?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof SendListMemberDetail
     */
    mail?: string;
}
/**
 * 決済情報
 * @export
 * @interface SettlementInfo
 */
export interface SettlementInfo {
    /**
     * GMOカード情報トークン
     * @type {string}
     * @memberof SettlementInfo
     */
    gmoToken?: string;
    /**
     * GMOカード登録連番
     * @type {number}
     * @memberof SettlementInfo
     */
    gmoCardSeq?: number;
    /**
     * GMO会員ID
     * @type {string}
     * @memberof SettlementInfo
     */
    gmoMemberId?: string;
    /**
     * 支払額
     * @type {number}
     * @memberof SettlementInfo
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
}
/**
 * サービス会員ID
 * @export
 * @interface StopHistoryOverview
 */
export interface StopHistoryOverview {
    /**
     * ID
     * @type {number}
     * @memberof StopHistoryOverview
     */
    id?: number;
    /**
     * 理由(状態)
     * @type {string}
     * @memberof StopHistoryOverview
     */
    status?: string;
    /**
     * 休会･退会理由
     * @type {string}
     * @memberof StopHistoryOverview
     */
    stopReason?: string;
    /**
     * 日数
     * @type {number}
     * @memberof StopHistoryOverview
     */
    stopDayCount?: number;
    /**
     * 停止年月日FROM
     * @type {string}
     * @memberof StopHistoryOverview
     */
    stopDateFrom?: string;
    /**
     * 停止年月日TO
     * @type {string}
     * @memberof StopHistoryOverview
     */
    stopDateTo?: string;
    /**
     * 会員歴に含むか
     * @type {boolean}
     * @memberof StopHistoryOverview
     */
    excludedDuration?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateHistoryOverview
 */
export interface UpdateHistoryOverview {
    /**
     * 
     * @type {number}
     * @memberof UpdateHistoryOverview
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateHistoryOverview
     */
    updateUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateHistoryOverview
     */
    updateUserType?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHistoryOverview
     */
    modified?: string;
}
/**
 * アップロードされたファイル情報
 * @export
 * @interface UploadedFile
 */
export interface UploadedFile {
    /**
     * ファイル名
     * @type {string}
     * @memberof UploadedFile
     */
    fileName?: string;
    /**
     * ファイルURL
     * @type {string}
     * @memberof UploadedFile
     */
    url?: string;
}
/**
 * パスワード更新パラメータ
 * @export
 * @interface UserForm
 */
export interface UserForm {
    /**
     * 新しいパスワード
     * @type {string}
     * @memberof UserForm
     */
    newPassword?: string;
}
/**
 * 勤務先情報
 * @export
 * @interface WorkAddress
 */
export interface WorkAddress {
    /**
     * ID
     * @type {number}
     * @memberof WorkAddress
     */
    id?: number;
    /**
     * サービス会員ID
     * @type {number}
     * @memberof WorkAddress
     */
    conferenceDoctorsId?: number;
    /**
     * 施設ID
     * @type {number}
     * @memberof WorkAddress
     */
    facilitiesId?: number;
    /**
     * 医局ID
     * @type {number}
     * @memberof WorkAddress
     */
    medicalOfficesId?: number;
    /**
     * 施設名
     * @type {string}
     * @memberof WorkAddress
     */
    faciltyFreeTerm?: string;
    /**
     * 医局名
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficeTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficePostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficePrefectures?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficeAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficeAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficeTel?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkAddress
     */
    medicalOfficeFax?: string;
    /**
     * 在住区分
     * @type {boolean}
     * @memberof WorkAddress
     */
    isLivingAbroad?: boolean;
    /**
     * 主要な勤務先
     * @type {boolean}
     * @memberof WorkAddress
     */
    isDestination?: boolean;
}

/**
 * AdminConferenceDoctorRestControllerApi - axios parameter creator
 * @export
 */
export const AdminConferenceDoctorRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学会の会員数情報を取得する
         * @summary 学会の会員数情報を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminConferenceMembership: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAdminConferenceMembership', 'id', id)
            const localVarPath = `/api/admin/conferences/{id}/membership`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminConferenceDoctorRestControllerApi - functional programming interface
 * @export
 */
export const AdminConferenceDoctorRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminConferenceDoctorRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 学会の会員数情報を取得する
         * @summary 学会の会員数情報を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminConferenceMembership(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceMembership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminConferenceMembership(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminConferenceDoctorRestControllerApi - factory interface
 * @export
 */
export const AdminConferenceDoctorRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminConferenceDoctorRestControllerApiFp(configuration)
    return {
        /**
         * 学会の会員数情報を取得する
         * @summary 学会の会員数情報を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminConferenceMembership(id: number, options?: any): AxiosPromise<ConferenceMembership> {
            return localVarFp.fetchAdminConferenceMembership(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminConferenceDoctorRestControllerApi - object-oriented interface
 * @export
 * @class AdminConferenceDoctorRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminConferenceDoctorRestControllerApi extends BaseAPI {
    /**
     * 学会の会員数情報を取得する
     * @summary 学会の会員数情報を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceDoctorRestControllerApi
     */
    public fetchAdminConferenceMembership(id: number, options?: any) {
        return AdminConferenceDoctorRestControllerApiFp(this.configuration).fetchAdminConferenceMembership(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminConferenceRestControllerApi - axios parameter creator
 * @export
 */
export const AdminConferenceRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学会のサブドメインの重複をチェックする
         * @summary 学会のサブドメインの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckSubdomainParam} checkSubdomainParam サブドメインパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDuplicateSubdomainByAdmin: async (id: number, checkSubdomainParam: CheckSubdomainParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConferenceDuplicateSubdomainByAdmin', 'id', id)
            // verify required parameter 'checkSubdomainParam' is not null or undefined
            assertParamExists('checkConferenceDuplicateSubdomainByAdmin', 'checkSubdomainParam', checkSubdomainParam)
            const localVarPath = `/api/admin/conferences/{id}/subdomain/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkSubdomainParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmailByAdmin: async (id: number, checkEmailParam: CheckEmailParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmailByAdmin', 'id', id)
            // verify required parameter 'checkEmailParam' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmailByAdmin', 'checkEmailParam', checkEmailParam)
            const localVarPath = `/api/admin/conferences/{id}/users/email/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会を削除する
         * @summary 指定した学会の削除
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceByAdmin: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceByAdmin', 'id', id)
            const localVarPath = `/api/admin/conferences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceFileByAdmin: async (id: number, type: 'logo' | 'stamp', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceFileByAdmin', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteConferenceFileByAdmin', 'type', type)
            const localVarPath = `/api/admin/conferences/{id}/conferenceFiles/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceUserByAdmin: async (id: number, conferenceUsersId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceUserByAdmin', 'id', id)
            // verify required parameter 'conferenceUsersId' is not null or undefined
            assertParamExists('deleteConferenceUserByAdmin', 'conferenceUsersId', conferenceUsersId)
            const localVarPath = `/api/admin/conferences/{id}/users/{conferenceUsersId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"conferenceUsersId"}}`, encodeURIComponent(String(conferenceUsersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFileByAdmin: async (id: number, type: 'logo' | 'stamp', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceFileByAdmin', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('fetchConferenceFileByAdmin', 'type', type)
            const localVarPath = `/api/admin/conferences/{id}/conferenceFiles/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFilesByAdmin: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceFilesByAdmin', 'id', id)
            const localVarPath = `/api/admin/conferences/{id}/conferenceFiles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetailByAdmin: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceManagementDetailByAdmin', 'id', id)
            const localVarPath = `/api/admin/conferences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会一覧を取得する
         * @summary 学会一覧取得
         * @param {Array<number>} [ids] 
         * @param {string} [conferenceName] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetailsByAdmin: async (ids?: Array<number>, conferenceName?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/conferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (conferenceName !== undefined) {
                localVarQueryParameter['conferenceName'] = conferenceName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUserByAdmin: async (id: number, conferenceUsersId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceUserByAdmin', 'id', id)
            // verify required parameter 'conferenceUsersId' is not null or undefined
            assertParamExists('fetchConferenceUserByAdmin', 'conferenceUsersId', conferenceUsersId)
            const localVarPath = `/api/admin/conferences/{id}/users/{conferenceUsersId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"conferenceUsersId"}}`, encodeURIComponent(String(conferenceUsersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} id 学会ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUsersByAdmin: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceUsersByAdmin', 'id', id)
            const localVarPath = `/api/admin/conferences/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 登録されている学会数を取得する
         * @summary 登録されている学会数を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCountConferenceByAdmin: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/conferences/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理情報を登録・更新する
         * @summary 学会管理情報登録・更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceManagementDetailByAdmin: async (conferenceManagementDetail: ConferenceManagementDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceManagementDetail' is not null or undefined
            assertParamExists('updateConferenceManagementDetailByAdmin', 'conferenceManagementDetail', conferenceManagementDetail)
            const localVarPath = `/api/admin/conferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceManagementDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {number} id 学会ID
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUserByAdmin: async (id: number, conferenceUserDetail: ConferenceUserDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConferenceUserByAdmin', 'id', id)
            // verify required parameter 'conferenceUserDetail' is not null or undefined
            assertParamExists('updateConferenceUserByAdmin', 'conferenceUserDetail', conferenceUserDetail)
            const localVarPath = `/api/admin/conferences/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceUserDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceFileByAdmin: async (id: number, type: 'logo' | 'stamp', file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConferenceFileByAdmin', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadConferenceFileByAdmin', 'type', type)
            const localVarPath = `/api/admin/conferences/{id}/conferenceFiles/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminConferenceRestControllerApi - functional programming interface
 * @export
 */
export const AdminConferenceRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminConferenceRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 学会のサブドメインの重複をチェックする
         * @summary 学会のサブドメインの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckSubdomainParam} checkSubdomainParam サブドメインパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceDuplicateSubdomainByAdmin(id: number, checkSubdomainParam: CheckSubdomainParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceDuplicateSubdomainByAdmin(id, checkSubdomainParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceUserDuplicateEmailByAdmin(id: number, checkEmailParam: CheckEmailParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceUserDuplicateEmailByAdmin(id, checkEmailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会を削除する
         * @summary 指定した学会の削除
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceByAdmin(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceByAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceFileByAdmin(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceUserByAdmin(id, conferenceUsersId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceFileByAdmin(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceFilesByAdmin(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceFilesByAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceManagementDetailByAdmin(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceManagementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceManagementDetailByAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会一覧を取得する
         * @summary 学会一覧取得
         * @param {Array<number>} [ids] 
         * @param {string} [conferenceName] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceManagementDetailsByAdmin(ids?: Array<number>, conferenceName?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceManagementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceManagementDetailsByAdmin(ids, conferenceName, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceUserByAdmin(id, conferenceUsersId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} id 学会ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceUsersByAdmin(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceUsersByAdmin(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 登録されている学会数を取得する
         * @summary 登録されている学会数を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchCountConferenceByAdmin(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchCountConferenceByAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理情報を登録・更新する
         * @summary 学会管理情報登録・更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceManagementDetailByAdmin(conferenceManagementDetail: ConferenceManagementDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceManagementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceManagementDetailByAdmin(conferenceManagementDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {number} id 学会ID
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceUserByAdmin(id: number, conferenceUserDetail: ConferenceUserDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceUserByAdmin(id, conferenceUserDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConferenceFileByAdmin(id, type, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminConferenceRestControllerApi - factory interface
 * @export
 */
export const AdminConferenceRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminConferenceRestControllerApiFp(configuration)
    return {
        /**
         * 学会のサブドメインの重複をチェックする
         * @summary 学会のサブドメインの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckSubdomainParam} checkSubdomainParam サブドメインパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDuplicateSubdomainByAdmin(id: number, checkSubdomainParam: CheckSubdomainParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceDuplicateSubdomainByAdmin(id, checkSubdomainParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmailByAdmin(id: number, checkEmailParam: CheckEmailParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceUserDuplicateEmailByAdmin(id, checkEmailParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会を削除する
         * @summary 指定した学会の削除
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceByAdmin(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceByAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceFileByAdmin(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceUserByAdmin(id, conferenceUsersId, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchConferenceFileByAdmin(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFilesByAdmin(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchConferenceFilesByAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetailByAdmin(id: number, options?: any): AxiosPromise<ConferenceManagementDetail> {
            return localVarFp.fetchConferenceManagementDetailByAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会一覧を取得する
         * @summary 学会一覧取得
         * @param {Array<number>} [ids] 
         * @param {string} [conferenceName] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetailsByAdmin(ids?: Array<number>, conferenceName?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceManagementDetail> {
            return localVarFp.fetchConferenceManagementDetailsByAdmin(ids, conferenceName, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any): AxiosPromise<ConferenceUserDetail> {
            return localVarFp.fetchConferenceUserByAdmin(id, conferenceUsersId, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} id 学会ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUsersByAdmin(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceUserDetail> {
            return localVarFp.fetchConferenceUsersByAdmin(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 登録されている学会数を取得する
         * @summary 登録されている学会数を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCountConferenceByAdmin(options?: any): AxiosPromise<number> {
            return localVarFp.fetchCountConferenceByAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理情報を登録・更新する
         * @summary 学会管理情報登録・更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceManagementDetailByAdmin(conferenceManagementDetail: ConferenceManagementDetail, options?: any): AxiosPromise<ConferenceManagementDetail> {
            return localVarFp.updateConferenceManagementDetailByAdmin(conferenceManagementDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {number} id 学会ID
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUserByAdmin(id: number, conferenceUserDetail: ConferenceUserDetail, options?: any): AxiosPromise<ConferenceUserDetail> {
            return localVarFp.updateConferenceUserByAdmin(id, conferenceUserDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {number} id 学会ID
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadConferenceFileByAdmin(id, type, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminConferenceRestControllerApi - object-oriented interface
 * @export
 * @class AdminConferenceRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminConferenceRestControllerApi extends BaseAPI {
    /**
     * 学会のサブドメインの重複をチェックする
     * @summary 学会のサブドメインの重複をチェックする
     * @param {number} id 学会ID
     * @param {CheckSubdomainParam} checkSubdomainParam サブドメインパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public checkConferenceDuplicateSubdomainByAdmin(id: number, checkSubdomainParam: CheckSubdomainParam, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).checkConferenceDuplicateSubdomainByAdmin(id, checkSubdomainParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザーのメールアドレスの重複をチェックする
     * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
     * @param {number} id 学会ID
     * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public checkConferenceUserDuplicateEmailByAdmin(id: number, checkEmailParam: CheckEmailParam, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).checkConferenceUserDuplicateEmailByAdmin(id, checkEmailParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会を削除する
     * @summary 指定した学会の削除
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public deleteConferenceByAdmin(id: number, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).deleteConferenceByAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイルを削除する
     * @summary 学会のファイルを削除
     * @param {number} id 学会ID
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public deleteConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).deleteConferenceFileByAdmin(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会管理ユーザーを削除する
     * @summary 指定した学会管理ユーザーの削除
     * @param {number} id 学会ID
     * @param {number} conferenceUsersId 学会管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public deleteConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).deleteConferenceUserByAdmin(id, conferenceUsersId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイルを取得する
     * @summary 学会のファイルを取得
     * @param {number} id 学会ID
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceFileByAdmin(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイル一覧を取得する
     * @summary 学会のファイル一覧を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceFilesByAdmin(id: number, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceFilesByAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会情報を取得する
     * @summary 学会情報取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceManagementDetailByAdmin(id: number, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceManagementDetailByAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会一覧を取得する
     * @summary 学会一覧取得
     * @param {Array<number>} [ids] 
     * @param {string} [conferenceName] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceManagementDetailsByAdmin(ids?: Array<number>, conferenceName?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceManagementDetailsByAdmin(ids, conferenceName, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会管理ユーザーの詳細を取得する
     * @summary 学会管理ユーザーの詳細を取得
     * @param {number} id 学会ID
     * @param {number} conferenceUsersId 学会管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceUserByAdmin(id: number, conferenceUsersId: number, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceUserByAdmin(id, conferenceUsersId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザー一覧を取得する
     * @summary 学会管理ユーザー一覧取得
     * @param {number} id 学会ID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchConferenceUsersByAdmin(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchConferenceUsersByAdmin(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 登録されている学会数を取得する
     * @summary 登録されている学会数を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public fetchCountConferenceByAdmin(options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).fetchCountConferenceByAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理情報を登録・更新する
     * @summary 学会管理情報登録・更新
     * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public updateConferenceManagementDetailByAdmin(conferenceManagementDetail: ConferenceManagementDetail, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).updateConferenceManagementDetailByAdmin(conferenceManagementDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザーを登録・更新する
     * @summary 学会管理ユーザー登録・更新
     * @param {number} id 学会ID
     * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public updateConferenceUserByAdmin(id: number, conferenceUserDetail: ConferenceUserDetail, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).updateConferenceUserByAdmin(id, conferenceUserDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイルをアップロードする
     * @summary 学会のファイルをアップロード
     * @param {number} id 学会ID
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminConferenceRestControllerApi
     */
    public uploadConferenceFileByAdmin(id: number, type: 'logo' | 'stamp', file?: any, options?: any) {
        return AdminConferenceRestControllerApiFp(this.configuration).uploadConferenceFileByAdmin(id, type, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminGmoCallbackApi - axios parameter creator
 * @export
 */
export const AdminGmoCallbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CvsCallbackEntity} [cvsCallbackEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmoCallback: async (cvsCallbackEntity?: CvsCallbackEntity, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/callback/gmo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cvsCallbackEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminGmoCallbackApi - functional programming interface
 * @export
 */
export const AdminGmoCallbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminGmoCallbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CvsCallbackEntity} [cvsCallbackEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmoCallback(cvsCallbackEntity?: CvsCallbackEntity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmoCallback(cvsCallbackEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminGmoCallbackApi - factory interface
 * @export
 */
export const AdminGmoCallbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminGmoCallbackApiFp(configuration)
    return {
        /**
         * 
         * @param {CvsCallbackEntity} [cvsCallbackEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmoCallback(cvsCallbackEntity?: CvsCallbackEntity, options?: any): AxiosPromise<string> {
            return localVarFp.gmoCallback(cvsCallbackEntity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminGmoCallbackApi - object-oriented interface
 * @export
 * @class AdminGmoCallbackApi
 * @extends {BaseAPI}
 */
export class AdminGmoCallbackApi extends BaseAPI {
    /**
     * 
     * @param {CvsCallbackEntity} [cvsCallbackEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminGmoCallbackApi
     */
    public gmoCallback(cvsCallbackEntity?: CvsCallbackEntity, options?: any) {
        return AdminGmoCallbackApiFp(this.configuration).gmoCallback(cvsCallbackEntity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminInfoRestControllerApi - axios parameter creator
 * @export
 */
export const AdminInfoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した運営からのお知らせを削除する
         * @summary 指定した運営からのお知らせの削除
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminInfoDetail', 'id', id)
            const localVarPath = `/api/admin/adminInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した運営からのお知らせを取得する
         * @summary 指定した運営からのお知らせ取得
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAdminInfoDetail', 'id', id)
            const localVarPath = `/api/admin/adminInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した運営からのお知らせ一覧を取得する
         * @summary 指定した運営からのお知らせ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetails: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/adminInfos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した運営からの運営からのお知らせを更新・追加する
         * @summary 指定した運営からのお知らせの更新・追加
         * @param {AdminInfoDetail} adminInfoDetail 運営からのお知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminInfoDetail: async (adminInfoDetail: AdminInfoDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminInfoDetail' is not null or undefined
            assertParamExists('updateAdminInfoDetail', 'adminInfoDetail', adminInfoDetail)
            const localVarPath = `/api/admin/adminInfos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminInfoDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInfoRestControllerApi - functional programming interface
 * @export
 */
export const AdminInfoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInfoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した運営からのお知らせを削除する
         * @summary 指定した運営からのお知らせの削除
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した運営からのお知らせを取得する
         * @summary 指定した運営からのお知らせ取得
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した運営からのお知らせ一覧を取得する
         * @summary 指定した運営からのお知らせ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoDetails(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoDetails(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した運営からの運営からのお知らせを更新・追加する
         * @summary 指定した運営からのお知らせの更新・追加
         * @param {AdminInfoDetail} adminInfoDetail 運営からのお知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminInfoDetail(adminInfoDetail: AdminInfoDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminInfoDetail(adminInfoDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminInfoRestControllerApi - factory interface
 * @export
 */
export const AdminInfoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInfoRestControllerApiFp(configuration)
    return {
        /**
         * 指定した運営からのお知らせを削除する
         * @summary 指定した運営からのお知らせの削除
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminInfoDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAdminInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した運営からのお知らせを取得する
         * @summary 指定した運営からのお知らせ取得
         * @param {number} id 運営からのお知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetail(id: number, options?: any): AxiosPromise<AdminInfoDetail> {
            return localVarFp.fetchAdminInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した運営からのお知らせ一覧を取得する
         * @summary 指定した運営からのお知らせ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetails(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdminInfoDetail> {
            return localVarFp.fetchAdminInfoDetails(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した運営からの運営からのお知らせを更新・追加する
         * @summary 指定した運営からのお知らせの更新・追加
         * @param {AdminInfoDetail} adminInfoDetail 運営からのお知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminInfoDetail(adminInfoDetail: AdminInfoDetail, options?: any): AxiosPromise<AdminInfoDetail> {
            return localVarFp.updateAdminInfoDetail(adminInfoDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminInfoRestControllerApi - object-oriented interface
 * @export
 * @class AdminInfoRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminInfoRestControllerApi extends BaseAPI {
    /**
     * 指定した運営からのお知らせを削除する
     * @summary 指定した運営からのお知らせの削除
     * @param {number} id 運営からのお知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInfoRestControllerApi
     */
    public deleteAdminInfoDetail(id: number, options?: any) {
        return AdminInfoRestControllerApiFp(this.configuration).deleteAdminInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した運営からのお知らせを取得する
     * @summary 指定した運営からのお知らせ取得
     * @param {number} id 運営からのお知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInfoRestControllerApi
     */
    public fetchAdminInfoDetail(id: number, options?: any) {
        return AdminInfoRestControllerApiFp(this.configuration).fetchAdminInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した運営からのお知らせ一覧を取得する
     * @summary 指定した運営からのお知らせ一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInfoRestControllerApi
     */
    public fetchAdminInfoDetails(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminInfoRestControllerApiFp(this.configuration).fetchAdminInfoDetails(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した運営からの運営からのお知らせを更新・追加する
     * @summary 指定した運営からのお知らせの更新・追加
     * @param {AdminInfoDetail} adminInfoDetail 運営からのお知らせ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInfoRestControllerApi
     */
    public updateAdminInfoDetail(adminInfoDetail: AdminInfoDetail, options?: any) {
        return AdminInfoRestControllerApiFp(this.configuration).updateAdminInfoDetail(adminInfoDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminRestControllerApi - axios parameter creator
 * @export
 */
export const AdminRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した運営管理詳細を削除する
         * @summary 指定した運営管理詳細の削除
         * @param {number} id 運営管理情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminDetail', 'id', id)
            const localVarPath = `/api/admin/detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobsDetail1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJobsDetail1', 'id', id)
            const localVarPath = `/api/admin/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalSpecialtyCategoryDetail1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMedicalSpecialtyCategoryDetail1', 'id', id)
            const localVarPath = `/api/admin/medicalSpecialtyCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 運営管理詳細を取得する
         * @summary 運営管理詳細を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetail1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchJobsDetail1', 'id', id)
            const localVarPath = `/api/admin/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetails1: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetail1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMedicalSpecialtyCategoryDetail1', 'id', id)
            const localVarPath = `/api/admin/medicalSpecialtyCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetails1: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/medicalSpecialtyCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 運営管理詳細を登録・更新する
         * @summary 運営管理詳細登録・更新
         * @param {AdminDetail} adminDetail 運営管理詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminDetail: async (adminDetail: AdminDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminDetail' is not null or undefined
            assertParamExists('updateAdminDetail', 'adminDetail', adminDetail)
            const localVarPath = `/api/admin/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobsDetail1: async (jobDetail: JobDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobDetail' is not null or undefined
            assertParamExists('updateJobsDetail1', 'jobDetail', jobDetail)
            const localVarPath = `/api/admin/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalSpecialtyCategoryDetail1: async (medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalSpecialtyCategoryDetail' is not null or undefined
            assertParamExists('updateMedicalSpecialtyCategoryDetail1', 'medicalSpecialtyCategoryDetail', medicalSpecialtyCategoryDetail)
            const localVarPath = `/api/admin/medicalSpecialtyCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalSpecialtyCategoryDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRestControllerApi - functional programming interface
 * @export
 */
export const AdminRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した運営管理詳細を削除する
         * @summary 指定した運営管理詳細の削除
         * @param {number} id 運営管理情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobsDetail1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobsDetail1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedicalSpecialtyCategoryDetail1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedicalSpecialtyCategoryDetail1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 運営管理詳細を取得する
         * @summary 運営管理詳細を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobsDetail1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsDetail1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobsDetails1(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageJobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsDetails1(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMedicalSpecialtyCategoryDetail1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMedicalSpecialtyCategoryDetail1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMedicalSpecialtyCategoryDetails1(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMedicalSpecialtyCategoryDetails1(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 運営管理詳細を登録・更新する
         * @summary 運営管理詳細登録・更新
         * @param {AdminDetail} adminDetail 運営管理詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminDetail(adminDetail: AdminDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminDetail(adminDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJobsDetail1(jobDetail: JobDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJobsDetail1(jobDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRestControllerApi - factory interface
 * @export
 */
export const AdminRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRestControllerApiFp(configuration)
    return {
        /**
         * 指定した運営管理詳細を削除する
         * @summary 指定した運営管理詳細の削除
         * @param {number} id 運営管理情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAdminDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobsDetail1(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteJobsDetail1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalSpecialtyCategoryDetail1(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMedicalSpecialtyCategoryDetail1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 運営管理詳細を取得する
         * @summary 運営管理詳細を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminDetail(options?: any): AxiosPromise<AdminDetail> {
            return localVarFp.fetchAdminDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetail1(id: number, options?: any): AxiosPromise<JobDetail> {
            return localVarFp.fetchJobsDetail1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetails1(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageJobDetail> {
            return localVarFp.fetchJobsDetails1(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetail1(id: number, options?: any): AxiosPromise<MedicalSpecialtyCategoryDetail> {
            return localVarFp.fetchMedicalSpecialtyCategoryDetail1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetails1(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMedicalSpecialtyCategoryDetail> {
            return localVarFp.fetchMedicalSpecialtyCategoryDetails1(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 運営管理詳細を登録・更新する
         * @summary 運営管理詳細登録・更新
         * @param {AdminDetail} adminDetail 運営管理詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminDetail(adminDetail: AdminDetail, options?: any): AxiosPromise<AdminDetail> {
            return localVarFp.updateAdminDetail(adminDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobsDetail1(jobDetail: JobDetail, options?: any): AxiosPromise<JobDetail> {
            return localVarFp.updateJobsDetail1(jobDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any): AxiosPromise<MedicalSpecialtyCategoryDetail> {
            return localVarFp.updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminRestControllerApi - object-oriented interface
 * @export
 * @class AdminRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminRestControllerApi extends BaseAPI {
    /**
     * 指定した運営管理詳細を削除する
     * @summary 指定した運営管理詳細の削除
     * @param {number} id 運営管理情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public deleteAdminDetail(id: number, options?: any) {
        return AdminRestControllerApiFp(this.configuration).deleteAdminDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した職種マスタ詳細を削除する
     * @summary 指定した職種マスタ詳細の削除
     * @param {number} id 職種マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public deleteJobsDetail1(id: number, options?: any) {
        return AdminRestControllerApiFp(this.configuration).deleteJobsDetail1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した基本領域マスタ詳細を削除する
     * @summary 指定した基本領域マスタ詳細の削除
     * @param {number} id 基本領域マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public deleteMedicalSpecialtyCategoryDetail1(id: number, options?: any) {
        return AdminRestControllerApiFp(this.configuration).deleteMedicalSpecialtyCategoryDetail1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 運営管理詳細を取得する
     * @summary 運営管理詳細を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public fetchAdminDetail(options?: any) {
        return AdminRestControllerApiFp(this.configuration).fetchAdminDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ詳細を取得
     * @summary 職種マスタ詳細を取得
     * @param {number} id 職種ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public fetchJobsDetail1(id: number, options?: any) {
        return AdminRestControllerApiFp(this.configuration).fetchJobsDetail1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ一覧を取得
     * @summary 職種マスタ一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public fetchJobsDetails1(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminRestControllerApiFp(this.configuration).fetchJobsDetails1(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ詳細を取得する
     * @summary 基本領域マスタ詳細を取得
     * @param {number} id 基本領域マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public fetchMedicalSpecialtyCategoryDetail1(id: number, options?: any) {
        return AdminRestControllerApiFp(this.configuration).fetchMedicalSpecialtyCategoryDetail1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ一覧を取得する
     * @summary 基本領域マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public fetchMedicalSpecialtyCategoryDetails1(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminRestControllerApiFp(this.configuration).fetchMedicalSpecialtyCategoryDetails1(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 運営管理詳細を登録・更新する
     * @summary 運営管理詳細登録・更新
     * @param {AdminDetail} adminDetail 運営管理詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public updateAdminDetail(adminDetail: AdminDetail, options?: any) {
        return AdminRestControllerApiFp(this.configuration).updateAdminDetail(adminDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ詳細を登録・更新する
     * @summary 職種マスタ詳細登録・更新
     * @param {JobDetail} jobDetail 職種マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public updateJobsDetail1(jobDetail: JobDetail, options?: any) {
        return AdminRestControllerApiFp(this.configuration).updateJobsDetail1(jobDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ詳細を登録・更新する
     * @summary 基本領域マスタ詳細登録・更新
     * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRestControllerApi
     */
    public updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any) {
        return AdminRestControllerApiFp(this.configuration).updateMedicalSpecialtyCategoryDetail1(medicalSpecialtyCategoryDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUserRestControllerApi - axios parameter creator
 * @export
 */
export const AdminUserRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 運営管理ユーザーのメールアドレスの重複をチェックする
         * @summary 運営管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdminUserDuplicateEmail: async (checkEmailParam: CheckEmailParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkEmailParam' is not null or undefined
            assertParamExists('checkAdminUserDuplicateEmail', 'checkEmailParam', checkEmailParam)
            const localVarPath = `/api/admin/user/email/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した運営管理ユーザーを削除する
         * @summary 指定した運営管理ユーザーの削除
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdminUser', 'id', id)
            const localVarPath = `/api/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した運営管理ユーザー詳細を取得する
         * @summary 運営管理ユーザー詳細取得
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUserDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAdminUserDetail', 'id', id)
            const localVarPath = `/api/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を取得する
         * @summary 運営管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 運営管理ユーザー一覧を取得する
         * @summary 運営管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUsers: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 運営管理ユーザーのプロファイルを取得する
         * @summary 運営管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 運営管理ユーザーを登録・更新する
         * @summary 運営管理ユーザー登録・更新
         * @param {AdminUserDetail} adminUserDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser: async (adminUserDetail: AdminUserDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserDetail' is not null or undefined
            assertParamExists('updateAdminUser', 'adminUserDetail', adminUserDetail)
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を更新する
         * @summary 運営管理ユーザーのユーザー情報を更新
         * @param {AdminUserInfo} adminUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUserInfo: async (adminUserInfo: AdminUserInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminUserInfo' is not null or undefined
            assertParamExists('updateAdminUserInfo', 'adminUserInfo', adminUserInfo)
            const localVarPath = `/api/admin/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUserInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserRestControllerApi - functional programming interface
 * @export
 */
export const AdminUserRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 運営管理ユーザーのメールアドレスの重複をチェックする
         * @summary 運営管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAdminUserDuplicateEmail(checkEmailParam: CheckEmailParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAdminUserDuplicateEmail(checkEmailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した運営管理ユーザーを削除する
         * @summary 指定した運営管理ユーザーの削除
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdminUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdminUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した運営管理ユーザー詳細を取得する
         * @summary 運営管理ユーザー詳細取得
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminUserDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminUserDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を取得する
         * @summary 運営管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 運営管理ユーザー一覧を取得する
         * @summary 運営管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminUsers(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdminUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminUsers(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 運営管理ユーザーのプロファイルを取得する
         * @summary 運営管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 運営管理ユーザーを登録・更新する
         * @summary 運営管理ユーザー登録・更新
         * @param {AdminUserDetail} adminUserDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminUser(adminUserDetail: AdminUserDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminUser(adminUserDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を更新する
         * @summary 運営管理ユーザーのユーザー情報を更新
         * @param {AdminUserInfo} adminUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAdminUserInfo(adminUserInfo: AdminUserInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAdminUserInfo(adminUserInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserRestControllerApi - factory interface
 * @export
 */
export const AdminUserRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserRestControllerApiFp(configuration)
    return {
        /**
         * 運営管理ユーザーのメールアドレスの重複をチェックする
         * @summary 運営管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdminUserDuplicateEmail(checkEmailParam: CheckEmailParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkAdminUserDuplicateEmail(checkEmailParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した運営管理ユーザーを削除する
         * @summary 指定した運営管理ユーザーの削除
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminUser(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAdminUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した運営管理ユーザー詳細を取得する
         * @summary 運営管理ユーザー詳細取得
         * @param {number} id 運営管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUserDetail(id: number, options?: any): AxiosPromise<AdminUserDetail> {
            return localVarFp.fetchAdminUserDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を取得する
         * @summary 運営管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUserInfo(options?: any): AxiosPromise<AdminUserInfo> {
            return localVarFp.fetchAdminUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 運営管理ユーザー一覧を取得する
         * @summary 運営管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminUsers(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdminUserDetail> {
            return localVarFp.fetchAdminUsers(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 運営管理ユーザーのプロファイルを取得する
         * @summary 運営管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile2(options?: any): AxiosPromise<AdminUserProfile> {
            return localVarFp.getProfile2(options).then((request) => request(axios, basePath));
        },
        /**
         * 運営管理ユーザーを登録・更新する
         * @summary 運営管理ユーザー登録・更新
         * @param {AdminUserDetail} adminUserDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUser(adminUserDetail: AdminUserDetail, options?: any): AxiosPromise<AdminUserDetail> {
            return localVarFp.updateAdminUser(adminUserDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしている運営管理ユーザーのユーザー情報を更新する
         * @summary 運営管理ユーザーのユーザー情報を更新
         * @param {AdminUserInfo} adminUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdminUserInfo(adminUserInfo: AdminUserInfo, options?: any): AxiosPromise<string> {
            return localVarFp.updateAdminUserInfo(adminUserInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserRestControllerApi - object-oriented interface
 * @export
 * @class AdminUserRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminUserRestControllerApi extends BaseAPI {
    /**
     * 運営管理ユーザーのメールアドレスの重複をチェックする
     * @summary 運営管理ユーザーのメールアドレスの重複をチェックする
     * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public checkAdminUserDuplicateEmail(checkEmailParam: CheckEmailParam, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).checkAdminUserDuplicateEmail(checkEmailParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した運営管理ユーザーを削除する
     * @summary 指定した運営管理ユーザーの削除
     * @param {number} id 運営管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public deleteAdminUser(id: number, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).deleteAdminUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した運営管理ユーザー詳細を取得する
     * @summary 運営管理ユーザー詳細取得
     * @param {number} id 運営管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public fetchAdminUserDetail(id: number, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).fetchAdminUserDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしている運営管理ユーザーのユーザー情報を取得する
     * @summary 運営管理ユーザーのユーザー情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public fetchAdminUserInfo(options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).fetchAdminUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 運営管理ユーザー一覧を取得する
     * @summary 運営管理ユーザー一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public fetchAdminUsers(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).fetchAdminUsers(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 運営管理ユーザーのプロファイルを取得する
     * @summary 運営管理ユーザーのプロファイル取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public getProfile2(options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).getProfile2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 運営管理ユーザーを登録・更新する
     * @summary 運営管理ユーザー登録・更新
     * @param {AdminUserDetail} adminUserDetail 学会詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public updateAdminUser(adminUserDetail: AdminUserDetail, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).updateAdminUser(adminUserDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしている運営管理ユーザーのユーザー情報を更新する
     * @summary 運営管理ユーザーのユーザー情報を更新
     * @param {AdminUserInfo} adminUserInfo ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public updateAdminUserInfo(adminUserInfo: AdminUserInfo, options?: any) {
        return AdminUserRestControllerApiFp(this.configuration).updateAdminUserInfo(adminUserInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonConferenceRestControllerApi - axios parameter creator
 * @export
 */
export const CommonConferenceRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * サービス会員の会員番号の重複をチェックする
         * @summary サービス会員の会員番号の重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckConferenceNumberParam} checkConferenceNumberParam 会員番号パラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDoctorDuplicateConferenceNumber: async (id: number, checkConferenceNumberParam: CheckConferenceNumberParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConferenceDoctorDuplicateConferenceNumber', 'id', id)
            // verify required parameter 'checkConferenceNumberParam' is not null or undefined
            assertParamExists('checkConferenceDoctorDuplicateConferenceNumber', 'checkConferenceNumberParam', checkConferenceNumberParam)
            const localVarPath = `/api/common/conferences/{id}/members/conferenceNumber/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkConferenceNumberParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サービス会員のメールアドレスの重複をチェックする
         * @summary サービス会員のメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDoctorDuplicateEmail: async (id: number, checkEmailParam: CheckEmailParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConferenceDoctorDuplicateEmail', 'id', id)
            // verify required parameter 'checkEmailParam' is not null or undefined
            assertParamExists('checkConferenceDoctorDuplicateEmail', 'checkEmailParam', checkEmailParam)
            const localVarPath = `/api/common/conferences/{id}/members/email/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmail: async (id: number, conferenceUsersId: number, checkEmailParam: CheckEmailParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmail', 'id', id)
            // verify required parameter 'conferenceUsersId' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmail', 'conferenceUsersId', conferenceUsersId)
            // verify required parameter 'checkEmailParam' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmail', 'checkEmailParam', checkEmailParam)
            const localVarPath = `/api/common/conferences/{id}/users/{conferenceUsersId}/email/duplicate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"conferenceUsersId"}}`, encodeURIComponent(String(conferenceUsersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @summary 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBasicMedicalAreas: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchBasicMedicalAreas', 'id', id)
            const localVarPath = `/api/common/conferences/{id}/basicMedicalArea`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceOfficerDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceOfficerDetails', 'id', id)
            const localVarPath = `/api/common/conferences/{id}/officers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferencePositionDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferencePositionDetails', 'id', id)
            const localVarPath = `/api/common/conferences/{id}/positions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他支払いの一覧(セレクトコンポーネント用)を取得
         * @summary その他支払いの一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentSelectValue: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEtcPaymentSelectValue', 'id', id)
            const localVarPath = `/api/common/conferences/{id}/payments/etcPaymentList`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonConferenceRestControllerApi - functional programming interface
 * @export
 */
export const CommonConferenceRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonConferenceRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * サービス会員の会員番号の重複をチェックする
         * @summary サービス会員の会員番号の重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckConferenceNumberParam} checkConferenceNumberParam 会員番号パラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceDoctorDuplicateConferenceNumber(id: number, checkConferenceNumberParam: CheckConferenceNumberParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceDoctorDuplicateConferenceNumber(id, checkConferenceNumberParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サービス会員のメールアドレスの重複をチェックする
         * @summary サービス会員のメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceDoctorDuplicateEmail(id: number, checkEmailParam: CheckEmailParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceDoctorDuplicateEmail(id, checkEmailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceUserDuplicateEmail(id: number, conferenceUsersId: number, checkEmailParam: CheckEmailParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceUserDuplicateEmail(id, conferenceUsersId, checkEmailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @summary 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchBasicMedicalAreas(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectValueInt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchBasicMedicalAreas(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceOfficerDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficerDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceOfficerDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferencePositionDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PositionDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferencePositionDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他支払いの一覧(セレクトコンポーネント用)を取得
         * @summary その他支払いの一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcPaymentSelectValue(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectValueInt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcPaymentSelectValue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonConferenceRestControllerApi - factory interface
 * @export
 */
export const CommonConferenceRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonConferenceRestControllerApiFp(configuration)
    return {
        /**
         * サービス会員の会員番号の重複をチェックする
         * @summary サービス会員の会員番号の重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckConferenceNumberParam} checkConferenceNumberParam 会員番号パラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDoctorDuplicateConferenceNumber(id: number, checkConferenceNumberParam: CheckConferenceNumberParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceDoctorDuplicateConferenceNumber(id, checkConferenceNumberParam, options).then((request) => request(axios, basePath));
        },
        /**
         * サービス会員のメールアドレスの重複をチェックする
         * @summary サービス会員のメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceDoctorDuplicateEmail(id: number, checkEmailParam: CheckEmailParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceDoctorDuplicateEmail(id, checkEmailParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {number} id 学会ID
         * @param {number} conferenceUsersId 学会管理ユーザーID
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmail(id: number, conferenceUsersId: number, checkEmailParam: CheckEmailParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceUserDuplicateEmail(id, conferenceUsersId, checkEmailParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @summary 基本診療科の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBasicMedicalAreas(id: number, options?: any): AxiosPromise<Array<SelectValueInt>> {
            return localVarFp.fetchBasicMedicalAreas(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceOfficerDetails(id: number, options?: any): AxiosPromise<Array<OfficerDetail>> {
            return localVarFp.fetchConferenceOfficerDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferencePositionDetails(id: number, options?: any): AxiosPromise<Array<PositionDetail>> {
            return localVarFp.fetchConferencePositionDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * その他支払いの一覧(セレクトコンポーネント用)を取得
         * @summary その他支払いの一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentSelectValue(id: number, options?: any): AxiosPromise<Array<SelectValueInt>> {
            return localVarFp.fetchEtcPaymentSelectValue(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonConferenceRestControllerApi - object-oriented interface
 * @export
 * @class CommonConferenceRestControllerApi
 * @extends {BaseAPI}
 */
export class CommonConferenceRestControllerApi extends BaseAPI {
    /**
     * サービス会員の会員番号の重複をチェックする
     * @summary サービス会員の会員番号の重複をチェックする
     * @param {number} id 学会ID
     * @param {CheckConferenceNumberParam} checkConferenceNumberParam 会員番号パラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public checkConferenceDoctorDuplicateConferenceNumber(id: number, checkConferenceNumberParam: CheckConferenceNumberParam, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).checkConferenceDoctorDuplicateConferenceNumber(id, checkConferenceNumberParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サービス会員のメールアドレスの重複をチェックする
     * @summary サービス会員のメールアドレスの重複をチェックする
     * @param {number} id 学会ID
     * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public checkConferenceDoctorDuplicateEmail(id: number, checkEmailParam: CheckEmailParam, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).checkConferenceDoctorDuplicateEmail(id, checkEmailParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザーのメールアドレスの重複をチェックする
     * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
     * @param {number} id 学会ID
     * @param {number} conferenceUsersId 学会管理ユーザーID
     * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public checkConferenceUserDuplicateEmail(id: number, conferenceUsersId: number, checkEmailParam: CheckEmailParam, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).checkConferenceUserDuplicateEmail(id, conferenceUsersId, checkEmailParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本診療科の一覧(セレクトコンポーネント用)を取得
     * @summary 基本診療科の一覧(セレクトコンポーネント用)を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public fetchBasicMedicalAreas(id: number, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).fetchBasicMedicalAreas(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public fetchConferenceOfficerDetails(id: number, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).fetchConferenceOfficerDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public fetchConferencePositionDetails(id: number, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).fetchConferencePositionDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他支払いの一覧(セレクトコンポーネント用)を取得
     * @summary その他支払いの一覧(セレクトコンポーネント用)を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonConferenceRestControllerApi
     */
    public fetchEtcPaymentSelectValue(id: number, options?: any) {
        return CommonConferenceRestControllerApiFp(this.configuration).fetchEtcPaymentSelectValue(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonRestControllerApi - axios parameter creator
 * @export
 */
export const CommonRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 基本領域の一覧(セレクトコンポーネント用)を取得
         * @summary 基本領域の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBasicMedicalSpecialties: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchBasicMedicalSpecialties', 'id', id)
            const localVarPath = `/api/common/members/{id}/basicMedicalSpecialties`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種の一覧(セレクトコンポーネント用)を取得
         * @summary 職種の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobs: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchJobs', 'id', id)
            const localVarPath = `/api/common/members/{id}/jobs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonRestControllerApi - functional programming interface
 * @export
 */
export const CommonRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 基本領域の一覧(セレクトコンポーネント用)を取得
         * @summary 基本領域の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchBasicMedicalSpecialties(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectValueInt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchBasicMedicalSpecialties(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種の一覧(セレクトコンポーネント用)を取得
         * @summary 職種の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobs(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectValueInt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobs(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonRestControllerApi - factory interface
 * @export
 */
export const CommonRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonRestControllerApiFp(configuration)
    return {
        /**
         * 基本領域の一覧(セレクトコンポーネント用)を取得
         * @summary 基本領域の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBasicMedicalSpecialties(id: number, options?: any): AxiosPromise<Array<SelectValueInt>> {
            return localVarFp.fetchBasicMedicalSpecialties(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種の一覧(セレクトコンポーネント用)を取得
         * @summary 職種の一覧(セレクトコンポーネント用)を取得
         * @param {number} id 学会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobs(id: number, options?: any): AxiosPromise<Array<SelectValueInt>> {
            return localVarFp.fetchJobs(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonRestControllerApi - object-oriented interface
 * @export
 * @class CommonRestControllerApi
 * @extends {BaseAPI}
 */
export class CommonRestControllerApi extends BaseAPI {
    /**
     * 基本領域の一覧(セレクトコンポーネント用)を取得
     * @summary 基本領域の一覧(セレクトコンポーネント用)を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonRestControllerApi
     */
    public fetchBasicMedicalSpecialties(id: number, options?: any) {
        return CommonRestControllerApiFp(this.configuration).fetchBasicMedicalSpecialties(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種の一覧(セレクトコンポーネント用)を取得
     * @summary 職種の一覧(セレクトコンポーネント用)を取得
     * @param {number} id 学会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonRestControllerApi
     */
    public fetchJobs(id: number, options?: any) {
        return CommonRestControllerApiFp(this.configuration).fetchJobs(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceAdminInfoRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceAdminInfoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoOverviews1: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/adminInfos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceAdminInfoRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceAdminInfoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceAdminInfoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdminInfoOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoOverviews1(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceAdminInfoRestControllerApi - factory interface
 * @export
 */
export const ConferenceAdminInfoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceAdminInfoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdminInfoOverview> {
            return localVarFp.fetchAdminInfoOverviews1(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceAdminInfoRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceAdminInfoRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceAdminInfoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceAdminInfoRestControllerApi
     */
    public fetchAdminInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceAdminInfoRestControllerApiFp(this.configuration).fetchAdminInfoOverviews1(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDetailRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDetailRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインしているサービス会員が所属する学会情報を取得
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConference: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/conference/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員が所属する学会の決済に必要な情報を取得
         * @summary 決済に必要な情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSettlementInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/conference/settlementInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDetailRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDetailRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDetailRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインしているサービス会員が所属する学会情報を取得
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConference(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConference(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員が所属する学会の決済に必要な情報を取得
         * @summary 決済に必要な情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceSettlementInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceSettlementInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceSettlementInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDetailRestControllerApi - factory interface
 * @export
 */
export const ConferenceDetailRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDetailRestControllerApiFp(configuration)
    return {
        /**
         * ログインしているサービス会員が所属する学会情報を取得
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConference(options?: any): AxiosPromise<ConferenceDetail> {
            return localVarFp.fetchConference(options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員が所属する学会の決済に必要な情報を取得
         * @summary 決済に必要な情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSettlementInfo(options?: any): AxiosPromise<ConferenceSettlementInfo> {
            return localVarFp.fetchConferenceSettlementInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDetailRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDetailRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDetailRestControllerApi extends BaseAPI {
    /**
     * ログインしているサービス会員が所属する学会情報を取得
     * @summary 学会情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDetailRestControllerApi
     */
    public fetchConference(options?: any) {
        return ConferenceDetailRestControllerApiFp(this.configuration).fetchConference(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員が所属する学会の決済に必要な情報を取得
     * @summary 決済に必要な情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDetailRestControllerApi
     */
    public fetchConferenceSettlementInfo(options?: any) {
        return ConferenceDetailRestControllerApiFp(this.configuration).fetchConferenceSettlementInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorEntryMeetingRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorEntryMeetingRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナーの表示可能な共有URL数取得
         * @summary 指定したIDの学術集会・セミナーの表示可能な共有URL数取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMemberDisplayableSharedUrl: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countMemberDisplayableSharedUrl', 'id', id)
            const localVarPath = `/api/member/meetings/{id}/count`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーの証明書を発行する
         * @summary 学術集会･セミナーの証明書を発行
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCertificateOfCompletion: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadCertificateOfCompletion', 'id', id)
            const localVarPath = `/api/member/meetings/{id}/certificateOfCompletion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員の学術集会・セミナー一覧取得
         * @summary 学術集会・セミナー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナー情報取得
         * @summary 指定したIDの学術集会・セミナー情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAttendMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberAttendMeetingDetail', 'id', id)
            const localVarPath = `/api/member/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberMeetingFiles', 'id', id)
            const localVarPath = `/api/member/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAbsenceMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberAbsenceMeeting', 'id', id)
            const localVarPath = `/api/member/meetings/{id}/entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEntryMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberEntryMeeting', 'id', id)
            const localVarPath = `/api/member/meetings/{id}/entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EntryMeetingForm} entryMeetingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEntryMeeting1: async (id: number, entryMeetingForm: EntryMeetingForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('memberEntryMeeting1', 'id', id)
            // verify required parameter 'entryMeetingForm' is not null or undefined
            assertParamExists('memberEntryMeeting1', 'entryMeetingForm', entryMeetingForm)
            const localVarPath = `/api/member/meetings/{id}/entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryMeetingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorEntryMeetingRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorEntryMeetingRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorEntryMeetingRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナーの表示可能な共有URL数取得
         * @summary 指定したIDの学術集会・セミナーの表示可能な共有URL数取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countMemberDisplayableSharedUrl(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countMemberDisplayableSharedUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーの証明書を発行する
         * @summary 学術集会･セミナーの証明書を発行
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadCertificateOfCompletion(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCertificateOfCompletion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員の学術集会・セミナー一覧取得
         * @summary 学術集会・セミナー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryMeetingOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEntryMeetingOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryMeetingOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナー情報取得
         * @summary 指定したIDの学術集会・セミナー情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberAttendMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberAttendMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAbsenceMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAbsenceMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberEntryMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberEntryMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EntryMeetingForm} entryMeetingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberEntryMeeting1(id: number, entryMeetingForm: EntryMeetingForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryMeetings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberEntryMeeting1(id, entryMeetingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorEntryMeetingRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorEntryMeetingRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorEntryMeetingRestControllerApiFp(configuration)
    return {
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナーの表示可能な共有URL数取得
         * @summary 指定したIDの学術集会・セミナーの表示可能な共有URL数取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countMemberDisplayableSharedUrl(id: number, options?: any): AxiosPromise<number> {
            return localVarFp.countMemberDisplayableSharedUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーの証明書を発行する
         * @summary 学術集会･セミナーの証明書を発行
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCertificateOfCompletion(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.downloadCertificateOfCompletion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員の学術集会・セミナー一覧取得
         * @summary 学術集会・セミナー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEntryMeetingOverview> {
            return localVarFp.fetchEntryMeetingOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナー情報取得
         * @summary 指定したIDの学術集会・セミナー情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAttendMeetingDetail(id: number, options?: any): AxiosPromise<MeetingDetail> {
            return localVarFp.fetchMemberAttendMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchMemberMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAbsenceMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.memberAbsenceMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEntryMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.memberEntryMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EntryMeetingForm} entryMeetingForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberEntryMeeting1(id: number, entryMeetingForm: EntryMeetingForm, options?: any): AxiosPromise<EntryMeetings> {
            return localVarFp.memberEntryMeeting1(id, entryMeetingForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorEntryMeetingRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorEntryMeetingRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorEntryMeetingRestControllerApi extends BaseAPI {
    /**
     * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナーの表示可能な共有URL数取得
     * @summary 指定したIDの学術集会・セミナーの表示可能な共有URL数取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public countMemberDisplayableSharedUrl(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).countMemberDisplayableSharedUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーの証明書を発行する
     * @summary 学術集会･セミナーの証明書を発行
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public downloadCertificateOfCompletion(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).downloadCertificateOfCompletion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員の学術集会・セミナー一覧取得
     * @summary 学術集会・セミナー一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public fetchEntryMeetingOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).fetchEntryMeetingOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員の指定した学術集会・セミナーIDの学術集会・セミナー情報取得
     * @summary 指定したIDの学術集会・セミナー情報取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public fetchMemberAttendMeetingDetail(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).fetchMemberAttendMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーのファイルURL一覧を取得する
     * @summary 学術集会･セミナーのファイルURL一覧を取得
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public fetchMemberMeetingFiles(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).fetchMemberMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public memberAbsenceMeeting(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).memberAbsenceMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public memberEntryMeeting(id: number, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).memberEntryMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EntryMeetingForm} entryMeetingForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorEntryMeetingRestControllerApi
     */
    public memberEntryMeeting1(id: number, entryMeetingForm: EntryMeetingForm, options?: any) {
        return ConferenceDoctorEntryMeetingRestControllerApiFp(this.configuration).memberEntryMeeting1(id, entryMeetingForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorMemberRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorMemberRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/user/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceDoctorDetail: async (conferenceDoctorDetail: ConferenceDoctorDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceDoctorDetail' is not null or undefined
            assertParamExists('updateConferenceDoctorDetail', 'conferenceDoctorDetail', conferenceDoctorDetail)
            const localVarPath = `/api/member/user/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceDoctorDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserForm} userForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (userForm: UserForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userForm' is not null or undefined
            assertParamExists('updatePassword', 'userForm', userForm)
            const localVarPath = `/api/member/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorMemberRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorMemberRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorMemberRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceDoctorDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDoctorDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceDoctorDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceDoctorDetail(conferenceDoctorDetail: ConferenceDoctorDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDoctors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceDoctorDetail(conferenceDoctorDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserForm} userForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(userForm: UserForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(userForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorMemberRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorMemberRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorMemberRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorDetail(options?: any): AxiosPromise<ConferenceDoctorDetail> {
            return localVarFp.fetchConferenceDoctorDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceDoctorDetail(conferenceDoctorDetail: ConferenceDoctorDetail, options?: any): AxiosPromise<ConferenceDoctors> {
            return localVarFp.updateConferenceDoctorDetail(conferenceDoctorDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserForm} userForm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(userForm: UserForm, options?: any): AxiosPromise<string> {
            return localVarFp.updatePassword(userForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorMemberRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorMemberRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorMemberRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorMemberRestControllerApi
     */
    public fetchConferenceDoctorDetail(options?: any) {
        return ConferenceDoctorMemberRestControllerApiFp(this.configuration).fetchConferenceDoctorDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConferenceDoctorDetail} conferenceDoctorDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorMemberRestControllerApi
     */
    public updateConferenceDoctorDetail(conferenceDoctorDetail: ConferenceDoctorDetail, options?: any) {
        return ConferenceDoctorMemberRestControllerApiFp(this.configuration).updateConferenceDoctorDetail(conferenceDoctorDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserForm} userForm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorMemberRestControllerApi
     */
    public updatePassword(userForm: UserForm, options?: any) {
        return ConferenceDoctorMemberRestControllerApiFp(this.configuration).updatePassword(userForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorOfficerRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorOfficerRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したIDの理事・役員会開催の出欠情報を取得する
         * @summary 指定したIDの理事・役員会開催の出欠情報を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberEntryOfficerMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberEntryOfficerMeetingDetail', 'id', id)
            const localVarPath = `/api/member/officers/meetings/{id}/attendees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberOfficerMaster', 'id', id)
            const localVarPath = `/api/member/officers/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMasterMeetings: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberOfficerMasterMeetings', 'id', id)
            const localVarPath = `/api/member/officers/masters/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberOfficerMeetingDetail', 'id', id)
            const localVarPath = `/api/member/officers/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberOfficerMeetingFiles', 'id', id)
            const localVarPath = `/api/member/officers/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerQualificationOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/officers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorOfficerRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorOfficerRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorOfficerRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したIDの理事・役員会開催の出欠情報を取得する
         * @summary 指定したIDの理事・役員会開催の出欠情報を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberEntryOfficerMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryOfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberEntryOfficerMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberOfficerMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberOfficerMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberOfficerMasterMeetings(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberOfficerMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberOfficerMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberOfficerMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberOfficerMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberOfficerQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberOfficerQualificationOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorOfficerRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorOfficerRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorOfficerRestControllerApiFp(configuration)
    return {
        /**
         * 指定したIDの理事・役員会開催の出欠情報を取得する
         * @summary 指定したIDの理事・役員会開催の出欠情報を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberEntryOfficerMeetingDetail(id: number, options?: any): AxiosPromise<EntryOfficerMeetingDetail> {
            return localVarFp.fetchMemberEntryOfficerMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMaster(id: number, options?: any): AxiosPromise<OfficerDetail> {
            return localVarFp.fetchMemberOfficerMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerMeetingDetail> {
            return localVarFp.fetchMemberOfficerMasterMeetings(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMeetingDetail(id: number, options?: any): AxiosPromise<OfficerMeetingDetail> {
            return localVarFp.fetchMemberOfficerMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchMemberOfficerMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberOfficerQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerQualificationOverview> {
            return localVarFp.fetchMemberOfficerQualificationOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorOfficerRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorOfficerRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorOfficerRestControllerApi extends BaseAPI {
    /**
     * 指定したIDの理事・役員会開催の出欠情報を取得する
     * @summary 指定したIDの理事・役員会開催の出欠情報を取得
     * @param {number} id 理事・役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberEntryOfficerMeetingDetail(id: number, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberEntryOfficerMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会マスタを取得する
     * @summary 指定した学会の理事･役員会マスタ取得
     * @param {number} id 理事･役員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberOfficerMaster(id: number, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberOfficerMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
     * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
     * @param {number} id 理事･役員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberOfficerMasterMeetings(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会開催詳細を取得する
     * @summary 指定した理事･役員会開催詳細を取得
     * @param {number} id 理事･役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberOfficerMeetingDetail(id: number, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberOfficerMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 理事･役員会会議のファイルURL一覧を取得する
     * @summary 理事･役員会会議のファイルURL一覧を取得
     * @param {number} id 理事･役員会会議ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberOfficerMeetingFiles(id: number, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberOfficerMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorOfficerRestControllerApi
     */
    public fetchMemberOfficerQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorOfficerRestControllerApiFp(this.configuration).fetchMemberOfficerQualificationOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorPositionRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorPositionRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したIDの委員会開催の出欠情報を取得する
         * @summary 指定したIDの委員会開催の出欠情報を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberEntryPositionMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberEntryPositionMeetingDetail', 'id', id)
            const localVarPath = `/api/member/positions/meetings/{id}/attendees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したIDの委員会マスタを取得する
         * @summary 指定したIDの委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberPositionMaster', 'id', id)
            const localVarPath = `/api/member/positions/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したIDの委員会開催一覧を取得する
         * @summary 指定したIDの委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMasterMeetings: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberPositionMasterMeetings', 'id', id)
            const localVarPath = `/api/member/positions/masters/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したIDの委員会開催詳細を取得する
         * @summary 指定したIDの委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberPositionMeetingDetail', 'id', id)
            const localVarPath = `/api/member/positions/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したIDの委員会開催のファイルURL一覧を取得する
         * @summary 指定したIDの委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberPositionMeetingFiles', 'id', id)
            const localVarPath = `/api/member/positions/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionQualificationOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/positions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorPositionRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorPositionRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorPositionRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したIDの委員会開催の出欠情報を取得する
         * @summary 指定したIDの委員会開催の出欠情報を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberEntryPositionMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryPositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberEntryPositionMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したIDの委員会マスタを取得する
         * @summary 指定したIDの委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberPositionMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberPositionMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したIDの委員会開催一覧を取得する
         * @summary 指定したIDの委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberPositionMasterMeetings(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したIDの委員会開催詳細を取得する
         * @summary 指定したIDの委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberPositionMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberPositionMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したIDの委員会開催のファイルURL一覧を取得する
         * @summary 指定したIDの委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberPositionMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberPositionMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberPositionQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberPositionQualificationOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorPositionRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorPositionRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorPositionRestControllerApiFp(configuration)
    return {
        /**
         * 指定したIDの委員会開催の出欠情報を取得する
         * @summary 指定したIDの委員会開催の出欠情報を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberEntryPositionMeetingDetail(id: number, options?: any): AxiosPromise<EntryPositionMeetingDetail> {
            return localVarFp.fetchMemberEntryPositionMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したIDの委員会マスタを取得する
         * @summary 指定したIDの委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMaster(id: number, options?: any): AxiosPromise<PositionDetail> {
            return localVarFp.fetchMemberPositionMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したIDの委員会開催一覧を取得する
         * @summary 指定したIDの委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionMeetingDetail> {
            return localVarFp.fetchMemberPositionMasterMeetings(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したIDの委員会開催詳細を取得する
         * @summary 指定したIDの委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMeetingDetail(id: number, options?: any): AxiosPromise<PositionMeetingDetail> {
            return localVarFp.fetchMemberPositionMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したIDの委員会開催のファイルURL一覧を取得する
         * @summary 指定したIDの委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchMemberPositionMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberPositionQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionQualificationOverview> {
            return localVarFp.fetchMemberPositionQualificationOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorPositionRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorPositionRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorPositionRestControllerApi extends BaseAPI {
    /**
     * 指定したIDの委員会開催の出欠情報を取得する
     * @summary 指定したIDの委員会開催の出欠情報を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberEntryPositionMeetingDetail(id: number, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberEntryPositionMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したIDの委員会マスタを取得する
     * @summary 指定したIDの委員会マスタ取得
     * @param {number} id 委員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberPositionMaster(id: number, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberPositionMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したIDの委員会開催一覧を取得する
     * @summary 指定したIDの委員会開催一覧取得
     * @param {number} id 委員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberPositionMasterMeetings(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したIDの委員会開催詳細を取得する
     * @summary 指定したIDの委員会開催詳細を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberPositionMeetingDetail(id: number, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberPositionMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したIDの委員会開催のファイルURL一覧を取得する
     * @summary 指定したIDの委員会開催のファイルURL一覧を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberPositionMeetingFiles(id: number, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberPositionMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorPositionRestControllerApi
     */
    public fetchMemberPositionQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorPositionRestControllerApiFp(this.configuration).fetchMemberPositionQualificationOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorQualificationRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorQualificationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAcquisitionQualificationInfo: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberAcquisitionQualificationInfo', 'id', id)
            const localVarPath = `/api/member/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/qualifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorQualificationRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorQualificationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorQualificationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberAcquisitionQualificationInfo(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcquisitionQualificationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberAcquisitionQualificationInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorQualificationRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorQualificationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorQualificationRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAcquisitionQualificationInfo(id: number, options?: any): AxiosPromise<AcquisitionQualificationInfo> {
            return localVarFp.fetchMemberAcquisitionQualificationInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQualificationOverview> {
            return localVarFp.fetchQualificationOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorQualificationRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorQualificationRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorQualificationRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorQualificationRestControllerApi
     */
    public fetchMemberAcquisitionQualificationInfo(id: number, options?: any) {
        return ConferenceDoctorQualificationRestControllerApiFp(this.configuration).fetchMemberAcquisitionQualificationInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorQualificationRestControllerApi
     */
    public fetchQualificationOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorQualificationRestControllerApiFp(this.configuration).fetchQualificationOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * サービス会員を追加する
         * @summary サービス会員追加
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConferenceDoctor: async (conferenceDoctorDetail: ConferenceDoctorDetail, sendInviteMail?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceDoctorDetail' is not null or undefined
            assertParamExists('addConferenceDoctor', 'conferenceDoctorDetail', conferenceDoctorDetail)
            const localVarPath = `/api/conference/members/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sendInviteMail !== undefined) {
                localVarQueryParameter['sendInviteMail'] = sendInviteMail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceDoctorDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したサービス会員を削除する
         * @summary 指定したサービス会員の削除
         * @param {number} id サービス会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceDoctorDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceDoctorDetail', 'id', id)
            const localVarPath = `/api/conference/members/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索結果のサービス会員をCSVにエクスポートする
         * @summary サービス会員をCSVにエクスポート
         * @param {string} organization 組織種別
         * @param {Array<number>} [ids] サービス会員IDリスト
         * @param {string} [userName] 会員名
         * @param {string} [numberString] 会員番号
         * @param {number} [jobId] 職種ID
         * @param {string} [memberStatus] 会員状態
         * @param {number} [memberTypesId] 会員･会費種別ID
         * @param {string} [paymentStatus] 年会費入金状況
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {string} [workOfficeName] 勤務先名
         * @param {string} [qualificationName] 資格・認定(状態:保有中)
         * @param {string} [sex] 性別
         * @param {string} [birthdate] 生年月日
         * @param {string} [destination] 送付先
         * @param {string} [entryDateFrom] 入会年月日FROM
         * @param {string} [entryDateTo] 入会年月日TO
         * @param {string} [committeeName] 委員会名(在任中)
         * @param {string} [executiveName] 理事･役員会名(在任中)
         * @param {number} [basicMedicalAreaId] 基本診療科
         * @param {string} [email] メールアドレス
         * @param {boolean} [invited] 招待済みか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMemberCsv: async (organization: string, ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('exportMemberCsv', 'organization', organization)
            const localVarPath = `/api/conference/members/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (memberTypesId !== undefined) {
                localVarQueryParameter['memberTypesId'] = memberTypesId;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (workPrefecture !== undefined) {
                localVarQueryParameter['workPrefecture'] = workPrefecture;
            }

            if (workOfficeName !== undefined) {
                localVarQueryParameter['workOfficeName'] = workOfficeName;
            }

            if (qualificationName !== undefined) {
                localVarQueryParameter['qualificationName'] = qualificationName;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (birthdate !== undefined) {
                localVarQueryParameter['birthdate'] = (birthdate as any instanceof Date) ?
                    (birthdate as any).toISOString().substr(0,10) :
                    birthdate;
            }

            if (destination !== undefined) {
                localVarQueryParameter['destination'] = destination;
            }

            if (entryDateFrom !== undefined) {
                localVarQueryParameter['entryDateFrom'] = (entryDateFrom as any instanceof Date) ?
                    (entryDateFrom as any).toISOString().substr(0,10) :
                    entryDateFrom;
            }

            if (entryDateTo !== undefined) {
                localVarQueryParameter['entryDateTo'] = (entryDateTo as any instanceof Date) ?
                    (entryDateTo as any).toISOString().substr(0,10) :
                    entryDateTo;
            }

            if (committeeName !== undefined) {
                localVarQueryParameter['committeeName'] = committeeName;
            }

            if (executiveName !== undefined) {
                localVarQueryParameter['executiveName'] = executiveName;
            }

            if (basicMedicalAreaId !== undefined) {
                localVarQueryParameter['basicMedicalAreaId'] = basicMedicalAreaId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (invited !== undefined) {
                localVarQueryParameter['invited'] = invited;
            }

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorDetail1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceDoctorDetail1', 'id', id)
            const localVarPath = `/api/conference/members/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [ids] 
         * @param {string} [userName] 
         * @param {string} [numberString] 
         * @param {number} [jobId] 
         * @param {string} [memberStatus] 
         * @param {number} [memberTypesId] 
         * @param {string} [paymentStatus] 
         * @param {string} [workPrefecture] 
         * @param {string} [workOfficeName] 
         * @param {string} [qualificationName] 
         * @param {string} [sex] 
         * @param {string} [birthdate] 
         * @param {string} [destination] 
         * @param {string} [entryDateFrom] 
         * @param {string} [entryDateTo] 
         * @param {string} [committeeName] 
         * @param {string} [executiveName] 
         * @param {number} [basicMedicalAreaId] 
         * @param {string} [email] 
         * @param {boolean} [invited] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorOverviews: async (ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/members/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (memberTypesId !== undefined) {
                localVarQueryParameter['memberTypesId'] = memberTypesId;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (workPrefecture !== undefined) {
                localVarQueryParameter['workPrefecture'] = workPrefecture;
            }

            if (workOfficeName !== undefined) {
                localVarQueryParameter['workOfficeName'] = workOfficeName;
            }

            if (qualificationName !== undefined) {
                localVarQueryParameter['qualificationName'] = qualificationName;
            }

            if (sex !== undefined) {
                localVarQueryParameter['sex'] = sex;
            }

            if (birthdate !== undefined) {
                localVarQueryParameter['birthdate'] = (birthdate as any instanceof Date) ?
                    (birthdate as any).toISOString().substr(0,10) :
                    birthdate;
            }

            if (destination !== undefined) {
                localVarQueryParameter['destination'] = destination;
            }

            if (entryDateFrom !== undefined) {
                localVarQueryParameter['entryDateFrom'] = (entryDateFrom as any instanceof Date) ?
                    (entryDateFrom as any).toISOString().substr(0,10) :
                    entryDateFrom;
            }

            if (entryDateTo !== undefined) {
                localVarQueryParameter['entryDateTo'] = (entryDateTo as any instanceof Date) ?
                    (entryDateTo as any).toISOString().substr(0,10) :
                    entryDateTo;
            }

            if (committeeName !== undefined) {
                localVarQueryParameter['committeeName'] = committeeName;
            }

            if (executiveName !== undefined) {
                localVarQueryParameter['executiveName'] = executiveName;
            }

            if (basicMedicalAreaId !== undefined) {
                localVarQueryParameter['basicMedicalAreaId'] = basicMedicalAreaId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (invited !== undefined) {
                localVarQueryParameter['invited'] = invited;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 未招待のサービス会員数を取得する
         * @summary 未招待のサービス会員数を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNotInvitedMemberCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/members/notInvite/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サービス会員をMAのCSVからインポートする
         * @summary サービス会員をMAのCSVからインポート
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMaMemberCsv: async (sendInviteMail?: boolean, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/members/import/ma`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (sendInviteMail !== undefined) {
                localVarQueryParameter['sendInviteMail'] = sendInviteMail;
            }


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サービス会員をCSVからインポートする
         * @summary サービス会員をCSVからインポート
         * @param {string} organization 組織種別
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMemberCsv: async (organization: string, sendInviteMail?: boolean, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization' is not null or undefined
            assertParamExists('importMemberCsv', 'organization', organization)
            const localVarPath = `/api/conference/members/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            if (sendInviteMail !== undefined) {
                localVarQueryParameter['sendInviteMail'] = sendInviteMail;
            }


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サービス会員に招待メールを送信する
         * @summary サービス会員に招待メールを送信する
         * @param {InviteMember} inviteMember 招待情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteMailForMember: async (inviteMember: InviteMember, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteMember' is not null or undefined
            assertParamExists('sendInviteMailForMember', 'inviteMember', inviteMember)
            const localVarPath = `/api/conference/members/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サービス会員の詳細情報を更新する
         * @summary サービス会員の詳細情報を更新する
         * @param {number} id サービス会員ID
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceDoctorDetailByConference: async (id: number, conferenceDoctorDetail: ConferenceDoctorDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConferenceDoctorDetailByConference', 'id', id)
            // verify required parameter 'conferenceDoctorDetail' is not null or undefined
            assertParamExists('updateConferenceDoctorDetailByConference', 'conferenceDoctorDetail', conferenceDoctorDetail)
            const localVarPath = `/api/conference/members/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceDoctorDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * サービス会員を追加する
         * @summary サービス会員追加
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConferenceDoctor(conferenceDoctorDetail: ConferenceDoctorDetail, sendInviteMail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConferenceDoctor(conferenceDoctorDetail, sendInviteMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したサービス会員を削除する
         * @summary 指定したサービス会員の削除
         * @param {number} id サービス会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceDoctorDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceDoctorDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索結果のサービス会員をCSVにエクスポートする
         * @summary サービス会員をCSVにエクスポート
         * @param {string} organization 組織種別
         * @param {Array<number>} [ids] サービス会員IDリスト
         * @param {string} [userName] 会員名
         * @param {string} [numberString] 会員番号
         * @param {number} [jobId] 職種ID
         * @param {string} [memberStatus] 会員状態
         * @param {number} [memberTypesId] 会員･会費種別ID
         * @param {string} [paymentStatus] 年会費入金状況
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {string} [workOfficeName] 勤務先名
         * @param {string} [qualificationName] 資格・認定(状態:保有中)
         * @param {string} [sex] 性別
         * @param {string} [birthdate] 生年月日
         * @param {string} [destination] 送付先
         * @param {string} [entryDateFrom] 入会年月日FROM
         * @param {string} [entryDateTo] 入会年月日TO
         * @param {string} [committeeName] 委員会名(在任中)
         * @param {string} [executiveName] 理事･役員会名(在任中)
         * @param {number} [basicMedicalAreaId] 基本診療科
         * @param {string} [email] メールアドレス
         * @param {boolean} [invited] 招待済みか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportMemberCsv(organization: string, ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportMemberCsv(organization, ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceDoctorDetail1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDoctorDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceDoctorDetail1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [ids] 
         * @param {string} [userName] 
         * @param {string} [numberString] 
         * @param {number} [jobId] 
         * @param {string} [memberStatus] 
         * @param {number} [memberTypesId] 
         * @param {string} [paymentStatus] 
         * @param {string} [workPrefecture] 
         * @param {string} [workOfficeName] 
         * @param {string} [qualificationName] 
         * @param {string} [sex] 
         * @param {string} [birthdate] 
         * @param {string} [destination] 
         * @param {string} [entryDateFrom] 
         * @param {string} [entryDateTo] 
         * @param {string} [committeeName] 
         * @param {string} [executiveName] 
         * @param {number} [basicMedicalAreaId] 
         * @param {string} [email] 
         * @param {boolean} [invited] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceDoctorOverviews(ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceDoctorDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceDoctorOverviews(ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 未招待のサービス会員数を取得する
         * @summary 未招待のサービス会員数を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchNotInvitedMemberCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchNotInvitedMemberCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サービス会員をMAのCSVからインポートする
         * @summary サービス会員をMAのCSVからインポート
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importMaMemberCsv(sendInviteMail?: boolean, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importMaMemberCsv(sendInviteMail, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サービス会員をCSVからインポートする
         * @summary サービス会員をCSVからインポート
         * @param {string} organization 組織種別
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importMemberCsv(organization: string, sendInviteMail?: boolean, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importMemberCsv(organization, sendInviteMail, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サービス会員に招待メールを送信する
         * @summary サービス会員に招待メールを送信する
         * @param {InviteMember} inviteMember 招待情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInviteMailForMember(inviteMember: InviteMember, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInviteMailForMember(inviteMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サービス会員の詳細情報を更新する
         * @summary サービス会員の詳細情報を更新する
         * @param {number} id サービス会員ID
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceDoctorDetailByConference(id: number, conferenceDoctorDetail: ConferenceDoctorDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDoctors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceDoctorDetailByConference(id, conferenceDoctorDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorRestControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorRestControllerApiFp(configuration)
    return {
        /**
         * サービス会員を追加する
         * @summary サービス会員追加
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConferenceDoctor(conferenceDoctorDetail: ConferenceDoctorDetail, sendInviteMail?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.addConferenceDoctor(conferenceDoctorDetail, sendInviteMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したサービス会員を削除する
         * @summary 指定したサービス会員の削除
         * @param {number} id サービス会員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceDoctorDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceDoctorDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索結果のサービス会員をCSVにエクスポートする
         * @summary サービス会員をCSVにエクスポート
         * @param {string} organization 組織種別
         * @param {Array<number>} [ids] サービス会員IDリスト
         * @param {string} [userName] 会員名
         * @param {string} [numberString] 会員番号
         * @param {number} [jobId] 職種ID
         * @param {string} [memberStatus] 会員状態
         * @param {number} [memberTypesId] 会員･会費種別ID
         * @param {string} [paymentStatus] 年会費入金状況
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {string} [workOfficeName] 勤務先名
         * @param {string} [qualificationName] 資格・認定(状態:保有中)
         * @param {string} [sex] 性別
         * @param {string} [birthdate] 生年月日
         * @param {string} [destination] 送付先
         * @param {string} [entryDateFrom] 入会年月日FROM
         * @param {string} [entryDateTo] 入会年月日TO
         * @param {string} [committeeName] 委員会名(在任中)
         * @param {string} [executiveName] 理事･役員会名(在任中)
         * @param {number} [basicMedicalAreaId] 基本診療科
         * @param {string} [email] メールアドレス
         * @param {boolean} [invited] 招待済みか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportMemberCsv(organization: string, ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportMemberCsv(organization, ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorDetail1(id: number, options?: any): AxiosPromise<ConferenceDoctorDetail> {
            return localVarFp.fetchConferenceDoctorDetail1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [ids] 
         * @param {string} [userName] 
         * @param {string} [numberString] 
         * @param {number} [jobId] 
         * @param {string} [memberStatus] 
         * @param {number} [memberTypesId] 
         * @param {string} [paymentStatus] 
         * @param {string} [workPrefecture] 
         * @param {string} [workOfficeName] 
         * @param {string} [qualificationName] 
         * @param {string} [sex] 
         * @param {string} [birthdate] 
         * @param {string} [destination] 
         * @param {string} [entryDateFrom] 
         * @param {string} [entryDateTo] 
         * @param {string} [committeeName] 
         * @param {string} [executiveName] 
         * @param {number} [basicMedicalAreaId] 
         * @param {string} [email] 
         * @param {boolean} [invited] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorOverviews(ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceDoctorDetail> {
            return localVarFp.fetchConferenceDoctorOverviews(ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 未招待のサービス会員数を取得する
         * @summary 未招待のサービス会員数を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchNotInvitedMemberCount(options?: any): AxiosPromise<number> {
            return localVarFp.fetchNotInvitedMemberCount(options).then((request) => request(axios, basePath));
        },
        /**
         * サービス会員をMAのCSVからインポートする
         * @summary サービス会員をMAのCSVからインポート
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMaMemberCsv(sendInviteMail?: boolean, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importMaMemberCsv(sendInviteMail, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * サービス会員をCSVからインポートする
         * @summary サービス会員をCSVからインポート
         * @param {string} organization 組織種別
         * @param {boolean} [sendInviteMail] 招待メールを送るか否か
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMemberCsv(organization: string, sendInviteMail?: boolean, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importMemberCsv(organization, sendInviteMail, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * サービス会員に招待メールを送信する
         * @summary サービス会員に招待メールを送信する
         * @param {InviteMember} inviteMember 招待情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteMailForMember(inviteMember: InviteMember, options?: any): AxiosPromise<string> {
            return localVarFp.sendInviteMailForMember(inviteMember, options).then((request) => request(axios, basePath));
        },
        /**
         * サービス会員の詳細情報を更新する
         * @summary サービス会員の詳細情報を更新する
         * @param {number} id サービス会員ID
         * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceDoctorDetailByConference(id: number, conferenceDoctorDetail: ConferenceDoctorDetail, options?: any): AxiosPromise<ConferenceDoctors> {
            return localVarFp.updateConferenceDoctorDetailByConference(id, conferenceDoctorDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorRestControllerApi extends BaseAPI {
    /**
     * サービス会員を追加する
     * @summary サービス会員追加
     * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
     * @param {boolean} [sendInviteMail] 招待メールを送るか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public addConferenceDoctor(conferenceDoctorDetail: ConferenceDoctorDetail, sendInviteMail?: boolean, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).addConferenceDoctor(conferenceDoctorDetail, sendInviteMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したサービス会員を削除する
     * @summary 指定したサービス会員の削除
     * @param {number} id サービス会員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public deleteConferenceDoctorDetail(id: number, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).deleteConferenceDoctorDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索結果のサービス会員をCSVにエクスポートする
     * @summary サービス会員をCSVにエクスポート
     * @param {string} organization 組織種別
     * @param {Array<number>} [ids] サービス会員IDリスト
     * @param {string} [userName] 会員名
     * @param {string} [numberString] 会員番号
     * @param {number} [jobId] 職種ID
     * @param {string} [memberStatus] 会員状態
     * @param {number} [memberTypesId] 会員･会費種別ID
     * @param {string} [paymentStatus] 年会費入金状況
     * @param {string} [workPrefecture] 勤務先の都道府県
     * @param {string} [workOfficeName] 勤務先名
     * @param {string} [qualificationName] 資格・認定(状態:保有中)
     * @param {string} [sex] 性別
     * @param {string} [birthdate] 生年月日
     * @param {string} [destination] 送付先
     * @param {string} [entryDateFrom] 入会年月日FROM
     * @param {string} [entryDateTo] 入会年月日TO
     * @param {string} [committeeName] 委員会名(在任中)
     * @param {string} [executiveName] 理事･役員会名(在任中)
     * @param {number} [basicMedicalAreaId] 基本診療科
     * @param {string} [email] メールアドレス
     * @param {boolean} [invited] 招待済みか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public exportMemberCsv(organization: string, ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).exportMemberCsv(organization, ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public fetchConferenceDoctorDetail1(id: number, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).fetchConferenceDoctorDetail1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [ids] 
     * @param {string} [userName] 
     * @param {string} [numberString] 
     * @param {number} [jobId] 
     * @param {string} [memberStatus] 
     * @param {number} [memberTypesId] 
     * @param {string} [paymentStatus] 
     * @param {string} [workPrefecture] 
     * @param {string} [workOfficeName] 
     * @param {string} [qualificationName] 
     * @param {string} [sex] 
     * @param {string} [birthdate] 
     * @param {string} [destination] 
     * @param {string} [entryDateFrom] 
     * @param {string} [entryDateTo] 
     * @param {string} [committeeName] 
     * @param {string} [executiveName] 
     * @param {number} [basicMedicalAreaId] 
     * @param {string} [email] 
     * @param {boolean} [invited] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public fetchConferenceDoctorOverviews(ids?: Array<number>, userName?: string, numberString?: string, jobId?: number, memberStatus?: string, memberTypesId?: number, paymentStatus?: string, workPrefecture?: string, workOfficeName?: string, qualificationName?: string, sex?: string, birthdate?: string, destination?: string, entryDateFrom?: string, entryDateTo?: string, committeeName?: string, executiveName?: string, basicMedicalAreaId?: number, email?: string, invited?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).fetchConferenceDoctorOverviews(ids, userName, numberString, jobId, memberStatus, memberTypesId, paymentStatus, workPrefecture, workOfficeName, qualificationName, sex, birthdate, destination, entryDateFrom, entryDateTo, committeeName, executiveName, basicMedicalAreaId, email, invited, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 未招待のサービス会員数を取得する
     * @summary 未招待のサービス会員数を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public fetchNotInvitedMemberCount(options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).fetchNotInvitedMemberCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サービス会員をMAのCSVからインポートする
     * @summary サービス会員をMAのCSVからインポート
     * @param {boolean} [sendInviteMail] 招待メールを送るか否か
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public importMaMemberCsv(sendInviteMail?: boolean, csvFile?: any, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).importMaMemberCsv(sendInviteMail, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サービス会員をCSVからインポートする
     * @summary サービス会員をCSVからインポート
     * @param {string} organization 組織種別
     * @param {boolean} [sendInviteMail] 招待メールを送るか否か
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public importMemberCsv(organization: string, sendInviteMail?: boolean, csvFile?: any, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).importMemberCsv(organization, sendInviteMail, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サービス会員に招待メールを送信する
     * @summary サービス会員に招待メールを送信する
     * @param {InviteMember} inviteMember 招待情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public sendInviteMailForMember(inviteMember: InviteMember, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).sendInviteMailForMember(inviteMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サービス会員の詳細情報を更新する
     * @summary サービス会員の詳細情報を更新する
     * @param {number} id サービス会員ID
     * @param {ConferenceDoctorDetail} conferenceDoctorDetail 会員詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorRestControllerApi
     */
    public updateConferenceDoctorDetailByConference(id: number, conferenceDoctorDetail: ConferenceDoctorDetail, options?: any) {
        return ConferenceDoctorRestControllerApiFp(this.configuration).updateConferenceDoctorDetailByConference(id, conferenceDoctorDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceDoctorUserProfileControllerApi - axios parameter creator
 * @export
 */
export const ConferenceDoctorUserProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceDoctorUserProfileControllerApi - functional programming interface
 * @export
 */
export const ConferenceDoctorUserProfileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceDoctorUserProfileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceDoctorUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceDoctorUserProfileControllerApi - factory interface
 * @export
 */
export const ConferenceDoctorUserProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceDoctorUserProfileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<ConferenceDoctorUserProfile> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceDoctorUserProfileControllerApi - object-oriented interface
 * @export
 * @class ConferenceDoctorUserProfileControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceDoctorUserProfileControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceDoctorUserProfileControllerApi
     */
    public getProfile(options?: any) {
        return ConferenceDoctorUserProfileControllerApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceInfoRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceInfoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した学会お知らせを削除する
         * @summary 指定した学会お知らせの削除
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceInfoDetail', 'id', id)
            const localVarPath = `/api/conference/conferenceInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会お知らせのファイルを削除する
         * @summary 学会お知らせのファイルを削除
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceInfoFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceInfoFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('deleteConferenceInfoFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/conferenceInfos/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetailByConference: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAdminInfoDetailByConference', 'id', id)
            const localVarPath = `/api/conference/admin/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetailsByConference: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/admin/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の学会お知らせを取得する
         * @summary 指定した学会の学会お知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceInfoDetail', 'id', id)
            const localVarPath = `/api/conference/conferenceInfos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会お知らせのファイルURLを取得する
         * @summary 学会お知らせのファイルURLを取得
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceInfoFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('fetchConferenceInfoFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/conferenceInfos/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会お知らせのファイルURL一覧を取得する
         * @summary 学会お知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceInfoFiles', 'id', id)
            const localVarPath = `/api/conference/conferenceInfos/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoOverviews1: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/conferenceInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の学会お知らせを更新・追加する
         * @summary 指定した学会の学会お知らせの更新・追加
         * @param {ConferenceInfoDetail} conferenceInfoDetail 学会お知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceInfoDetail: async (conferenceInfoDetail: ConferenceInfoDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceInfoDetail' is not null or undefined
            assertParamExists('updateConferenceInfoDetail', 'conferenceInfoDetail', conferenceInfoDetail)
            const localVarPath = `/api/conference/conferenceInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceInfoDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会お知らせのファイルをアップロードする
         * @summary 学会お知らせのファイルをアップロード
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceInfoFile: async (id: number, fileNo: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadConferenceInfoFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('uploadConferenceInfoFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/conferenceInfos/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceInfoRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceInfoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceInfoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した学会お知らせを削除する
         * @summary 指定した学会お知らせの削除
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会お知らせのファイルを削除する
         * @summary 学会お知らせのファイルを削除
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceInfoFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceInfoFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoDetailByConference(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoDetailByConference(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoDetailsByConference(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoDetailsByConference(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の学会お知らせを取得する
         * @summary 指定した学会の学会お知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会お知らせのファイルURLを取得する
         * @summary 学会お知らせのファイルURLを取得
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会お知らせのファイルURL一覧を取得する
         * @summary 学会お知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceNotificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoOverviews1(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の学会お知らせを更新・追加する
         * @summary 指定した学会の学会お知らせの更新・追加
         * @param {ConferenceInfoDetail} conferenceInfoDetail 学会お知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceInfoDetail(conferenceInfoDetail: ConferenceInfoDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceInfoDetail(conferenceInfoDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会お知らせのファイルをアップロードする
         * @summary 学会お知らせのファイルをアップロード
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConferenceInfoFile(id: number, fileNo: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConferenceInfoFile(id, fileNo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceInfoRestControllerApi - factory interface
 * @export
 */
export const ConferenceInfoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceInfoRestControllerApiFp(configuration)
    return {
        /**
         * 指定した学会お知らせを削除する
         * @summary 指定した学会お知らせの削除
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceInfoDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会お知らせのファイルを削除する
         * @summary 学会お知らせのファイルを削除
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceInfoFile(id: number, fileNo: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceInfoFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetailByConference(id: number, options?: any): AxiosPromise<AdminInfoDetail> {
            return localVarFp.fetchAdminInfoDetailByConference(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoDetailsByConference(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdminInfoDetail> {
            return localVarFp.fetchAdminInfoDetailsByConference(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の学会お知らせを取得する
         * @summary 指定した学会の学会お知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoDetail(id: number, options?: any): AxiosPromise<ConferenceInfoDetail> {
            return localVarFp.fetchConferenceInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会お知らせのファイルURLを取得する
         * @summary 学会お知らせのファイルURLを取得
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoFile(id: number, fileNo: number, options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchConferenceInfoFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会お知らせのファイルURL一覧を取得する
         * @summary 学会お知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchConferenceInfoFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceNotificationOverview> {
            return localVarFp.fetchConferenceInfoOverviews1(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の学会お知らせを更新・追加する
         * @summary 指定した学会の学会お知らせの更新・追加
         * @param {ConferenceInfoDetail} conferenceInfoDetail 学会お知らせ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceInfoDetail(conferenceInfoDetail: ConferenceInfoDetail, options?: any): AxiosPromise<ConferenceInfoDetail> {
            return localVarFp.updateConferenceInfoDetail(conferenceInfoDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会お知らせのファイルをアップロードする
         * @summary 学会お知らせのファイルをアップロード
         * @param {number} id 学会お知らせID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceInfoFile(id: number, fileNo: number, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadConferenceInfoFile(id, fileNo, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceInfoRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceInfoRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceInfoRestControllerApi extends BaseAPI {
    /**
     * 指定した学会お知らせを削除する
     * @summary 指定した学会お知らせの削除
     * @param {number} id 学会お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public deleteConferenceInfoDetail(id: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).deleteConferenceInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会お知らせのファイルを削除する
     * @summary 学会お知らせのファイルを削除
     * @param {number} id 学会お知らせID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public deleteConferenceInfoFile(id: number, fileNo: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).deleteConferenceInfoFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchAdminInfoDetailByConference(id: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchAdminInfoDetailByConference(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchAdminInfoDetailsByConference(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchAdminInfoDetailsByConference(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の学会お知らせを取得する
     * @summary 指定した学会の学会お知らせ取得
     * @param {number} id 学会お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchConferenceInfoDetail(id: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchConferenceInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会お知らせのファイルURLを取得する
     * @summary 学会お知らせのファイルURLを取得
     * @param {number} id 学会お知らせID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchConferenceInfoFile(id: number, fileNo: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchConferenceInfoFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会お知らせのファイルURL一覧を取得する
     * @summary 学会お知らせのファイルURL一覧を取得
     * @param {number} id 学会お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchConferenceInfoFiles(id: number, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchConferenceInfoFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public fetchConferenceInfoOverviews1(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).fetchConferenceInfoOverviews1(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の学会お知らせを更新・追加する
     * @summary 指定した学会の学会お知らせの更新・追加
     * @param {ConferenceInfoDetail} conferenceInfoDetail 学会お知らせ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public updateConferenceInfoDetail(conferenceInfoDetail: ConferenceInfoDetail, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).updateConferenceInfoDetail(conferenceInfoDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会お知らせのファイルをアップロードする
     * @summary 学会お知らせのファイルをアップロード
     * @param {number} id 学会お知らせID
     * @param {number} fileNo ファイル番号
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoRestControllerApi
     */
    public uploadConferenceInfoFile(id: number, fileNo: number, file?: any, options?: any) {
        return ConferenceInfoRestControllerApiFp(this.configuration).uploadConferenceInfoFile(id, fileNo, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceInfoSendListRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceInfoSendListRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {Array<SendListMemberDetail>} sendListMemberDetail お知らせ配信メンバー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListMember: async (id: number, sendListMemberDetail: Array<SendListMemberDetail>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteListMember', 'id', id)
            // verify required parameter 'sendListMemberDetail' is not null or undefined
            assertParamExists('deleteListMember', 'sendListMemberDetail', sendListMemberDetail)
            const localVarPath = `/api/conference/conferenceInfos/list/{id}/member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendListMemberDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSendList: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSendList', 'id', id)
            const localVarPath = `/api/conference/conferenceInfos/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ配信名簿をCSVにエクスポートする
         * @summary お知らせ配信名簿をCSVにエクスポート
         * @param {number} [conferencesInfosId] ID
         * @param {string} [title] 名簿名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSendListMemberCsv: async (conferencesInfosId?: number, title?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/conferenceInfos/list/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (conferencesInfosId !== undefined) {
                localVarQueryParameter['conferencesInfosId'] = conferencesInfosId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoSendListOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/conferenceInfos/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSendList: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchSendList', 'id', id)
            const localVarPath = `/api/conference/conferenceInfos/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ配信名簿をCSVからインポートする
         * @summary お知らせ配信名簿をCSVからインポート
         * @param {number} conferenceInfosId ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSendListMemberCsv: async (conferenceInfosId: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceInfosId' is not null or undefined
            assertParamExists('importSendListMemberCsv', 'conferenceInfosId', conferenceInfosId)
            const localVarPath = `/api/conference/conferenceInfos/list/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (conferenceInfosId !== undefined) {
                localVarQueryParameter['conferenceInfosId'] = conferenceInfosId;
            }


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConferenceInfoSendListDetail} conferenceInfoSendListDetail お知らせ配信名簿情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSendList: async (conferenceInfoSendListDetail: ConferenceInfoSendListDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceInfoSendListDetail' is not null or undefined
            assertParamExists('updateSendList', 'conferenceInfoSendListDetail', conferenceInfoSendListDetail)
            const localVarPath = `/api/conference/conferenceInfos/list/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceInfoSendListDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceInfoSendListRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceInfoSendListRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceInfoSendListRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {Array<SendListMemberDetail>} sendListMemberDetail お知らせ配信メンバー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteListMember(id: number, sendListMemberDetail: Array<SendListMemberDetail>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteListMember(id, sendListMemberDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSendList(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSendList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ配信名簿をCSVにエクスポートする
         * @summary お知らせ配信名簿をCSVにエクスポート
         * @param {number} [conferencesInfosId] ID
         * @param {string} [title] 名簿名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportSendListMemberCsv(conferencesInfosId?: number, title?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportSendListMemberCsv(conferencesInfosId, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoSendListOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceInfoSendListOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoSendListOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSendList(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceInfoSendListDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSendList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ配信名簿をCSVからインポートする
         * @summary お知らせ配信名簿をCSVからインポート
         * @param {number} conferenceInfosId ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importSendListMemberCsv(conferenceInfosId: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importSendListMemberCsv(conferenceInfosId, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConferenceInfoSendListDetail} conferenceInfoSendListDetail お知らせ配信名簿情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSendList(conferenceInfoSendListDetail: ConferenceInfoSendListDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceInfoSendListDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSendList(conferenceInfoSendListDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceInfoSendListRestControllerApi - factory interface
 * @export
 */
export const ConferenceInfoSendListRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceInfoSendListRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {Array<SendListMemberDetail>} sendListMemberDetail お知らせ配信メンバー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListMember(id: number, sendListMemberDetail: Array<SendListMemberDetail>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteListMember(id, sendListMemberDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSendList(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSendList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ配信名簿をCSVにエクスポートする
         * @summary お知らせ配信名簿をCSVにエクスポート
         * @param {number} [conferencesInfosId] ID
         * @param {string} [title] 名簿名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSendListMemberCsv(conferencesInfosId?: number, title?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportSendListMemberCsv(conferencesInfosId, title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoSendListOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceInfoSendListOverview> {
            return localVarFp.fetchConferenceInfoSendListOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id お知らせ配信名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSendList(id: number, options?: any): AxiosPromise<ConferenceInfoSendListDetail> {
            return localVarFp.fetchSendList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ配信名簿をCSVからインポートする
         * @summary お知らせ配信名簿をCSVからインポート
         * @param {number} conferenceInfosId ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSendListMemberCsv(conferenceInfosId: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importSendListMemberCsv(conferenceInfosId, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConferenceInfoSendListDetail} conferenceInfoSendListDetail お知らせ配信名簿情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSendList(conferenceInfoSendListDetail: ConferenceInfoSendListDetail, options?: any): AxiosPromise<ConferenceInfoSendListDetail> {
            return localVarFp.updateSendList(conferenceInfoSendListDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceInfoSendListRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceInfoSendListRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceInfoSendListRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id お知らせ配信名簿ID
     * @param {Array<SendListMemberDetail>} sendListMemberDetail お知らせ配信メンバー詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public deleteListMember(id: number, sendListMemberDetail: Array<SendListMemberDetail>, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).deleteListMember(id, sendListMemberDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id お知らせ配信名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public deleteSendList(id: number, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).deleteSendList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ配信名簿をCSVにエクスポートする
     * @summary お知らせ配信名簿をCSVにエクスポート
     * @param {number} [conferencesInfosId] ID
     * @param {string} [title] 名簿名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public exportSendListMemberCsv(conferencesInfosId?: number, title?: string, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).exportSendListMemberCsv(conferencesInfosId, title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public fetchConferenceInfoSendListOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).fetchConferenceInfoSendListOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id お知らせ配信名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public fetchSendList(id: number, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).fetchSendList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ配信名簿をCSVからインポートする
     * @summary お知らせ配信名簿をCSVからインポート
     * @param {number} conferenceInfosId ID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public importSendListMemberCsv(conferenceInfosId: number, csvFile?: any, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).importSendListMemberCsv(conferenceInfosId, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConferenceInfoSendListDetail} conferenceInfoSendListDetail お知らせ配信名簿情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceInfoSendListRestControllerApi
     */
    public updateSendList(conferenceInfoSendListDetail: ConferenceInfoSendListDetail, options?: any) {
        return ConferenceInfoSendListRestControllerApiFp(this.configuration).updateSendList(conferenceInfoSendListDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceFile: async (type: 'logo' | 'stamp', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteConferenceFile', 'type', type)
            const localVarPath = `/api/conference/conferenceFiles/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFile: async (type: 'logo' | 'stamp', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('fetchConferenceFile', 'type', type)
            const localVarPath = `/api/conference/conferenceFiles/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFiles: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/conferenceFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/conferenceDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceMemberTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/memberTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の会員数情報を取得する 取得できるのはログインしている学会ユーザが所属している学会のみ
         * @summary 学会の会員数情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceMembership: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/membership`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理情報を更新する
         * @summary 学会管理情報更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceManagementDetail: async (conferenceManagementDetail: ConferenceManagementDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceManagementDetail' is not null or undefined
            assertParamExists('updateConferenceManagementDetail', 'conferenceManagementDetail', conferenceManagementDetail)
            const localVarPath = `/api/conference/conferenceDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceManagementDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceFile: async (type: 'logo' | 'stamp', file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadConferenceFile', 'type', type)
            const localVarPath = `/api/conference/conferenceFiles/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceFile(type: 'logo' | 'stamp', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceFile(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceFile(type: 'logo' | 'stamp', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceFile(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceFiles(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceManagementDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceManagementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceManagementDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceMemberTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberTypeItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceMemberTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の会員数情報を取得する 取得できるのはログインしている学会ユーザが所属している学会のみ
         * @summary 学会の会員数情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceMembership(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceMembership>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceMembership(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理情報を更新する
         * @summary 学会管理情報更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceManagementDetail(conferenceManagementDetail: ConferenceManagementDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceManagementDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceManagementDetail(conferenceManagementDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConferenceFile(type: 'logo' | 'stamp', file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConferenceFile(type, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceRestControllerApi - factory interface
 * @export
 */
export const ConferenceRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceRestControllerApiFp(configuration)
    return {
        /**
         * 学会のファイルを削除する
         * @summary 学会のファイルを削除
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceFile(type: 'logo' | 'stamp', options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceFile(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイルを取得する
         * @summary 学会のファイルを取得
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFile(type: 'logo' | 'stamp', options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchConferenceFile(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイル一覧を取得する
         * @summary 学会のファイル一覧を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceFiles(options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchConferenceFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会情報を取得する
         * @summary 学会情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceManagementDetail(options?: any): AxiosPromise<ConferenceManagementDetail> {
            return localVarFp.fetchConferenceManagementDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceMemberTypes(options?: any): AxiosPromise<Array<MemberTypeItem>> {
            return localVarFp.fetchConferenceMemberTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の会員数情報を取得する 取得できるのはログインしている学会ユーザが所属している学会のみ
         * @summary 学会の会員数情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceMembership(options?: any): AxiosPromise<ConferenceMembership> {
            return localVarFp.fetchConferenceMembership(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理情報を更新する
         * @summary 学会管理情報更新
         * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceManagementDetail(conferenceManagementDetail: ConferenceManagementDetail, options?: any): AxiosPromise<ConferenceManagementDetail> {
            return localVarFp.updateConferenceManagementDetail(conferenceManagementDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のファイルをアップロードする
         * @summary 学会のファイルをアップロード
         * @param {'logo' | 'stamp'} type ファイルタイプ
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConferenceFile(type: 'logo' | 'stamp', file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadConferenceFile(type, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceRestControllerApi extends BaseAPI {
    /**
     * 学会のファイルを削除する
     * @summary 学会のファイルを削除
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public deleteConferenceFile(type: 'logo' | 'stamp', options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).deleteConferenceFile(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイルを取得する
     * @summary 学会のファイルを取得
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public fetchConferenceFile(type: 'logo' | 'stamp', options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).fetchConferenceFile(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイル一覧を取得する
     * @summary 学会のファイル一覧を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public fetchConferenceFiles(options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).fetchConferenceFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会情報を取得する
     * @summary 学会情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public fetchConferenceManagementDetail(options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).fetchConferenceManagementDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public fetchConferenceMemberTypes(options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).fetchConferenceMemberTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の会員数情報を取得する 取得できるのはログインしている学会ユーザが所属している学会のみ
     * @summary 学会の会員数情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public fetchConferenceMembership(options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).fetchConferenceMembership(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理情報を更新する
     * @summary 学会管理情報更新
     * @param {ConferenceManagementDetail} conferenceManagementDetail 学会詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public updateConferenceManagementDetail(conferenceManagementDetail: ConferenceManagementDetail, options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).updateConferenceManagementDetail(conferenceManagementDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のファイルをアップロードする
     * @summary 学会のファイルをアップロード
     * @param {'logo' | 'stamp'} type ファイルタイプ
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceRestControllerApi
     */
    public uploadConferenceFile(type: 'logo' | 'stamp', file?: any, options?: any) {
        return ConferenceRestControllerApiFp(this.configuration).uploadConferenceFile(type, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceSpecializedAreaRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceSpecializedAreaRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した基本診療科マスタを削除する
         * @summary 指定した基本診療科マスタの削除
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceSpecializedAreaMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceSpecializedAreaMaster', 'id', id)
            const localVarPath = `/api/conference/specializedAreas/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の基本診療科マスタを取得する
         * @summary 指定した学会の基本診療科マスタ取得
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSpecializedAreaMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceSpecializedAreaMaster', 'id', id)
            const localVarPath = `/api/conference/specializedAreas/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の基本診療科マスタ一覧を取得する
         * @summary 学会の基本診療科マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSpecializedAreaMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/specializedAreas/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の基本診療科マスタを更新・追加する
         * @summary 指定した学会の基本診療科マスタの更新・追加
         * @param {ConferenceSpecializedAreaDetail} conferenceSpecializedAreaDetail 基本診療科マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceSpecializedAreaMaster: async (conferenceSpecializedAreaDetail: ConferenceSpecializedAreaDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceSpecializedAreaDetail' is not null or undefined
            assertParamExists('updateConferenceSpecializedAreaMaster', 'conferenceSpecializedAreaDetail', conferenceSpecializedAreaDetail)
            const localVarPath = `/api/conference/specializedAreas/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceSpecializedAreaDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceSpecializedAreaRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceSpecializedAreaRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceSpecializedAreaRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した基本診療科マスタを削除する
         * @summary 指定した基本診療科マスタの削除
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceSpecializedAreaMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceSpecializedAreaMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の基本診療科マスタを取得する
         * @summary 指定した学会の基本診療科マスタ取得
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceSpecializedAreaMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceSpecializedAreaDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceSpecializedAreaMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の基本診療科マスタ一覧を取得する
         * @summary 学会の基本診療科マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceSpecializedAreaMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceSpecializedAreaDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceSpecializedAreaMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の基本診療科マスタを更新・追加する
         * @summary 指定した学会の基本診療科マスタの更新・追加
         * @param {ConferenceSpecializedAreaDetail} conferenceSpecializedAreaDetail 基本診療科マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail: ConferenceSpecializedAreaDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceSpecializedAreaDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceSpecializedAreaRestControllerApi - factory interface
 * @export
 */
export const ConferenceSpecializedAreaRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceSpecializedAreaRestControllerApiFp(configuration)
    return {
        /**
         * 指定した基本診療科マスタを削除する
         * @summary 指定した基本診療科マスタの削除
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceSpecializedAreaMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceSpecializedAreaMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の基本診療科マスタを取得する
         * @summary 指定した学会の基本診療科マスタ取得
         * @param {number} id 基本診療科マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSpecializedAreaMaster(id: number, options?: any): AxiosPromise<ConferenceSpecializedAreaDetail> {
            return localVarFp.fetchConferenceSpecializedAreaMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の基本診療科マスタ一覧を取得する
         * @summary 学会の基本診療科マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceSpecializedAreaMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceSpecializedAreaDetail> {
            return localVarFp.fetchConferenceSpecializedAreaMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の基本診療科マスタを更新・追加する
         * @summary 指定した学会の基本診療科マスタの更新・追加
         * @param {ConferenceSpecializedAreaDetail} conferenceSpecializedAreaDetail 基本診療科マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail: ConferenceSpecializedAreaDetail, options?: any): AxiosPromise<ConferenceSpecializedAreaDetail> {
            return localVarFp.updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceSpecializedAreaRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceSpecializedAreaRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceSpecializedAreaRestControllerApi extends BaseAPI {
    /**
     * 指定した基本診療科マスタを削除する
     * @summary 指定した基本診療科マスタの削除
     * @param {number} id 基本診療科マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceSpecializedAreaRestControllerApi
     */
    public deleteConferenceSpecializedAreaMaster(id: number, options?: any) {
        return ConferenceSpecializedAreaRestControllerApiFp(this.configuration).deleteConferenceSpecializedAreaMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の基本診療科マスタを取得する
     * @summary 指定した学会の基本診療科マスタ取得
     * @param {number} id 基本診療科マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceSpecializedAreaRestControllerApi
     */
    public fetchConferenceSpecializedAreaMaster(id: number, options?: any) {
        return ConferenceSpecializedAreaRestControllerApiFp(this.configuration).fetchConferenceSpecializedAreaMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の基本診療科マスタ一覧を取得する
     * @summary 学会の基本診療科マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceSpecializedAreaRestControllerApi
     */
    public fetchConferenceSpecializedAreaMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceSpecializedAreaRestControllerApiFp(this.configuration).fetchConferenceSpecializedAreaMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の基本診療科マスタを更新・追加する
     * @summary 指定した学会の基本診療科マスタの更新・追加
     * @param {ConferenceSpecializedAreaDetail} conferenceSpecializedAreaDetail 基本診療科マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceSpecializedAreaRestControllerApi
     */
    public updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail: ConferenceSpecializedAreaDetail, options?: any) {
        return ConferenceSpecializedAreaRestControllerApiFp(this.configuration).updateConferenceSpecializedAreaMaster(conferenceSpecializedAreaDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConferenceUserRestControllerApi - axios parameter creator
 * @export
 */
export const ConferenceUserRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmailByConference: async (checkEmailParam: CheckEmailParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkEmailParam' is not null or undefined
            assertParamExists('checkConferenceUserDuplicateEmailByConference', 'checkEmailParam', checkEmailParam)
            const localVarPath = `/api/conference/users/email/duplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEmailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConferenceUser', 'id', id)
            const localVarPath = `/api/conference/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceUser', 'id', id)
            const localVarPath = `/api/conference/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を取得する
         * @summary 学会管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUsers: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザーのプロファイルを取得する
         * @summary 学会管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUser: async (conferenceUserDetail: ConferenceUserDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceUserDetail' is not null or undefined
            assertParamExists('updateConferenceUser', 'conferenceUserDetail', conferenceUserDetail)
            const localVarPath = `/api/conference/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceUserDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を更新する
         * @summary 学会管理ユーザーのユーザー情報を更新
         * @param {ConferenceUserInfo} conferenceUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUserInfo: async (conferenceUserInfo: ConferenceUserInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conferenceUserInfo' is not null or undefined
            assertParamExists('updateConferenceUserInfo', 'conferenceUserInfo', conferenceUserInfo)
            const localVarPath = `/api/conference/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conferenceUserInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConferenceUserRestControllerApi - functional programming interface
 * @export
 */
export const ConferenceUserRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConferenceUserRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkConferenceUserDuplicateEmailByConference(checkEmailParam: CheckEmailParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkConferenceUserDuplicateEmailByConference(checkEmailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConferenceUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConferenceUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を取得する
         * @summary 学会管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceUsers(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceUsers(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザーのプロファイルを取得する
         * @summary 学会管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceUser(conferenceUserDetail: ConferenceUserDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceUserDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceUser(conferenceUserDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を更新する
         * @summary 学会管理ユーザーのユーザー情報を更新
         * @param {ConferenceUserInfo} conferenceUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConferenceUserInfo(conferenceUserInfo: ConferenceUserInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConferenceUserInfo(conferenceUserInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConferenceUserRestControllerApi - factory interface
 * @export
 */
export const ConferenceUserRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConferenceUserRestControllerApiFp(configuration)
    return {
        /**
         * 学会管理ユーザーのメールアドレスの重複をチェックする
         * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
         * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkConferenceUserDuplicateEmailByConference(checkEmailParam: CheckEmailParam, options?: any): AxiosPromise<string> {
            return localVarFp.checkConferenceUserDuplicateEmailByConference(checkEmailParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会管理ユーザーを削除する
         * @summary 指定した学会管理ユーザーの削除
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConferenceUser(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteConferenceUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会管理ユーザーの詳細を取得する
         * @summary 学会管理ユーザーの詳細を取得
         * @param {number} id 学会管理ユーザーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUser(id: number, options?: any): AxiosPromise<ConferenceUserDetail> {
            return localVarFp.fetchConferenceUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を取得する
         * @summary 学会管理ユーザーのユーザー情報を取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUserInfo(options?: any): AxiosPromise<ConferenceUserInfo> {
            return localVarFp.fetchConferenceUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザー一覧を取得する
         * @summary 学会管理ユーザー一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceUsers(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceUserDetail> {
            return localVarFp.fetchConferenceUsers(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザーのプロファイルを取得する
         * @summary 学会管理ユーザーのプロファイル取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile1(options?: any): AxiosPromise<ConferenceUserProfile> {
            return localVarFp.getProfile1(options).then((request) => request(axios, basePath));
        },
        /**
         * 学会管理ユーザーを登録・更新する
         * @summary 学会管理ユーザー登録・更新
         * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUser(conferenceUserDetail: ConferenceUserDetail, options?: any): AxiosPromise<ConferenceUserDetail> {
            return localVarFp.updateConferenceUser(conferenceUserDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしている学会管理ユーザーのユーザー情報を更新する
         * @summary 学会管理ユーザーのユーザー情報を更新
         * @param {ConferenceUserInfo} conferenceUserInfo ユーザー情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConferenceUserInfo(conferenceUserInfo: ConferenceUserInfo, options?: any): AxiosPromise<string> {
            return localVarFp.updateConferenceUserInfo(conferenceUserInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConferenceUserRestControllerApi - object-oriented interface
 * @export
 * @class ConferenceUserRestControllerApi
 * @extends {BaseAPI}
 */
export class ConferenceUserRestControllerApi extends BaseAPI {
    /**
     * 学会管理ユーザーのメールアドレスの重複をチェックする
     * @summary 学会管理ユーザーのメールアドレスの重複をチェックする
     * @param {CheckEmailParam} checkEmailParam メールアドレスパラメーター
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public checkConferenceUserDuplicateEmailByConference(checkEmailParam: CheckEmailParam, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).checkConferenceUserDuplicateEmailByConference(checkEmailParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会管理ユーザーを削除する
     * @summary 指定した学会管理ユーザーの削除
     * @param {number} id 学会管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public deleteConferenceUser(id: number, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).deleteConferenceUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会管理ユーザーの詳細を取得する
     * @summary 学会管理ユーザーの詳細を取得
     * @param {number} id 学会管理ユーザーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public fetchConferenceUser(id: number, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).fetchConferenceUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしている学会管理ユーザーのユーザー情報を取得する
     * @summary 学会管理ユーザーのユーザー情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public fetchConferenceUserInfo(options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).fetchConferenceUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザー一覧を取得する
     * @summary 学会管理ユーザー一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public fetchConferenceUsers(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).fetchConferenceUsers(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザーのプロファイルを取得する
     * @summary 学会管理ユーザーのプロファイル取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public getProfile1(options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).getProfile1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会管理ユーザーを登録・更新する
     * @summary 学会管理ユーザー登録・更新
     * @param {ConferenceUserDetail} conferenceUserDetail 学会管理ユーザー詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public updateConferenceUser(conferenceUserDetail: ConferenceUserDetail, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).updateConferenceUser(conferenceUserDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしている学会管理ユーザーのユーザー情報を更新する
     * @summary 学会管理ユーザーのユーザー情報を更新
     * @param {ConferenceUserInfo} conferenceUserInfo ユーザー情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConferenceUserRestControllerApi
     */
    public updateConferenceUserInfo(conferenceUserInfo: ConferenceUserInfo, options?: any) {
        return ConferenceUserRestControllerApiFp(this.configuration).updateConferenceUserInfo(conferenceUserInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoctorInfoRestControllerApi - axios parameter creator
 * @export
 */
export const DoctorInfoRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/admin/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/conference/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAdminInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberAdminInfoDetail', 'id', id)
            const localVarPath = `/api/member/admin/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員の指定した学会の学会お知らせを取得する
         * @summary ログインしているサービス会員の指定した学会からのお知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberConferenceInfoDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberConferenceInfoDetail', 'id', id)
            const localVarPath = `/api/member/conference/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会からのお知らせファイルURL一覧を取得する
         * @summary 指定した学会からのお知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberConferenceInfoFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberConferenceInfoFiles', 'id', id)
            const localVarPath = `/api/member/conference/notifications/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaidDoctorPaymentOverview: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPaidDoctorPaymentOverview', 'id', id)
            const localVarPath = `/api/member/payment/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaidDoctorPaymentOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payment/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorInfoRestControllerApi - functional programming interface
 * @export
 */
export const DoctorInfoRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorInfoRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAdminInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdminInfoOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAdminInfoOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConferenceNotificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceInfoOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberAdminInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberAdminInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員の指定した学会の学会お知らせを取得する
         * @summary ログインしているサービス会員の指定した学会からのお知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberConferenceInfoDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberConferenceInfoDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会からのお知らせファイルURL一覧を取得する
         * @summary 指定した学会からのお知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberConferenceInfoFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberConferenceInfoFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPaidDoctorPaymentOverview(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfoOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPaidDoctorPaymentOverview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPaidDoctorPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentInfoOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPaidDoctorPaymentOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorInfoRestControllerApi - factory interface
 * @export
 */
export const DoctorInfoRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorInfoRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAdminInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAdminInfoOverview> {
            return localVarFp.fetchAdminInfoOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConferenceNotificationOverview> {
            return localVarFp.fetchConferenceInfoOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberAdminInfoDetail(id: number, options?: any): AxiosPromise<AdminInfoDetail> {
            return localVarFp.fetchMemberAdminInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員の指定した学会の学会お知らせを取得する
         * @summary ログインしているサービス会員の指定した学会からのお知らせ取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberConferenceInfoDetail(id: number, options?: any): AxiosPromise<ConferenceInfoDetail> {
            return localVarFp.fetchMemberConferenceInfoDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会からのお知らせファイルURL一覧を取得する
         * @summary 指定した学会からのお知らせのファイルURL一覧を取得
         * @param {number} id 学会お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberConferenceInfoFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchMemberConferenceInfoFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaidDoctorPaymentOverview(id: number, options?: any): AxiosPromise<PaymentInfoOverview> {
            return localVarFp.fetchPaidDoctorPaymentOverview(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaidDoctorPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentInfoOverview> {
            return localVarFp.fetchPaidDoctorPaymentOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorInfoRestControllerApi - object-oriented interface
 * @export
 * @class DoctorInfoRestControllerApi
 * @extends {BaseAPI}
 */
export class DoctorInfoRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchAdminInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchAdminInfoOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchConferenceInfoOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchConferenceInfoOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchMemberAdminInfoDetail(id: number, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchMemberAdminInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員の指定した学会の学会お知らせを取得する
     * @summary ログインしているサービス会員の指定した学会からのお知らせ取得
     * @param {number} id 学会お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchMemberConferenceInfoDetail(id: number, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchMemberConferenceInfoDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会からのお知らせファイルURL一覧を取得する
     * @summary 指定した学会からのお知らせのファイルURL一覧を取得
     * @param {number} id 学会お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchMemberConferenceInfoFiles(id: number, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchMemberConferenceInfoFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchPaidDoctorPaymentOverview(id: number, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchPaidDoctorPaymentOverview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorInfoRestControllerApi
     */
    public fetchPaidDoctorPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorInfoRestControllerApiFp(this.configuration).fetchPaidDoctorPaymentOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DoctorPaymentRestControllerApi - axios parameter creator
 * @export
 */
export const DoctorPaymentRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインしているサービス会員の指定したIDの支払いをクレジットカード決済する
         * @summary 指定したIDの支払いをクレジットカード決済する
         * @param {number} id 支払いID
         * @param {SettlementInfo} settlementInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doCreditCardDoctorPayment: async (id: number, settlementInfo: SettlementInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('doCreditCardDoctorPayment', 'id', id)
            // verify required parameter 'settlementInfo' is not null or undefined
            assertParamExists('doCreditCardDoctorPayment', 'settlementInfo', settlementInfo)
            const localVarPath = `/api/member/payments/{id}/creditCard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員の指定したIDの支払いをコンビニ決済する
         * @summary 指定したIDの支払いをコンビニ決済する
         * @param {number} id 支払いID
         * @param {CvsPaymentInfo} cvsPaymentInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doCvsDoctorPayment: async (id: number, cvsPaymentInfo: CvsPaymentInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('doCvsDoctorPayment', 'id', id)
            // verify required parameter 'cvsPaymentInfo' is not null or undefined
            assertParamExists('doCvsDoctorPayment', 'cvsPaymentInfo', cvsPaymentInfo)
            const localVarPath = `/api/member/payments/{id}/convenience`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cvsPaymentInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を削除する
         * @summary クレジットカード情報の削除
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDeleteCard: async (creditCardInfo: CreditCardInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCardInfo' is not null or undefined
            assertParamExists('doDeleteCard', 'creditCardInfo', creditCardInfo)
            const localVarPath = `/api/member/payments/creditCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creditCardInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を登録/更新する
         * @summary クレジットカード情報の登録/更新
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doSaveCard: async (creditCardInfo: CreditCardInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCardInfo' is not null or undefined
            assertParamExists('doSaveCard', 'creditCardInfo', creditCardInfo)
            const localVarPath = `/api/member/payments/creditCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creditCardInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書を発行する
         * @summary 請求書の発行
         * @param {number} paymentId 宛名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice: async (paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('downloadInvoice', 'paymentId', paymentId)
            const localVarPath = `/api/member/payments/invoice/download/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 領収書を発行する
         * @summary 領収書の発行
         * @param {number} paymentId 宛名
         * @param {string} freeAddress 領収書作成用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReceipt: async (paymentId: number, freeAddress: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('downloadReceipt', 'paymentId', paymentId)
            // verify required parameter 'freeAddress' is not null or undefined
            assertParamExists('downloadReceipt', 'freeAddress', freeAddress)
            const localVarPath = `/api/member/payments/receipt/download/{paymentId}/{freeAddress}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"freeAddress"}}`, encodeURIComponent(String(freeAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeePaymentOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/annualFees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を取得する
         * @summary クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCardList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/creditCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員のデフォルト(継続課金対象の)クレジットカード情報を取得する
         * @summary デフォルト(継続課金対象の)クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDefaultCard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/creditCard/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしているサービス会員の指定したIDの支払情報を取得する
         * @summary 指定したIDの支払情報を取得
         * @param {number} id 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoctorPaymentOverview: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchDoctorPaymentOverview', 'id', id)
            const localVarPath = `/api/member/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [paymentStatus] 
         * @param {string} [paymentContentType] 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoctorPaymentOverviews: async (paymentStatus?: string, paymentContentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentContentType !== undefined) {
                localVarQueryParameter['paymentContentType'] = paymentContentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/etcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoiceDetail: async (paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('fetchInvoiceDetail', 'paymentId', paymentId)
            const localVarPath = `/api/member/payments/invoiceDetail/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationsPaymentOverviews: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/member/payments/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchReceiptDetail: async (paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('fetchReceiptDetail', 'paymentId', paymentId)
            const localVarPath = `/api/member/payments/receiptDetail/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchReceiptOverviews: async (paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('fetchReceiptOverviews', 'paymentId', paymentId)
            const localVarPath = `/api/member/payments/receipts/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorPaymentRestControllerApi - functional programming interface
 * @export
 */
export const DoctorPaymentRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorPaymentRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインしているサービス会員の指定したIDの支払いをクレジットカード決済する
         * @summary 指定したIDの支払いをクレジットカード決済する
         * @param {number} id 支払いID
         * @param {SettlementInfo} settlementInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doCreditCardDoctorPayment(id: number, settlementInfo: SettlementInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doCreditCardDoctorPayment(id, settlementInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員の指定したIDの支払いをコンビニ決済する
         * @summary 指定したIDの支払いをコンビニ決済する
         * @param {number} id 支払いID
         * @param {CvsPaymentInfo} cvsPaymentInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doCvsDoctorPayment(id: number, cvsPaymentInfo: CvsPaymentInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CvsPaymentResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doCvsDoctorPayment(id, cvsPaymentInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を削除する
         * @summary クレジットカード情報の削除
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doDeleteCard(creditCardInfo: CreditCardInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doDeleteCard(creditCardInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を登録/更新する
         * @summary クレジットカード情報の登録/更新
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doSaveCard(creditCardInfo: CreditCardInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doSaveCard(creditCardInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書を発行する
         * @summary 請求書の発行
         * @param {number} paymentId 宛名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInvoice(paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInvoice(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 領収書を発行する
         * @summary 領収書の発行
         * @param {number} paymentId 宛名
         * @param {string} freeAddress 領収書作成用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReceipt(paymentId: number, freeAddress: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReceipt(paymentId, freeAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAnnualFeePaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAnnualFeePaymentOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を取得する
         * @summary クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchCardList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmoCardInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchCardList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員のデフォルト(継続課金対象の)クレジットカード情報を取得する
         * @summary デフォルト(継続課金対象の)クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDefaultCard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmoCardInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDefaultCard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしているサービス会員の指定したIDの支払情報を取得する
         * @summary 指定したIDの支払情報を取得
         * @param {number} id 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDoctorPaymentOverview(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDoctorPaymentOverview(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [paymentStatus] 
         * @param {string} [paymentContentType] 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDoctorPaymentOverviews(paymentStatus?: string, paymentContentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDoctorPaymentOverviews(paymentStatus, paymentContentType, paymentDateFrom, paymentDateTo, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcPaymentOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInvoiceDetail(paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInvoiceDetail(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationsPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationsPaymentOverviews(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchReceiptDetail(paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchReceiptDetail(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchReceiptOverviews(paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceiptOverview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchReceiptOverviews(paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorPaymentRestControllerApi - factory interface
 * @export
 */
export const DoctorPaymentRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorPaymentRestControllerApiFp(configuration)
    return {
        /**
         * ログインしているサービス会員の指定したIDの支払いをクレジットカード決済する
         * @summary 指定したIDの支払いをクレジットカード決済する
         * @param {number} id 支払いID
         * @param {SettlementInfo} settlementInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doCreditCardDoctorPayment(id: number, settlementInfo: SettlementInfo, options?: any): AxiosPromise<string> {
            return localVarFp.doCreditCardDoctorPayment(id, settlementInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員の指定したIDの支払いをコンビニ決済する
         * @summary 指定したIDの支払いをコンビニ決済する
         * @param {number} id 支払いID
         * @param {CvsPaymentInfo} cvsPaymentInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doCvsDoctorPayment(id: number, cvsPaymentInfo: CvsPaymentInfo, options?: any): AxiosPromise<CvsPaymentResult> {
            return localVarFp.doCvsDoctorPayment(id, cvsPaymentInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を削除する
         * @summary クレジットカード情報の削除
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doDeleteCard(creditCardInfo: CreditCardInfo, options?: any): AxiosPromise<string> {
            return localVarFp.doDeleteCard(creditCardInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を登録/更新する
         * @summary クレジットカード情報の登録/更新
         * @param {CreditCardInfo} creditCardInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doSaveCard(creditCardInfo: CreditCardInfo, options?: any): AxiosPromise<string> {
            return localVarFp.doSaveCard(creditCardInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書を発行する
         * @summary 請求書の発行
         * @param {number} paymentId 宛名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInvoice(paymentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.downloadInvoice(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 領収書を発行する
         * @summary 領収書の発行
         * @param {number} paymentId 宛名
         * @param {string} freeAddress 領収書作成用情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReceipt(paymentId: number, freeAddress: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadReceipt(paymentId, freeAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeePaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchAnnualFeePaymentOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員のクレジットカード情報を取得する
         * @summary クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCardList(options?: any): AxiosPromise<Array<GmoCardInfo>> {
            return localVarFp.fetchCardList(options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員のデフォルト(継続課金対象の)クレジットカード情報を取得する
         * @summary デフォルト(継続課金対象の)クレジットカード情報の取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDefaultCard(options?: any): AxiosPromise<GmoCardInfo> {
            return localVarFp.fetchDefaultCard(options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしているサービス会員の指定したIDの支払情報を取得する
         * @summary 指定したIDの支払情報を取得
         * @param {number} id 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoctorPaymentOverview(id: number, options?: any): AxiosPromise<PaymentOverview> {
            return localVarFp.fetchDoctorPaymentOverview(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [paymentStatus] 
         * @param {string} [paymentContentType] 
         * @param {string} [paymentDateFrom] 
         * @param {string} [paymentDateTo] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDoctorPaymentOverviews(paymentStatus?: string, paymentContentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchDoctorPaymentOverviews(paymentStatus, paymentContentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchEtcPaymentOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInvoiceDetail(paymentId: number, options?: any): AxiosPromise<InvoiceDetail> {
            return localVarFp.fetchInvoiceDetail(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationsPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchQualificationsPaymentOverviews(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchReceiptDetail(paymentId: number, options?: any): AxiosPromise<ReceiptDetail> {
            return localVarFp.fetchReceiptDetail(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} paymentId 支払いID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchReceiptOverviews(paymentId: number, options?: any): AxiosPromise<Array<ReceiptOverview>> {
            return localVarFp.fetchReceiptOverviews(paymentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorPaymentRestControllerApi - object-oriented interface
 * @export
 * @class DoctorPaymentRestControllerApi
 * @extends {BaseAPI}
 */
export class DoctorPaymentRestControllerApi extends BaseAPI {
    /**
     * ログインしているサービス会員の指定したIDの支払いをクレジットカード決済する
     * @summary 指定したIDの支払いをクレジットカード決済する
     * @param {number} id 支払いID
     * @param {SettlementInfo} settlementInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public doCreditCardDoctorPayment(id: number, settlementInfo: SettlementInfo, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).doCreditCardDoctorPayment(id, settlementInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員の指定したIDの支払いをコンビニ決済する
     * @summary 指定したIDの支払いをコンビニ決済する
     * @param {number} id 支払いID
     * @param {CvsPaymentInfo} cvsPaymentInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public doCvsDoctorPayment(id: number, cvsPaymentInfo: CvsPaymentInfo, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).doCvsDoctorPayment(id, cvsPaymentInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員のクレジットカード情報を削除する
     * @summary クレジットカード情報の削除
     * @param {CreditCardInfo} creditCardInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public doDeleteCard(creditCardInfo: CreditCardInfo, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).doDeleteCard(creditCardInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員のクレジットカード情報を登録/更新する
     * @summary クレジットカード情報の登録/更新
     * @param {CreditCardInfo} creditCardInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public doSaveCard(creditCardInfo: CreditCardInfo, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).doSaveCard(creditCardInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書を発行する
     * @summary 請求書の発行
     * @param {number} paymentId 宛名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public downloadInvoice(paymentId: number, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).downloadInvoice(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 領収書を発行する
     * @summary 領収書の発行
     * @param {number} paymentId 宛名
     * @param {string} freeAddress 領収書作成用情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public downloadReceipt(paymentId: number, freeAddress: string, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).downloadReceipt(paymentId, freeAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchAnnualFeePaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchAnnualFeePaymentOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員のクレジットカード情報を取得する
     * @summary クレジットカード情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchCardList(options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchCardList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員のデフォルト(継続課金対象の)クレジットカード情報を取得する
     * @summary デフォルト(継続課金対象の)クレジットカード情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchDefaultCard(options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchDefaultCard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしているサービス会員の指定したIDの支払情報を取得する
     * @summary 指定したIDの支払情報を取得
     * @param {number} id 支払いID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchDoctorPaymentOverview(id: number, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchDoctorPaymentOverview(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [paymentStatus] 
     * @param {string} [paymentContentType] 
     * @param {string} [paymentDateFrom] 
     * @param {string} [paymentDateTo] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchDoctorPaymentOverviews(paymentStatus?: string, paymentContentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchDoctorPaymentOverviews(paymentStatus, paymentContentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchEtcPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchEtcPaymentOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} paymentId 支払いID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchInvoiceDetail(paymentId: number, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchInvoiceDetail(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchQualificationsPaymentOverviews(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchQualificationsPaymentOverviews(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} paymentId 支払いID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchReceiptDetail(paymentId: number, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchReceiptDetail(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} paymentId 支払いID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorPaymentRestControllerApi
     */
    public fetchReceiptOverviews(paymentId: number, options?: any) {
        return DoctorPaymentRestControllerApiFp(this.configuration).fetchReceiptOverviews(paymentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EntryMeetingRestControllerApi - axios parameter creator
 * @export
 */
export const EntryMeetingRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した学術集会･セミナーの参加者情報を削除する
         * @summary 指定した学術集会･セミナーの参加者情報の削除
         * @param {number} id 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntryMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEntryMeeting', 'id', id)
            const localVarPath = `/api/conference/entryMeetingMembers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの複数の参加者情報を削除する
         * @summary 指定した学術集会･セミナーの複数の参加者情報の削除
         * @param {Array<number>} requestBody 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntryMeetingMembers: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteEntryMeetingMembers', 'requestBody', requestBody)
            const localVarPath = `/api/conference/entryMeetingMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVにエクスポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVにエクスポート
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryMeetingCsv: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEntryMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}/entryMeetingMembers/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAttendMeetingDetail: async (id: number, meetingsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAttendMeetingDetail', 'id', id)
            // verify required parameter 'meetingsId' is not null or undefined
            assertParamExists('fetchAttendMeetingDetail', 'meetingsId', meetingsId)
            const localVarPath = `/api/conference/members/{id}/meetings/{meetingsId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"meetingsId"}}`, encodeURIComponent(String(meetingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を取得する
         * @summary 指定した学術集会･セミナーの参加者情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} entryMeetingsId 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingDetail: async (id: number, entryMeetingsId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEntryMeetingDetail', 'id', id)
            // verify required parameter 'entryMeetingsId' is not null or undefined
            assertParamExists('fetchEntryMeetingDetail', 'entryMeetingsId', entryMeetingsId)
            const localVarPath = `/api/conference/meetings/{id}/entryMeetingMembers/{entryMeetingsId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"entryMeetingsId"}}`, encodeURIComponent(String(entryMeetingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者一覧を取得する
         * @summary 指定した学術集会･セミナーの参加者一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingMembers: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEntryMeetingMembers', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}/entryMeetingMembers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingOverviews1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEntryMeetingOverviews1', 'id', id)
            const localVarPath = `/api/conference/members/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryMeetingCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importEntryMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}/entryMeetingMembers/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をMAのCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をMAのCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMaEntryMeetingCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importMaEntryMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}/entryMeetingMembers/import/ma`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を更新・追加する
         * @summary 指定した学術集会･セミナーの参加者情報の更新・追加
         * @param {EntryMeetingDetail} entryMeetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryMeeting: async (entryMeetingDetail: EntryMeetingDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryMeetingDetail' is not null or undefined
            assertParamExists('updateEntryMeeting', 'entryMeetingDetail', entryMeetingDetail)
            const localVarPath = `/api/conference/entryMeetingMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryMeetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntryMeetingRestControllerApi - functional programming interface
 * @export
 */
export const EntryMeetingRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntryMeetingRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した学術集会･セミナーの参加者情報を削除する
         * @summary 指定した学術集会･セミナーの参加者情報の削除
         * @param {number} id 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntryMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntryMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの複数の参加者情報を削除する
         * @summary 指定した学術集会･セミナーの複数の参加者情報の削除
         * @param {Array<number>} requestBody 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntryMeetingMembers(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntryMeetingMembers(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVにエクスポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVにエクスポート
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEntryMeetingCsv(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEntryMeetingCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAttendMeetingDetail(id: number, meetingsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAttendMeetingDetail(id, meetingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を取得する
         * @summary 指定した学術集会･セミナーの参加者情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} entryMeetingsId 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryMeetingDetail(id: number, entryMeetingsId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryMeetingDetail(id, entryMeetingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者一覧を取得する
         * @summary 指定した学術集会･セミナーの参加者一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryMeetingMembers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEntryMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryMeetingMembers(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryMeetingOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEntryMeetingOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryMeetingOverviews1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEntryMeetingCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEntryMeetingCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をMAのCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をMAのCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importMaEntryMeetingCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importMaEntryMeetingCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を更新・追加する
         * @summary 指定した学術集会･セミナーの参加者情報の更新・追加
         * @param {EntryMeetingDetail} entryMeetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEntryMeeting(entryMeetingDetail: EntryMeetingDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntryMeeting(entryMeetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntryMeetingRestControllerApi - factory interface
 * @export
 */
export const EntryMeetingRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntryMeetingRestControllerApiFp(configuration)
    return {
        /**
         * 指定した学術集会･セミナーの参加者情報を削除する
         * @summary 指定した学術集会･セミナーの参加者情報の削除
         * @param {number} id 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntryMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEntryMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの複数の参加者情報を削除する
         * @summary 指定した学術集会･セミナーの複数の参加者情報の削除
         * @param {Array<number>} requestBody 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntryMeetingMembers(requestBody: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEntryMeetingMembers(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVにエクスポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVにエクスポート
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryMeetingCsv(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportEntryMeetingCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAttendMeetingDetail(id: number, meetingsId: number, options?: any): AxiosPromise<MeetingDetail> {
            return localVarFp.fetchAttendMeetingDetail(id, meetingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を取得する
         * @summary 指定した学術集会･セミナーの参加者情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} entryMeetingsId 学術集会･セミナー参加情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingDetail(id: number, entryMeetingsId: number, options?: any): AxiosPromise<EntryMeetingDetail> {
            return localVarFp.fetchEntryMeetingDetail(id, entryMeetingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者一覧を取得する
         * @summary 指定した学術集会･セミナーの参加者一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingMembers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEntryMeetingDetail> {
            return localVarFp.fetchEntryMeetingMembers(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryMeetingOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEntryMeetingOverview> {
            return localVarFp.fetchEntryMeetingOverviews1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryMeetingCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importEntryMeetingCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者情報をMAのCSVからインポートする
         * @summary 指定した学術集会･セミナーの参加者情報をMAのCSVからインポート
         * @param {number} id 学術集会･セミナーID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importMaEntryMeetingCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importMaEntryMeetingCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学術集会･セミナーの参加者情報を更新・追加する
         * @summary 指定した学術集会･セミナーの参加者情報の更新・追加
         * @param {EntryMeetingDetail} entryMeetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryMeeting(entryMeetingDetail: EntryMeetingDetail, options?: any): AxiosPromise<EntryMeetingDetail> {
            return localVarFp.updateEntryMeeting(entryMeetingDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntryMeetingRestControllerApi - object-oriented interface
 * @export
 * @class EntryMeetingRestControllerApi
 * @extends {BaseAPI}
 */
export class EntryMeetingRestControllerApi extends BaseAPI {
    /**
     * 指定した学術集会･セミナーの参加者情報を削除する
     * @summary 指定した学術集会･セミナーの参加者情報の削除
     * @param {number} id 学術集会･セミナー参加情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public deleteEntryMeeting(id: number, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).deleteEntryMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの複数の参加者情報を削除する
     * @summary 指定した学術集会･セミナーの複数の参加者情報の削除
     * @param {Array<number>} requestBody 学術集会･セミナー参加情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public deleteEntryMeetingMembers(requestBody: Array<number>, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).deleteEntryMeetingMembers(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者情報をCSVにエクスポートする
     * @summary 指定した学術集会･セミナーの参加者情報をCSVにエクスポート
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public exportEntryMeetingCsv(id: number, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).exportEntryMeetingCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} meetingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public fetchAttendMeetingDetail(id: number, meetingsId: number, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).fetchAttendMeetingDetail(id, meetingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者情報を取得する
     * @summary 指定した学術集会･セミナーの参加者情報を取得
     * @param {number} id 学術集会･セミナーID
     * @param {number} entryMeetingsId 学術集会･セミナー参加情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public fetchEntryMeetingDetail(id: number, entryMeetingsId: number, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).fetchEntryMeetingDetail(id, entryMeetingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者一覧を取得する
     * @summary 指定した学術集会･セミナーの参加者一覧を取得
     * @param {number} id 学術集会･セミナーID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public fetchEntryMeetingMembers(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).fetchEntryMeetingMembers(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public fetchEntryMeetingOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).fetchEntryMeetingOverviews1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者情報をCSVからインポートする
     * @summary 指定した学術集会･セミナーの参加者情報をCSVからインポート
     * @param {number} id 学術集会･セミナーID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public importEntryMeetingCsv(id: number, csvFile?: any, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).importEntryMeetingCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者情報をMAのCSVからインポートする
     * @summary 指定した学術集会･セミナーの参加者情報をMAのCSVからインポート
     * @param {number} id 学術集会･セミナーID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public importMaEntryMeetingCsv(id: number, csvFile?: any, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).importMaEntryMeetingCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学術集会･セミナーの参加者情報を更新・追加する
     * @summary 指定した学術集会･セミナーの参加者情報の更新・追加
     * @param {EntryMeetingDetail} entryMeetingDetail 学術集会･セミナー詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntryMeetingRestControllerApi
     */
    public updateEntryMeeting(entryMeetingDetail: EntryMeetingDetail, options?: any) {
        return EntryMeetingRestControllerApiFp(this.configuration).updateEntryMeeting(entryMeetingDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobRestControllerApi - axios parameter creator
 * @export
 */
export const JobRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobsDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJobsDetail', 'id', id)
            const localVarPath = `/api/conference/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchJobsDetail', 'id', id)
            const localVarPath = `/api/conference/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetails: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/jobs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobsDetail: async (jobDetail: JobDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobDetail' is not null or undefined
            assertParamExists('updateJobsDetail', 'jobDetail', jobDetail)
            const localVarPath = `/api/conference/jobs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobRestControllerApi - functional programming interface
 * @export
 */
export const JobRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJobsDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJobsDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobsDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchJobsDetails(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageJobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsDetails(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJobsDetail(jobDetail: JobDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJobsDetail(jobDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobRestControllerApi - factory interface
 * @export
 */
export const JobRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobRestControllerApiFp(configuration)
    return {
        /**
         * 指定した職種マスタ詳細を削除する
         * @summary 指定した職種マスタ詳細の削除
         * @param {number} id 職種マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJobsDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteJobsDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ詳細を取得
         * @summary 職種マスタ詳細を取得
         * @param {number} id 職種ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetail(id: number, options?: any): AxiosPromise<JobDetail> {
            return localVarFp.fetchJobsDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ一覧を取得
         * @summary 職種マスタ一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchJobsDetails(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageJobDetail> {
            return localVarFp.fetchJobsDetails(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 職種マスタ詳細を登録・更新する
         * @summary 職種マスタ詳細登録・更新
         * @param {JobDetail} jobDetail 職種マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJobsDetail(jobDetail: JobDetail, options?: any): AxiosPromise<JobDetail> {
            return localVarFp.updateJobsDetail(jobDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobRestControllerApi - object-oriented interface
 * @export
 * @class JobRestControllerApi
 * @extends {BaseAPI}
 */
export class JobRestControllerApi extends BaseAPI {
    /**
     * 指定した職種マスタ詳細を削除する
     * @summary 指定した職種マスタ詳細の削除
     * @param {number} id 職種マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRestControllerApi
     */
    public deleteJobsDetail(id: number, options?: any) {
        return JobRestControllerApiFp(this.configuration).deleteJobsDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ詳細を取得
     * @summary 職種マスタ詳細を取得
     * @param {number} id 職種ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRestControllerApi
     */
    public fetchJobsDetail(id: number, options?: any) {
        return JobRestControllerApiFp(this.configuration).fetchJobsDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ一覧を取得
     * @summary 職種マスタ一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRestControllerApi
     */
    public fetchJobsDetails(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return JobRestControllerApiFp(this.configuration).fetchJobsDetails(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 職種マスタ詳細を登録・更新する
     * @summary 職種マスタ詳細登録・更新
     * @param {JobDetail} jobDetail 職種マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobRestControllerApi
     */
    public updateJobsDetail(jobDetail: JobDetail, options?: any) {
        return JobRestControllerApiFp(this.configuration).updateJobsDetail(jobDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailRestControllerApi - axios parameter creator
 * @export
 */
export const MailRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したメールテンプレートマスタを削除する
         * @summary 指定したメールテンプレートマスタの削除
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplateMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMailTemplateMaster', 'id', id)
            const localVarPath = `/api/conference/mailTemplates/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会のメールテンプレートマスタを取得する
         * @summary 指定した学会のメールテンプレートマスタ取得
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMailTemplateMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMailTemplateMaster', 'id', id)
            const localVarPath = `/api/conference/mailTemplates/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のメールテンプレートマスタ一覧を取得する
         * @summary 学会のメールテンプレートマスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMailTemplateMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/mailTemplates/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会のメールテンプレートマスタを更新・追加する
         * @summary 指定した学会のメールテンプレートマスタの更新・追加
         * @param {MailTemplateDetail} mailTemplateDetail メールテンプレートマスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailTemplateMaster: async (mailTemplateDetail: MailTemplateDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailTemplateDetail' is not null or undefined
            assertParamExists('updateMailTemplateMaster', 'mailTemplateDetail', mailTemplateDetail)
            const localVarPath = `/api/conference/mailTemplates/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailTemplateDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailRestControllerApi - functional programming interface
 * @export
 */
export const MailRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したメールテンプレートマスタを削除する
         * @summary 指定したメールテンプレートマスタの削除
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailTemplateMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailTemplateMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会のメールテンプレートマスタを取得する
         * @summary 指定した学会のメールテンプレートマスタ取得
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMailTemplateMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplateDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMailTemplateMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のメールテンプレートマスタ一覧を取得する
         * @summary 学会のメールテンプレートマスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMailTemplateMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMailTemplateDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMailTemplateMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会のメールテンプレートマスタを更新・追加する
         * @summary 指定した学会のメールテンプレートマスタの更新・追加
         * @param {MailTemplateDetail} mailTemplateDetail メールテンプレートマスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMailTemplateMaster(mailTemplateDetail: MailTemplateDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailTemplateDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMailTemplateMaster(mailTemplateDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailRestControllerApi - factory interface
 * @export
 */
export const MailRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailRestControllerApiFp(configuration)
    return {
        /**
         * 指定したメールテンプレートマスタを削除する
         * @summary 指定したメールテンプレートマスタの削除
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplateMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMailTemplateMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会のメールテンプレートマスタを取得する
         * @summary 指定した学会のメールテンプレートマスタ取得
         * @param {number} id メールテンプレートマスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMailTemplateMaster(id: number, options?: any): AxiosPromise<MailTemplateDetail> {
            return localVarFp.fetchMailTemplateMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のメールテンプレートマスタ一覧を取得する
         * @summary 学会のメールテンプレートマスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMailTemplateMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMailTemplateDetail> {
            return localVarFp.fetchMailTemplateMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会のメールテンプレートマスタを更新・追加する
         * @summary 指定した学会のメールテンプレートマスタの更新・追加
         * @param {MailTemplateDetail} mailTemplateDetail メールテンプレートマスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailTemplateMaster(mailTemplateDetail: MailTemplateDetail, options?: any): AxiosPromise<MailTemplateDetail> {
            return localVarFp.updateMailTemplateMaster(mailTemplateDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailRestControllerApi - object-oriented interface
 * @export
 * @class MailRestControllerApi
 * @extends {BaseAPI}
 */
export class MailRestControllerApi extends BaseAPI {
    /**
     * 指定したメールテンプレートマスタを削除する
     * @summary 指定したメールテンプレートマスタの削除
     * @param {number} id メールテンプレートマスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailRestControllerApi
     */
    public deleteMailTemplateMaster(id: number, options?: any) {
        return MailRestControllerApiFp(this.configuration).deleteMailTemplateMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会のメールテンプレートマスタを取得する
     * @summary 指定した学会のメールテンプレートマスタ取得
     * @param {number} id メールテンプレートマスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailRestControllerApi
     */
    public fetchMailTemplateMaster(id: number, options?: any) {
        return MailRestControllerApiFp(this.configuration).fetchMailTemplateMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のメールテンプレートマスタ一覧を取得する
     * @summary 学会のメールテンプレートマスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailRestControllerApi
     */
    public fetchMailTemplateMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return MailRestControllerApiFp(this.configuration).fetchMailTemplateMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会のメールテンプレートマスタを更新・追加する
     * @summary 指定した学会のメールテンプレートマスタの更新・追加
     * @param {MailTemplateDetail} mailTemplateDetail メールテンプレートマスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailRestControllerApi
     */
    public updateMailTemplateMaster(mailTemplateDetail: MailTemplateDetail, options?: any) {
        return MailRestControllerApiFp(this.configuration).updateMailTemplateMaster(mailTemplateDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalSpecialtyCategoryRestControllerApi - axios parameter creator
 * @export
 */
export const MedicalSpecialtyCategoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalSpecialtyCategoryDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMedicalSpecialtyCategoryDetail', 'id', id)
            const localVarPath = `/api/conference/medicalSpecialtyCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMedicalSpecialtyCategoryDetail', 'id', id)
            const localVarPath = `/api/conference/medicalSpecialtyCategories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetails: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/medicalSpecialtyCategories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalSpecialtyCategoryDetail: async (medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'medicalSpecialtyCategoryDetail' is not null or undefined
            assertParamExists('updateMedicalSpecialtyCategoryDetail', 'medicalSpecialtyCategoryDetail', medicalSpecialtyCategoryDetail)
            const localVarPath = `/api/conference/medicalSpecialtyCategories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medicalSpecialtyCategoryDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalSpecialtyCategoryRestControllerApi - functional programming interface
 * @export
 */
export const MedicalSpecialtyCategoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalSpecialtyCategoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMedicalSpecialtyCategoryDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMedicalSpecialtyCategoryDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMedicalSpecialtyCategoryDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMedicalSpecialtyCategoryDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMedicalSpecialtyCategoryDetails(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMedicalSpecialtyCategoryDetails(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalSpecialtyCategoryDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalSpecialtyCategoryRestControllerApi - factory interface
 * @export
 */
export const MedicalSpecialtyCategoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalSpecialtyCategoryRestControllerApiFp(configuration)
    return {
        /**
         * 指定した基本領域マスタ詳細を削除する
         * @summary 指定した基本領域マスタ詳細の削除
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMedicalSpecialtyCategoryDetail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMedicalSpecialtyCategoryDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ詳細を取得する
         * @summary 基本領域マスタ詳細を取得
         * @param {number} id 基本領域マスタ情報ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetail(id: number, options?: any): AxiosPromise<MedicalSpecialtyCategoryDetail> {
            return localVarFp.fetchMedicalSpecialtyCategoryDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ一覧を取得する
         * @summary 基本領域マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMedicalSpecialtyCategoryDetails(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMedicalSpecialtyCategoryDetail> {
            return localVarFp.fetchMedicalSpecialtyCategoryDetails(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本領域マスタ詳細を登録・更新する
         * @summary 基本領域マスタ詳細登録・更新
         * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any): AxiosPromise<MedicalSpecialtyCategoryDetail> {
            return localVarFp.updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalSpecialtyCategoryRestControllerApi - object-oriented interface
 * @export
 * @class MedicalSpecialtyCategoryRestControllerApi
 * @extends {BaseAPI}
 */
export class MedicalSpecialtyCategoryRestControllerApi extends BaseAPI {
    /**
     * 指定した基本領域マスタ詳細を削除する
     * @summary 指定した基本領域マスタ詳細の削除
     * @param {number} id 基本領域マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalSpecialtyCategoryRestControllerApi
     */
    public deleteMedicalSpecialtyCategoryDetail(id: number, options?: any) {
        return MedicalSpecialtyCategoryRestControllerApiFp(this.configuration).deleteMedicalSpecialtyCategoryDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ詳細を取得する
     * @summary 基本領域マスタ詳細を取得
     * @param {number} id 基本領域マスタ情報ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalSpecialtyCategoryRestControllerApi
     */
    public fetchMedicalSpecialtyCategoryDetail(id: number, options?: any) {
        return MedicalSpecialtyCategoryRestControllerApiFp(this.configuration).fetchMedicalSpecialtyCategoryDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ一覧を取得する
     * @summary 基本領域マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalSpecialtyCategoryRestControllerApi
     */
    public fetchMedicalSpecialtyCategoryDetails(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return MedicalSpecialtyCategoryRestControllerApiFp(this.configuration).fetchMedicalSpecialtyCategoryDetails(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本領域マスタ詳細を登録・更新する
     * @summary 基本領域マスタ詳細登録・更新
     * @param {MedicalSpecialtyCategoryDetail} medicalSpecialtyCategoryDetail 基本領域マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalSpecialtyCategoryRestControllerApi
     */
    public updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail: MedicalSpecialtyCategoryDetail, options?: any) {
        return MedicalSpecialtyCategoryRestControllerApiFp(this.configuration).updateMedicalSpecialtyCategoryDetail(medicalSpecialtyCategoryDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeetingRestControllerApi - axios parameter creator
 * @export
 */
export const MeetingRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した学術集会･セミナー情報を削除する
         * @summary 指定した学術集会･セミナー情報の削除
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMeeting', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーのファイルを削除する
         * @summary 学術集会･セミナーのファイルを削除
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('deleteMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の学術集会･セミナー情報を取得する
         * @summary 指定した学会の学術集会･セミナー情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMeeting', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーのファイルURLを取得する
         * @summary 学術集会･セミナーのファイルURLを取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('fetchMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMeetingFiles', 'id', id)
            const localVarPath = `/api/conference/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の学術集会･セミナー一覧を取得する
         * @summary 学会の学術集会･セミナー一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetings: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の学術集会･セミナー情報を更新・追加する
         * @summary 指定した学会の学術集会･セミナー情報の更新・追加
         * @param {MeetingDetail} meetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting: async (meetingDetail: MeetingDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingDetail' is not null or undefined
            assertParamExists('updateMeeting', 'meetingDetail', meetingDetail)
            const localVarPath = `/api/conference/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学術集会･セミナーのファイルをアップロードする
         * @summary 学術集会･セミナーのファイルをアップロード
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMeetingFile: async (id: number, fileNo: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('uploadMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingRestControllerApi - functional programming interface
 * @export
 */
export const MeetingRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeetingRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した学術集会･セミナー情報を削除する
         * @summary 指定した学術集会･セミナー情報の削除
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーのファイルを削除する
         * @summary 学術集会･セミナーのファイルを削除
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の学術集会･セミナー情報を取得する
         * @summary 指定した学会の学術集会･セミナー情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーのファイルURLを取得する
         * @summary 学術集会･セミナーのファイルURLを取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の学術集会･セミナー一覧を取得する
         * @summary 学会の学術集会･セミナー一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMeetings(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMeetings(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の学術集会･セミナー情報を更新・追加する
         * @summary 指定した学会の学術集会･セミナー情報の更新・追加
         * @param {MeetingDetail} meetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeeting(meetingDetail: MeetingDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeeting(meetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学術集会･セミナーのファイルをアップロードする
         * @summary 学術集会･セミナーのファイルをアップロード
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMeetingFile(id: number, fileNo: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMeetingFile(id, fileNo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeetingRestControllerApi - factory interface
 * @export
 */
export const MeetingRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeetingRestControllerApiFp(configuration)
    return {
        /**
         * 指定した学術集会･セミナー情報を削除する
         * @summary 指定した学術集会･セミナー情報の削除
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーのファイルを削除する
         * @summary 学術集会･セミナーのファイルを削除
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の学術集会･セミナー情報を取得する
         * @summary 指定した学会の学術集会･セミナー情報を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeeting(id: number, options?: any): AxiosPromise<MeetingDetail> {
            return localVarFp.fetchMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーのファイルURLを取得する
         * @summary 学術集会･セミナーのファイルURLを取得
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーのファイルURL一覧を取得する
         * @summary 学術集会･セミナーのファイルURL一覧を取得
         * @param {number} id 学術集会･セミナーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の学術集会･セミナー一覧を取得する
         * @summary 学会の学術集会･セミナー一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMeetings(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMeetingDetail> {
            return localVarFp.fetchMeetings(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の学術集会･セミナー情報を更新・追加する
         * @summary 指定した学会の学術集会･セミナー情報の更新・追加
         * @param {MeetingDetail} meetingDetail 学術集会･セミナー詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting(meetingDetail: MeetingDetail, options?: any): AxiosPromise<MeetingDetail> {
            return localVarFp.updateMeeting(meetingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 学術集会･セミナーのファイルをアップロードする
         * @summary 学術集会･セミナーのファイルをアップロード
         * @param {number} id 学術集会･セミナーID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMeetingFile(id: number, fileNo: number, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadMeetingFile(id, fileNo, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeetingRestControllerApi - object-oriented interface
 * @export
 * @class MeetingRestControllerApi
 * @extends {BaseAPI}
 */
export class MeetingRestControllerApi extends BaseAPI {
    /**
     * 指定した学術集会･セミナー情報を削除する
     * @summary 指定した学術集会･セミナー情報の削除
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public deleteMeeting(id: number, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).deleteMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーのファイルを削除する
     * @summary 学術集会･セミナーのファイルを削除
     * @param {number} id 学術集会･セミナーID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public deleteMeetingFile(id: number, fileNo: number, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).deleteMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の学術集会･セミナー情報を取得する
     * @summary 指定した学会の学術集会･セミナー情報を取得
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public fetchMeeting(id: number, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).fetchMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーのファイルURLを取得する
     * @summary 学術集会･セミナーのファイルURLを取得
     * @param {number} id 学術集会･セミナーID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public fetchMeetingFile(id: number, fileNo: number, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).fetchMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーのファイルURL一覧を取得する
     * @summary 学術集会･セミナーのファイルURL一覧を取得
     * @param {number} id 学術集会･セミナーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public fetchMeetingFiles(id: number, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).fetchMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の学術集会･セミナー一覧を取得する
     * @summary 学会の学術集会･セミナー一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public fetchMeetings(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).fetchMeetings(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の学術集会･セミナー情報を更新・追加する
     * @summary 指定した学会の学術集会･セミナー情報の更新・追加
     * @param {MeetingDetail} meetingDetail 学術集会･セミナー詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public updateMeeting(meetingDetail: MeetingDetail, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).updateMeeting(meetingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学術集会･セミナーのファイルをアップロードする
     * @summary 学術集会･セミナーのファイルをアップロード
     * @param {number} id 学術集会･セミナーID
     * @param {number} fileNo ファイル番号
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingRestControllerApi
     */
    public uploadMeetingFile(id: number, fileNo: number, file?: any, options?: any) {
        return MeetingRestControllerApiFp(this.configuration).uploadMeetingFile(id, fileNo, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberTypeRestControllerApi - axios parameter creator
 * @export
 */
export const MemberTypeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した会員･会費種別マスタを削除する
         * @summary 指定した会員･会費種別マスタの削除
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberTypeMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMemberTypeMaster', 'id', id)
            const localVarPath = `/api/conference/memberTypes/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の会員･会費種別マスタを取得する
         * @summary 指定した学会の会員･会費種別マスタ取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberTypeMaster', 'id', id)
            const localVarPath = `/api/conference/memberTypes/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の会員･会費種別マスタ一覧を取得する
         * @summary 学会の会員･会費種別マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/memberTypes/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した会員･会費種別名称を取得する
         * @summary 指定した会員･会費種別名称の取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeName: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchMemberTypeName', 'id', id)
            const localVarPath = `/api/conference/memberTypes/name/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の会員･会費種別マスタを更新・追加する
         * @summary 指定した学会の会員･会費種別マスタの更新・追加
         * @param {MemberTypeDetail} memberTypeDetail 会員･会費種別マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberTypeMaster: async (memberTypeDetail: MemberTypeDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberTypeDetail' is not null or undefined
            assertParamExists('updateMemberTypeMaster', 'memberTypeDetail', memberTypeDetail)
            const localVarPath = `/api/conference/memberTypes/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberTypeDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberTypeRestControllerApi - functional programming interface
 * @export
 */
export const MemberTypeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberTypeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した会員･会費種別マスタを削除する
         * @summary 指定した会員･会費種別マスタの削除
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemberTypeMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemberTypeMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の会員･会費種別マスタを取得する
         * @summary 指定した学会の会員･会費種別マスタ取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberTypeMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberTypeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberTypeMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の会員･会費種別マスタ一覧を取得する
         * @summary 学会の会員･会費種別マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberTypeMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMemberTypeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberTypeMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した会員･会費種別名称を取得する
         * @summary 指定した会員･会費種別名称の取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchMemberTypeName(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchMemberTypeName(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の会員･会費種別マスタを更新・追加する
         * @summary 指定した学会の会員･会費種別マスタの更新・追加
         * @param {MemberTypeDetail} memberTypeDetail 会員･会費種別マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberTypeMaster(memberTypeDetail: MemberTypeDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberTypeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberTypeMaster(memberTypeDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberTypeRestControllerApi - factory interface
 * @export
 */
export const MemberTypeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberTypeRestControllerApiFp(configuration)
    return {
        /**
         * 指定した会員･会費種別マスタを削除する
         * @summary 指定した会員･会費種別マスタの削除
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemberTypeMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteMemberTypeMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の会員･会費種別マスタを取得する
         * @summary 指定した学会の会員･会費種別マスタ取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeMaster(id: number, options?: any): AxiosPromise<MemberTypeDetail> {
            return localVarFp.fetchMemberTypeMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の会員･会費種別マスタ一覧を取得する
         * @summary 学会の会員･会費種別マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMemberTypeDetail> {
            return localVarFp.fetchMemberTypeMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した会員･会費種別名称を取得する
         * @summary 指定した会員･会費種別名称の取得
         * @param {number} id 会員･会費種別マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchMemberTypeName(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.fetchMemberTypeName(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の会員･会費種別マスタを更新・追加する
         * @summary 指定した学会の会員･会費種別マスタの更新・追加
         * @param {MemberTypeDetail} memberTypeDetail 会員･会費種別マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberTypeMaster(memberTypeDetail: MemberTypeDetail, options?: any): AxiosPromise<MemberTypeDetail> {
            return localVarFp.updateMemberTypeMaster(memberTypeDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberTypeRestControllerApi - object-oriented interface
 * @export
 * @class MemberTypeRestControllerApi
 * @extends {BaseAPI}
 */
export class MemberTypeRestControllerApi extends BaseAPI {
    /**
     * 指定した会員･会費種別マスタを削除する
     * @summary 指定した会員･会費種別マスタの削除
     * @param {number} id 会員･会費種別マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberTypeRestControllerApi
     */
    public deleteMemberTypeMaster(id: number, options?: any) {
        return MemberTypeRestControllerApiFp(this.configuration).deleteMemberTypeMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の会員･会費種別マスタを取得する
     * @summary 指定した学会の会員･会費種別マスタ取得
     * @param {number} id 会員･会費種別マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberTypeRestControllerApi
     */
    public fetchMemberTypeMaster(id: number, options?: any) {
        return MemberTypeRestControllerApiFp(this.configuration).fetchMemberTypeMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の会員･会費種別マスタ一覧を取得する
     * @summary 学会の会員･会費種別マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberTypeRestControllerApi
     */
    public fetchMemberTypeMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return MemberTypeRestControllerApiFp(this.configuration).fetchMemberTypeMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した会員･会費種別名称を取得する
     * @summary 指定した会員･会費種別名称の取得
     * @param {number} id 会員･会費種別マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberTypeRestControllerApi
     */
    public fetchMemberTypeName(id: number, options?: any) {
        return MemberTypeRestControllerApiFp(this.configuration).fetchMemberTypeName(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の会員･会費種別マスタを更新・追加する
     * @summary 指定した学会の会員･会費種別マスタの更新・追加
     * @param {MemberTypeDetail} memberTypeDetail 会員･会費種別マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberTypeRestControllerApi
     */
    public updateMemberTypeMaster(memberTypeDetail: MemberTypeDetail, options?: any) {
        return MemberTypeRestControllerApiFp(this.configuration).updateMemberTypeMaster(memberTypeDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficerMeetingRestControllerApi - axios parameter creator
 * @export
 */
export const OfficerMeetingRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した理事･役員会開催詳細を削除する
         * @summary 指定した理事･役員会開催詳細の削除
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOfficerMeeting', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 理事･役員会会議のファイルを削除する
         * @summary 理事･役員会会議のファイルを削除
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOfficerMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('deleteOfficerMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/officers/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryOfficerMeetingCsv: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEntryOfficerMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}/entryMeetingMembers/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を取得する
         * @summary 指定した理事・役員会開催の出席者一覧を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryOfficerMeetingDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEntryOfficerMeetingDetails', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}/entryMeetingMembers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMeetingDetail', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の理事･役員会開催一覧を取得する
         * @summary 学会の理事･役員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingDetails: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/officers/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 理事･役員会会議のファイルURLを取得する
         * @summary 理事･役員会会議のファイルURLを取得
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('fetchOfficerMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/officers/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMeetingFiles', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVからインポート
         * @param {number} id 理事･役員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryOfficerMeetingCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importEntryOfficerMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/officers/meetings/{id}/entryMeetingMembers/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を更新する
         * @summary 指定した理事・役員会開催の出席者一覧の更新
         * @param {number} id 理事・役員会開催ID
         * @param {Array<EntryOfficerMeetingDetail>} entryOfficerMeetingDetail 理事・役員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryOfficerMeeting: async (id: number, entryOfficerMeetingDetail: Array<EntryOfficerMeetingDetail>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEntryOfficerMeeting', 'id', id)
            // verify required parameter 'entryOfficerMeetingDetail' is not null or undefined
            assertParamExists('updateEntryOfficerMeeting', 'entryOfficerMeetingDetail', entryOfficerMeetingDetail)
            const localVarPath = `/api/conference/officers/meetings/{id}/entryMeetingMembers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryOfficerMeetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会開催詳細を更新・追加する
         * @summary 指定した理事･役員会開催詳細の更新・追加
         * @param {OfficerMeetingDetail} officerMeetingDetail 理事･役員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerMeeting: async (officerMeetingDetail: OfficerMeetingDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'officerMeetingDetail' is not null or undefined
            assertParamExists('updateOfficerMeeting', 'officerMeetingDetail', officerMeetingDetail)
            const localVarPath = `/api/conference/officers/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officerMeetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 理事･役員会会議のファイルをアップロードする
         * @summary 理事･役員会会議のファイルをアップロード
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOfficerMeetingFile: async (id: number, fileNo: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadOfficerMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('uploadOfficerMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/officers/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficerMeetingRestControllerApi - functional programming interface
 * @export
 */
export const OfficerMeetingRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficerMeetingRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した理事･役員会開催詳細を削除する
         * @summary 指定した理事･役員会開催詳細の削除
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfficerMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfficerMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 理事･役員会会議のファイルを削除する
         * @summary 理事･役員会会議のファイルを削除
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfficerMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfficerMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEntryOfficerMeetingCsv(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEntryOfficerMeetingCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を取得する
         * @summary 指定した理事・役員会開催の出席者一覧を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryOfficerMeetingDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntryOfficerMeetingDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryOfficerMeetingDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の理事･役員会開催一覧を取得する
         * @summary 学会の理事･役員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMeetingDetails(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 理事･役員会会議のファイルURLを取得する
         * @summary 理事･役員会会議のファイルURLを取得
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVからインポート
         * @param {number} id 理事･役員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEntryOfficerMeetingCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEntryOfficerMeetingCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を更新する
         * @summary 指定した理事・役員会開催の出席者一覧の更新
         * @param {number} id 理事・役員会開催ID
         * @param {Array<EntryOfficerMeetingDetail>} entryOfficerMeetingDetail 理事・役員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEntryOfficerMeeting(id: number, entryOfficerMeetingDetail: Array<EntryOfficerMeetingDetail>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntryOfficerMeeting(id, entryOfficerMeetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会開催詳細を更新・追加する
         * @summary 指定した理事･役員会開催詳細の更新・追加
         * @param {OfficerMeetingDetail} officerMeetingDetail 理事･役員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfficerMeeting(officerMeetingDetail: OfficerMeetingDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfficerMeeting(officerMeetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 理事･役員会会議のファイルをアップロードする
         * @summary 理事･役員会会議のファイルをアップロード
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadOfficerMeetingFile(id: number, fileNo: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadOfficerMeetingFile(id, fileNo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficerMeetingRestControllerApi - factory interface
 * @export
 */
export const OfficerMeetingRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficerMeetingRestControllerApiFp(configuration)
    return {
        /**
         * 指定した理事･役員会開催詳細を削除する
         * @summary 指定した理事･役員会開催詳細の削除
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOfficerMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 理事･役員会会議のファイルを削除する
         * @summary 理事･役員会会議のファイルを削除
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOfficerMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryOfficerMeetingCsv(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportEntryOfficerMeetingCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を取得する
         * @summary 指定した理事・役員会開催の出席者一覧を取得
         * @param {number} id 理事・役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryOfficerMeetingDetails(id: number, options?: any): AxiosPromise<Array<EntryOfficerMeetingDetail>> {
            return localVarFp.fetchEntryOfficerMeetingDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会開催詳細を取得する
         * @summary 指定した理事･役員会開催詳細を取得
         * @param {number} id 理事･役員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingDetail(id: number, options?: any): AxiosPromise<OfficerMeetingDetail> {
            return localVarFp.fetchOfficerMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の理事･役員会開催一覧を取得する
         * @summary 学会の理事･役員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerMeetingDetail> {
            return localVarFp.fetchOfficerMeetingDetails(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 理事･役員会会議のファイルURLを取得する
         * @summary 理事･役員会会議のファイルURLを取得
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchOfficerMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 理事･役員会会議のファイルURL一覧を取得する
         * @summary 理事･役員会会議のファイルURL一覧を取得
         * @param {number} id 理事･役員会会議ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchOfficerMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した理事･役員会開催の出席者一覧をCSVからインポート
         * @param {number} id 理事･役員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryOfficerMeetingCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importEntryOfficerMeetingCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事・役員会開催の出席者一覧を更新する
         * @summary 指定した理事・役員会開催の出席者一覧の更新
         * @param {number} id 理事・役員会開催ID
         * @param {Array<EntryOfficerMeetingDetail>} entryOfficerMeetingDetail 理事・役員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryOfficerMeeting(id: number, entryOfficerMeetingDetail: Array<EntryOfficerMeetingDetail>, options?: any): AxiosPromise<string> {
            return localVarFp.updateEntryOfficerMeeting(id, entryOfficerMeetingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会開催詳細を更新・追加する
         * @summary 指定した理事･役員会開催詳細の更新・追加
         * @param {OfficerMeetingDetail} officerMeetingDetail 理事･役員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerMeeting(officerMeetingDetail: OfficerMeetingDetail, options?: any): AxiosPromise<OfficerMeetingDetail> {
            return localVarFp.updateOfficerMeeting(officerMeetingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 理事･役員会会議のファイルをアップロードする
         * @summary 理事･役員会会議のファイルをアップロード
         * @param {number} id 理事･役員会会議ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOfficerMeetingFile(id: number, fileNo: number, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadOfficerMeetingFile(id, fileNo, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficerMeetingRestControllerApi - object-oriented interface
 * @export
 * @class OfficerMeetingRestControllerApi
 * @extends {BaseAPI}
 */
export class OfficerMeetingRestControllerApi extends BaseAPI {
    /**
     * 指定した理事･役員会開催詳細を削除する
     * @summary 指定した理事･役員会開催詳細の削除
     * @param {number} id 理事･役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public deleteOfficerMeeting(id: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).deleteOfficerMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 理事･役員会会議のファイルを削除する
     * @summary 理事･役員会会議のファイルを削除
     * @param {number} id 理事･役員会会議ID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public deleteOfficerMeetingFile(id: number, fileNo: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).deleteOfficerMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会開催の出席者一覧をCSVにエクスポートする
     * @summary 指定した理事･役員会開催の出席者一覧をCSVにエクスポート
     * @param {number} id 理事･役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public exportEntryOfficerMeetingCsv(id: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).exportEntryOfficerMeetingCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事・役員会開催の出席者一覧を取得する
     * @summary 指定した理事・役員会開催の出席者一覧を取得
     * @param {number} id 理事・役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public fetchEntryOfficerMeetingDetails(id: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).fetchEntryOfficerMeetingDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会開催詳細を取得する
     * @summary 指定した理事･役員会開催詳細を取得
     * @param {number} id 理事･役員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public fetchOfficerMeetingDetail(id: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).fetchOfficerMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の理事･役員会開催一覧を取得する
     * @summary 学会の理事･役員会開催一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public fetchOfficerMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).fetchOfficerMeetingDetails(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 理事･役員会会議のファイルURLを取得する
     * @summary 理事･役員会会議のファイルURLを取得
     * @param {number} id 理事･役員会会議ID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public fetchOfficerMeetingFile(id: number, fileNo: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).fetchOfficerMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 理事･役員会会議のファイルURL一覧を取得する
     * @summary 理事･役員会会議のファイルURL一覧を取得
     * @param {number} id 理事･役員会会議ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public fetchOfficerMeetingFiles(id: number, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).fetchOfficerMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会開催の出席者一覧をCSVからインポートする
     * @summary 指定した理事･役員会開催の出席者一覧をCSVからインポート
     * @param {number} id 理事･役員会開催ID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public importEntryOfficerMeetingCsv(id: number, csvFile?: any, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).importEntryOfficerMeetingCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事・役員会開催の出席者一覧を更新する
     * @summary 指定した理事・役員会開催の出席者一覧の更新
     * @param {number} id 理事・役員会開催ID
     * @param {Array<EntryOfficerMeetingDetail>} entryOfficerMeetingDetail 理事・役員会出席者詳細リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public updateEntryOfficerMeeting(id: number, entryOfficerMeetingDetail: Array<EntryOfficerMeetingDetail>, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).updateEntryOfficerMeeting(id, entryOfficerMeetingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会開催詳細を更新・追加する
     * @summary 指定した理事･役員会開催詳細の更新・追加
     * @param {OfficerMeetingDetail} officerMeetingDetail 理事･役員会開催詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public updateOfficerMeeting(officerMeetingDetail: OfficerMeetingDetail, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).updateOfficerMeeting(officerMeetingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 理事･役員会会議のファイルをアップロードする
     * @summary 理事･役員会会議のファイルをアップロード
     * @param {number} id 理事･役員会会議ID
     * @param {number} fileNo ファイル番号
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerMeetingRestControllerApi
     */
    public uploadOfficerMeetingFile(id: number, fileNo: number, file?: any, options?: any) {
        return OfficerMeetingRestControllerApiFp(this.configuration).uploadOfficerMeetingFile(id, fileNo, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficerRestControllerApi - axios parameter creator
 * @export
 */
export const OfficerRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した理事･役員会マスタを削除する
         * @summary 指定した理事･役員会マスタの削除
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOfficerMaster', 'id', id)
            const localVarPath = `/api/conference/officers/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会名簿詳細を削除する
         * @summary 指定した理事･役員会名簿詳細の削除
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerQualification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOfficerQualification', 'id', id)
            const localVarPath = `/api/conference/officers/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVにエクスポートする
         * @summary 指定した理事･役員会の参加者情報をCSVにエクスポート
         * @param {number} id 理事･役員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOfficerQualificationCsv: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportOfficerQualificationCsv', 'id', id)
            const localVarPath = `/api/conference/officers/qualifications/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMaster', 'id', id)
            const localVarPath = `/api/conference/officers/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasterMeetings: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMasterMeetings', 'id', id)
            const localVarPath = `/api/conference/officers/masters/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasterQualifications: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerMasterQualifications', 'id', id)
            const localVarPath = `/api/conference/officers/masters/{id}/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の理事･役員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/officers/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿詳細を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿詳細を取得
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerQualification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerQualification', 'id', id)
            const localVarPath = `/api/conference/officers/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerQualificationOverviews: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchOfficerQualificationOverviews', 'id', id)
            const localVarPath = `/api/conference/members/{id}/officers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVからインポートする
         * @summary 指定した理事･役員会の参加者情報をCSVからインポート
         * @param {number} id 理事･役員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOfficerQualificationCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importOfficerQualificationCsv', 'id', id)
            const localVarPath = `/api/conference/officers/qualifications/{id}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会マスタを更新・追加する
         * @summary 指定した学会の理事･役員会マスタの更新・追加
         * @param {OfficerDetail} officerDetail 理事･役員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerMaster: async (officerDetail: OfficerDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'officerDetail' is not null or undefined
            assertParamExists('updateOfficerMaster', 'officerDetail', officerDetail)
            const localVarPath = `/api/conference/officers/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officerDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の理事･役員会名簿詳細を更新・追加する
         * @summary 指定した学会の理事･役員会名簿詳細の更新・追加
         * @param {OfficerQualificationDetail} officerQualificationDetail 理事･役員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerQualification: async (officerQualificationDetail: OfficerQualificationDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'officerQualificationDetail' is not null or undefined
            assertParamExists('updateOfficerQualification', 'officerQualificationDetail', officerQualificationDetail)
            const localVarPath = `/api/conference/officers/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(officerQualificationDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficerRestControllerApi - functional programming interface
 * @export
 */
export const OfficerRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficerRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した理事･役員会マスタを削除する
         * @summary 指定した理事･役員会マスタの削除
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfficerMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfficerMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会名簿詳細を削除する
         * @summary 指定した理事･役員会名簿詳細の削除
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfficerQualification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOfficerQualification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVにエクスポートする
         * @summary 指定した理事･役員会の参加者情報をCSVにエクスポート
         * @param {number} id 理事･役員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportOfficerQualificationCsv(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportOfficerQualificationCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMasterMeetings(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMasterQualifications(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の理事･役員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿詳細を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿詳細を取得
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerQualification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerQualification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchOfficerQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfficerQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchOfficerQualificationOverviews(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVからインポートする
         * @summary 指定した理事･役員会の参加者情報をCSVからインポート
         * @param {number} id 理事･役員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importOfficerQualificationCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importOfficerQualificationCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会マスタを更新・追加する
         * @summary 指定した学会の理事･役員会マスタの更新・追加
         * @param {OfficerDetail} officerDetail 理事･役員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfficerMaster(officerDetail: OfficerDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfficerMaster(officerDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の理事･役員会名簿詳細を更新・追加する
         * @summary 指定した学会の理事･役員会名簿詳細の更新・追加
         * @param {OfficerQualificationDetail} officerQualificationDetail 理事･役員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfficerQualification(officerQualificationDetail: OfficerQualificationDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficerQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfficerQualification(officerQualificationDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficerRestControllerApi - factory interface
 * @export
 */
export const OfficerRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficerRestControllerApiFp(configuration)
    return {
        /**
         * 指定した理事･役員会マスタを削除する
         * @summary 指定した理事･役員会マスタの削除
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOfficerMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会名簿詳細を削除する
         * @summary 指定した理事･役員会名簿詳細の削除
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfficerQualification(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOfficerQualification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVにエクスポートする
         * @summary 指定した理事･役員会の参加者情報をCSVにエクスポート
         * @param {number} id 理事･役員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOfficerQualificationCsv(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportOfficerQualificationCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会マスタを取得する
         * @summary 指定した学会の理事･役員会マスタ取得
         * @param {number} id 理事･役員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMaster(id: number, options?: any): AxiosPromise<OfficerDetail> {
            return localVarFp.fetchOfficerMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerMeetingDetail> {
            return localVarFp.fetchOfficerMasterMeetings(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿一覧を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿一覧取得
         * @param {number} id 理事･役員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerQualificationDetail> {
            return localVarFp.fetchOfficerMasterQualifications(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の理事･役員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerDetail> {
            return localVarFp.fetchOfficerMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会の理事･役員会名簿詳細を取得する
         * @summary 指定した学会の理事･役員会の理事･役員会名簿詳細を取得
         * @param {number} id 理事･役員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerQualification(id: number, options?: any): AxiosPromise<OfficerQualificationDetail> {
            return localVarFp.fetchOfficerQualification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOfficerQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageOfficerQualificationOverview> {
            return localVarFp.fetchOfficerQualificationOverviews(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した理事･役員会の参加者情報をCSVからインポートする
         * @summary 指定した理事･役員会の参加者情報をCSVからインポート
         * @param {number} id 理事･役員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOfficerQualificationCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importOfficerQualificationCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会マスタを更新・追加する
         * @summary 指定した学会の理事･役員会マスタの更新・追加
         * @param {OfficerDetail} officerDetail 理事･役員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerMaster(officerDetail: OfficerDetail, options?: any): AxiosPromise<OfficerDetail> {
            return localVarFp.updateOfficerMaster(officerDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の理事･役員会名簿詳細を更新・追加する
         * @summary 指定した学会の理事･役員会名簿詳細の更新・追加
         * @param {OfficerQualificationDetail} officerQualificationDetail 理事･役員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficerQualification(officerQualificationDetail: OfficerQualificationDetail, options?: any): AxiosPromise<OfficerQualificationDetail> {
            return localVarFp.updateOfficerQualification(officerQualificationDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficerRestControllerApi - object-oriented interface
 * @export
 * @class OfficerRestControllerApi
 * @extends {BaseAPI}
 */
export class OfficerRestControllerApi extends BaseAPI {
    /**
     * 指定した理事･役員会マスタを削除する
     * @summary 指定した理事･役員会マスタの削除
     * @param {number} id 理事･役員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public deleteOfficerMaster(id: number, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).deleteOfficerMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会名簿詳細を削除する
     * @summary 指定した理事･役員会名簿詳細の削除
     * @param {number} id 理事･役員会名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public deleteOfficerQualification(id: number, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).deleteOfficerQualification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会の参加者情報をCSVにエクスポートする
     * @summary 指定した理事･役員会の参加者情報をCSVにエクスポート
     * @param {number} id 理事･役員会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public exportOfficerQualificationCsv(id: number, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).exportOfficerQualificationCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会マスタを取得する
     * @summary 指定した学会の理事･役員会マスタ取得
     * @param {number} id 理事･役員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerMaster(id: number, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会の理事･役員会開催一覧を取得する
     * @summary 指定した学会の理事･役員会の理事･役員会開催一覧取得
     * @param {number} id 理事･役員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerMasterMeetings(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会の理事･役員会名簿一覧を取得する
     * @summary 指定した学会の理事･役員会の理事･役員会名簿一覧取得
     * @param {number} id 理事･役員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerMasterQualifications(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の理事･役員会マスタ一覧を取得する
     * @summary 学会の理事･役員会マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会の理事･役員会名簿詳細を取得する
     * @summary 指定した学会の理事･役員会の理事･役員会名簿詳細を取得
     * @param {number} id 理事･役員会名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerQualification(id: number, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerQualification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public fetchOfficerQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).fetchOfficerQualificationOverviews(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した理事･役員会の参加者情報をCSVからインポートする
     * @summary 指定した理事･役員会の参加者情報をCSVからインポート
     * @param {number} id 理事･役員会ID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public importOfficerQualificationCsv(id: number, csvFile?: any, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).importOfficerQualificationCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会マスタを更新・追加する
     * @summary 指定した学会の理事･役員会マスタの更新・追加
     * @param {OfficerDetail} officerDetail 理事･役員会マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public updateOfficerMaster(officerDetail: OfficerDetail, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).updateOfficerMaster(officerDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の理事･役員会名簿詳細を更新・追加する
     * @summary 指定した学会の理事･役員会名簿詳細の更新・追加
     * @param {OfficerQualificationDetail} officerQualificationDetail 理事･役員会名簿詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficerRestControllerApi
     */
    public updateOfficerQualification(officerQualificationDetail: OfficerQualificationDetail, options?: any) {
        return OfficerRestControllerApiFp(this.configuration).updateOfficerQualification(officerQualificationDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentRestControllerApi - axios parameter creator
 * @export
 */
export const PaymentRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * コンビニ入金情報をGMOに問い合わせて更新
         * @summary コンビニ入金情報をGMOに問い合わせて更新
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAndUpdateCvsPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkAndUpdateCvsPayment', 'id', id)
            const localVarPath = `/api/conference/payments/{id}/check_cvs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した年会費請求情報を削除する
         * @summary 年会費請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnualFeePayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnnualFeePayment', 'id', id)
            const localVarPath = `/api/conference/payments/annualFees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した複数の年会費請求情報を削除する
         * @summary 指定した複数の年会費請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnualFeePayments: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteAnnualFeePayments', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/annualFees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したその他請求情報を削除する
         * @summary その他請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEtcPayment', 'id', id)
            const localVarPath = `/api/conference/payments/etcs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他請求マスターを削除する
         * @summary その他請求マスター削除
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPaymentMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEtcPaymentMaster', 'id', id)
            const localVarPath = `/api/conference/payments/etcs/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した複数のその他請求情報を削除する
         * @summary 指定した複数のその他請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPayments: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteEtcPayments', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/etcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した請求情報を削除する
         * @summary 請求情報の削除
         * @param {number} id 請求ID
         * @param {'AnnualFee' | 'Qualification' | 'Etc'} paymentContentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentByConference: async (id: number, paymentContentType: 'AnnualFee' | 'Qualification' | 'Etc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePaymentByConference', 'id', id)
            // verify required parameter 'paymentContentType' is not null or undefined
            assertParamExists('deletePaymentByConference', 'paymentContentType', paymentContentType)
            const localVarPath = `/api/conference/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentContentType !== undefined) {
                localVarQueryParameter['paymentContentType'] = paymentContentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した資格請求情報を削除する
         * @summary 資格請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPayment: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQualificationPayment', 'id', id)
            const localVarPath = `/api/conference/payments/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定請求マスターを削除する
         * @summary 資格・認定請求マスター削除
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPaymentMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQualificationPaymentMaster', 'id', id)
            const localVarPath = `/api/conference/payments/qualifications/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した複数の資格請求情報を削除する
         * @summary 指定した複数の資格請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPayments: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteQualificationPayments', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定費領収書を一括で発行する
         * @summary 資格・認定費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAcquisitionQualificationPaymentReceipts: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('downloadAcquisitionQualificationPaymentReceipts', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/qualifications/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 年会費領収書を一括で発行する
         * @summary 年会費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnnualFeesReceipts: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('downloadAnnualFeesReceipts', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/annualFees/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他入金領収書を一括で発行する
         * @summary その他入金領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignEtceteraPaymentReceipts: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('downloadSignEtceteraPaymentReceipts', 'requestBody', requestBody)
            const localVarPath = `/api/conference/payments/etcs/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定入金状態情報をCSVにエクスポートする
         * @summary 資格・認定入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAcquisitionQualificationPaymentCsv: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/qualifications/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 年会費入金状態情報をCSVにエクスポートする
         * @summary 年会費入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAnnualFeeCsv: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/annualFees/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他入金状態情報をCSVにエクスポートする
         * @summary その他入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSignEtceteraPaymentCsv: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/etcs/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeeOverviews: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/annualFees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeePaymentOverviews1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAnnualFeePaymentOverviews1', 'id', id)
            const localVarPath = `/api/conference/members/{id}/payments/annualFees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [billingId] その他請求ID
         * @param {string} [billingDateFrom] 請求開始年月日
         * @param {string} [billingDateTo] 請求終了年月日
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcBillingPersonOverviews: async (userName?: string, numberString?: string, billingId?: number, billingDateFrom?: string, billingDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/etcs/billingPersons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (billingDateFrom !== undefined) {
                localVarQueryParameter['billingDateFrom'] = (billingDateFrom as any instanceof Date) ?
                    (billingDateFrom as any).toISOString().substr(0,10) :
                    billingDateFrom;
            }

            if (billingDateTo !== undefined) {
                localVarQueryParameter['billingDateTo'] = (billingDateTo as any instanceof Date) ?
                    (billingDateTo as any).toISOString().substr(0,10) :
                    billingDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcFeeOverviews: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/etcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の特定のその他請求マスター情報を取得する
         * @summary 指定したその他請求マスター情報取得
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEtcPaymentMaster', 'id', id)
            const localVarPath = `/api/conference/payments/etcs/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のその他請求マスター一覧を取得する
         * @summary その他請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/etcs/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentOverviews1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEtcPaymentOverviews1', 'id', id)
            const localVarPath = `/api/conference/members/{id}/payments/etcs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した請求情報を取得する
         * @summary 請求情報の取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentDetailByConference: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPaymentDetailByConference', 'id', id)
            const localVarPath = `/api/conference/payments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentOverviews: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPaymentOverviews', 'id', id)
            const localVarPath = `/api/conference/members/{id}/payments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {number} [billingId] 資格・認定請求ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationBillingPersonOverviews: async (userName?: string, numberString?: string, workPrefecture?: string, billingId?: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/qualifications/billingPersons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (workPrefecture !== undefined) {
                localVarQueryParameter['workPrefecture'] = workPrefecture;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationFeeOverviews: async (userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (fiscalYear !== undefined) {
                localVarQueryParameter['fiscalYear'] = fiscalYear;
            }

            if (billingId !== undefined) {
                localVarQueryParameter['billingId'] = billingId;
            }

            if (memberStatus !== undefined) {
                localVarQueryParameter['memberStatus'] = memberStatus;
            }

            if (paymentStatus !== undefined) {
                localVarQueryParameter['paymentStatus'] = paymentStatus;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (paymentDateFrom !== undefined) {
                localVarQueryParameter['paymentDateFrom'] = (paymentDateFrom as any instanceof Date) ?
                    (paymentDateFrom as any).toISOString().substr(0,10) :
                    paymentDateFrom;
            }

            if (paymentDateTo !== undefined) {
                localVarQueryParameter['paymentDateTo'] = (paymentDateTo as any instanceof Date) ?
                    (paymentDateTo as any).toISOString().substr(0,10) :
                    paymentDateTo;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の特定の資格・認定請求マスター情報を取得する
         * @summary 指定した資格・認定請求マスター情報取得
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationPaymentMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchQualificationPaymentMaster', 'id', id)
            const localVarPath = `/api/conference/payments/qualifications/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の資格・認定請求マスター一覧を取得する
         * @summary 資格・認定請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationPaymentMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/qualifications/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationsPaymentOverviews1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchQualificationsPaymentOverviews1', 'id', id)
            const localVarPath = `/api/conference/members/{id}/payments/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定入金状態情報をCSVからインポートする
         * @summary 資格・認定入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAcquisitionQualificationPaymentCsv: async (csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/qualifications/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 年会費入金状態情報をCSVからインポートする
         * @summary 年会費入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAnnualFeeCsv: async (csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/annualFees/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他入金状態情報をCSVからインポートする
         * @summary その他入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSignEtceteraPaymentCsv: async (csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/payments/etcs/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 年会費請求情報を追加する
         * @summary 年会費請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnualFeePayment: async (newPaymentParam: NewPaymentParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPaymentParam' is not null or undefined
            assertParamExists('updateAnnualFeePayment', 'newPaymentParam', newPaymentParam)
            const localVarPath = `/api/conference/payments/annualFees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPaymentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他請求情報を追加する
         * @summary その他請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtcPayment: async (newPaymentParam: NewPaymentParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPaymentParam' is not null or undefined
            assertParamExists('updateEtcPayment', 'newPaymentParam', newPaymentParam)
            const localVarPath = `/api/conference/payments/etcs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPaymentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * その他請求マスターを追加・更新する
         * @summary その他請求マスター追加・更新
         * @param {EtcPaymentDetail} etcPaymentDetail その他請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtcPaymentMaster: async (etcPaymentDetail: EtcPaymentDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'etcPaymentDetail' is not null or undefined
            assertParamExists('updateEtcPaymentMaster', 'etcPaymentDetail', etcPaymentDetail)
            const localVarPath = `/api/conference/payments/etcs/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(etcPaymentDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求情報を学会対応の支払い済みで更新する
         * @summary 請求情報を学会対応の支払い済みで更新
         * @param {PaymentOverview} paymentOverview 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentByConference: async (paymentOverview: PaymentOverview, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentOverview' is not null or undefined
            assertParamExists('updatePaymentByConference', 'paymentOverview', paymentOverview)
            const localVarPath = `/api/conference/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentOverview, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定請求情報を追加する
         * @summary 資格・認定請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationPayment: async (newPaymentParam: NewPaymentParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPaymentParam' is not null or undefined
            assertParamExists('updateQualificationPayment', 'newPaymentParam', newPaymentParam)
            const localVarPath = `/api/conference/payments/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPaymentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定請求マスターを追加・更新する
         * @summary 資格・認定請求マスター追加・更新
         * @param {PaymentDetail} paymentDetail 請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationPaymentMaster: async (paymentDetail: PaymentDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentDetail' is not null or undefined
            assertParamExists('updateQualificationPaymentMaster', 'paymentDetail', paymentDetail)
            const localVarPath = `/api/conference/payments/qualifications/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentRestControllerApi - functional programming interface
 * @export
 */
export const PaymentRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * コンビニ入金情報をGMOに問い合わせて更新
         * @summary コンビニ入金情報をGMOに問い合わせて更新
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAndUpdateCvsPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAndUpdateCvsPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した年会費請求情報を削除する
         * @summary 年会費請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnualFeePayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnualFeePayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した複数の年会費請求情報を削除する
         * @summary 指定した複数の年会費請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnualFeePayments(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnualFeePayments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したその他請求情報を削除する
         * @summary その他請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEtcPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEtcPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他請求マスターを削除する
         * @summary その他請求マスター削除
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEtcPaymentMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEtcPaymentMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した複数のその他請求情報を削除する
         * @summary 指定した複数のその他請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEtcPayments(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEtcPayments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した請求情報を削除する
         * @summary 請求情報の削除
         * @param {number} id 請求ID
         * @param {'AnnualFee' | 'Qualification' | 'Etc'} paymentContentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentByConference(id: number, paymentContentType: 'AnnualFee' | 'Qualification' | 'Etc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentByConference(id, paymentContentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した資格請求情報を削除する
         * @summary 資格請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQualificationPayment(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQualificationPayment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定請求マスターを削除する
         * @summary 資格・認定請求マスター削除
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQualificationPaymentMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQualificationPaymentMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した複数の資格請求情報を削除する
         * @summary 指定した複数の資格請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQualificationPayments(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQualificationPayments(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定費領収書を一括で発行する
         * @summary 資格・認定費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAcquisitionQualificationPaymentReceipts(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAcquisitionQualificationPaymentReceipts(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 年会費領収書を一括で発行する
         * @summary 年会費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAnnualFeesReceipts(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAnnualFeesReceipts(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他入金領収書を一括で発行する
         * @summary その他入金領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSignEtceteraPaymentReceipts(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSignEtceteraPaymentReceipts(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定入金状態情報をCSVにエクスポートする
         * @summary 資格・認定入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAcquisitionQualificationPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAcquisitionQualificationPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 年会費入金状態情報をCSVにエクスポートする
         * @summary 年会費入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAnnualFeeCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAnnualFeeCsv(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他入金状態情報をCSVにエクスポートする
         * @summary その他入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportSignEtceteraPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportSignEtceteraPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAnnualFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAnnualFeeOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAnnualFeeOverviews(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAnnualFeePaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAnnualFeePaymentOverviews1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [billingId] その他請求ID
         * @param {string} [billingDateFrom] 請求開始年月日
         * @param {string} [billingDateTo] 請求終了年月日
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcBillingPersonOverviews(userName?: string, numberString?: string, billingId?: number, billingDateFrom?: string, billingDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEtcBillingPersonOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcBillingPersonOverviews(userName, numberString, billingId, billingDateFrom, billingDateTo, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEtcFeeOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の特定のその他請求マスター情報を取得する
         * @summary 指定したその他請求マスター情報取得
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcPaymentMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtcPaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcPaymentMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のその他請求マスター一覧を取得する
         * @summary その他請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageEtcPaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcPaymentMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEtcPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEtcPaymentOverviews1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した請求情報を取得する
         * @summary 請求情報の取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPaymentDetailByConference(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPaymentDetailByConference(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPaymentOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPaymentOverviews(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {number} [billingId] 資格・認定請求ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationBillingPersonOverviews(userName?: string, numberString?: string, workPrefecture?: string, billingId?: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQualificationBillingPersonOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationBillingPersonOverviews(userName, numberString, workPrefecture, billingId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQualificationFeeOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の特定の資格・認定請求マスター情報を取得する
         * @summary 指定した資格・認定請求マスター情報取得
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationPaymentMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationPaymentMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の資格・認定請求マスター一覧を取得する
         * @summary 資格・認定請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationPaymentMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationsPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationsPaymentOverviews1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定入金状態情報をCSVからインポートする
         * @summary 資格・認定入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importAcquisitionQualificationPaymentCsv(csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importAcquisitionQualificationPaymentCsv(csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 年会費入金状態情報をCSVからインポートする
         * @summary 年会費入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importAnnualFeeCsv(csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importAnnualFeeCsv(csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他入金状態情報をCSVからインポートする
         * @summary その他入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importSignEtceteraPaymentCsv(csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importSignEtceteraPaymentCsv(csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 年会費請求情報を追加する
         * @summary 年会費請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnualFeePayment(newPaymentParam: NewPaymentParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnualFeePayment(newPaymentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他請求情報を追加する
         * @summary その他請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtcPayment(newPaymentParam: NewPaymentParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtcPayment(newPaymentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * その他請求マスターを追加・更新する
         * @summary その他請求マスター追加・更新
         * @param {EtcPaymentDetail} etcPaymentDetail その他請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEtcPaymentMaster(etcPaymentDetail: EtcPaymentDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EtcPaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEtcPaymentMaster(etcPaymentDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求情報を学会対応の支払い済みで更新する
         * @summary 請求情報を学会対応の支払い済みで更新
         * @param {PaymentOverview} paymentOverview 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentByConference(paymentOverview: PaymentOverview, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentByConference(paymentOverview, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定請求情報を追加する
         * @summary 資格・認定請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQualificationPayment(newPaymentParam: NewPaymentParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQualificationPayment(newPaymentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定請求マスターを追加・更新する
         * @summary 資格・認定請求マスター追加・更新
         * @param {PaymentDetail} paymentDetail 請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQualificationPaymentMaster(paymentDetail: PaymentDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQualificationPaymentMaster(paymentDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentRestControllerApi - factory interface
 * @export
 */
export const PaymentRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentRestControllerApiFp(configuration)
    return {
        /**
         * コンビニ入金情報をGMOに問い合わせて更新
         * @summary コンビニ入金情報をGMOに問い合わせて更新
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAndUpdateCvsPayment(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.checkAndUpdateCvsPayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した年会費請求情報を削除する
         * @summary 年会費請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnualFeePayment(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAnnualFeePayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した複数の年会費請求情報を削除する
         * @summary 指定した複数の年会費請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnualFeePayments(requestBody: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAnnualFeePayments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したその他請求情報を削除する
         * @summary その他請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPayment(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEtcPayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * その他請求マスターを削除する
         * @summary その他請求マスター削除
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPaymentMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEtcPaymentMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した複数のその他請求情報を削除する
         * @summary 指定した複数のその他請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtcPayments(requestBody: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteEtcPayments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した請求情報を削除する
         * @summary 請求情報の削除
         * @param {number} id 請求ID
         * @param {'AnnualFee' | 'Qualification' | 'Etc'} paymentContentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentByConference(id: number, paymentContentType: 'AnnualFee' | 'Qualification' | 'Etc', options?: any): AxiosPromise<string> {
            return localVarFp.deletePaymentByConference(id, paymentContentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した資格請求情報を削除する
         * @summary 資格請求情報の削除
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPayment(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteQualificationPayment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定請求マスターを削除する
         * @summary 資格・認定請求マスター削除
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPaymentMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteQualificationPaymentMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した複数の資格請求情報を削除する
         * @summary 指定した複数の資格請求情報の削除
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationPayments(requestBody: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteQualificationPayments(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定費領収書を一括で発行する
         * @summary 資格・認定費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAcquisitionQualificationPaymentReceipts(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.downloadAcquisitionQualificationPaymentReceipts(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 年会費領収書を一括で発行する
         * @summary 年会費領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAnnualFeesReceipts(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.downloadAnnualFeesReceipts(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * その他入金領収書を一括で発行する
         * @summary その他入金領収書の一括発行
         * @param {Array<number>} requestBody 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSignEtceteraPaymentReceipts(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.downloadSignEtceteraPaymentReceipts(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定入金状態情報をCSVにエクスポートする
         * @summary 資格・認定入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAcquisitionQualificationPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportAcquisitionQualificationPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 年会費入金状態情報をCSVにエクスポートする
         * @summary 年会費入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAnnualFeeCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportAnnualFeeCsv(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * その他入金状態情報をCSVにエクスポートする
         * @summary その他入金状態情報をCSVにエクスポート
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSignEtceteraPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportSignEtceteraPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAnnualFeeOverview> {
            return localVarFp.fetchAnnualFeeOverviews(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAnnualFeePaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchAnnualFeePaymentOverviews1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [billingId] その他請求ID
         * @param {string} [billingDateFrom] 請求開始年月日
         * @param {string} [billingDateTo] 請求終了年月日
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcBillingPersonOverviews(userName?: string, numberString?: string, billingId?: number, billingDateFrom?: string, billingDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEtcBillingPersonOverview> {
            return localVarFp.fetchEtcBillingPersonOverviews(userName, numberString, billingId, billingDateFrom, billingDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] その他請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEtcFeeOverview> {
            return localVarFp.fetchEtcFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の特定のその他請求マスター情報を取得する
         * @summary 指定したその他請求マスター情報取得
         * @param {number} id その他請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentMaster(id: number, options?: any): AxiosPromise<EtcPaymentDetail> {
            return localVarFp.fetchEtcPaymentMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のその他請求マスター一覧を取得する
         * @summary その他請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageEtcPaymentDetail> {
            return localVarFp.fetchEtcPaymentMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEtcPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchEtcPaymentOverviews1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した請求情報を取得する
         * @summary 請求情報の取得
         * @param {number} id 請求ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentDetailByConference(id: number, options?: any): AxiosPromise<PaymentOverview> {
            return localVarFp.fetchPaymentDetailByConference(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPaymentOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchPaymentOverviews(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workPrefecture] 勤務先の都道府県
         * @param {number} [billingId] 資格・認定請求ID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationBillingPersonOverviews(userName?: string, numberString?: string, workPrefecture?: string, billingId?: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQualificationBillingPersonOverview> {
            return localVarFp.fetchQualificationBillingPersonOverviews(userName, numberString, workPrefecture, billingId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {number} [jobId] 職種ID
         * @param {number} [fiscalYear] 年度
         * @param {number} [billingId] 資格・認定請求ID
         * @param {string} [memberStatus] 会員状態
         * @param {string} [paymentStatus] 入金状態
         * @param {string} [paymentType] 入金方法
         * @param {string} [paymentDateFrom] 入金日FROM
         * @param {string} [paymentDateTo] 入金日TO
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQualificationFeeOverview> {
            return localVarFp.fetchQualificationFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の特定の資格・認定請求マスター情報を取得する
         * @summary 指定した資格・認定請求マスター情報取得
         * @param {number} id 資格・認定請求マスターID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationPaymentMaster(id: number, options?: any): AxiosPromise<PaymentDetail> {
            return localVarFp.fetchQualificationPaymentMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の資格・認定請求マスター一覧を取得する
         * @summary 資格・認定請求マスター一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentDetail> {
            return localVarFp.fetchQualificationPaymentMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationsPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePaymentOverview> {
            return localVarFp.fetchQualificationsPaymentOverviews1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定入金状態情報をCSVからインポートする
         * @summary 資格・認定入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAcquisitionQualificationPaymentCsv(csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importAcquisitionQualificationPaymentCsv(csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 年会費入金状態情報をCSVからインポートする
         * @summary 年会費入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAnnualFeeCsv(csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importAnnualFeeCsv(csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * その他入金状態情報をCSVからインポートする
         * @summary その他入金状態情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSignEtceteraPaymentCsv(csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importSignEtceteraPaymentCsv(csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 年会費請求情報を追加する
         * @summary 年会費請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnualFeePayment(newPaymentParam: NewPaymentParam, options?: any): AxiosPromise<string> {
            return localVarFp.updateAnnualFeePayment(newPaymentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * その他請求情報を追加する
         * @summary その他請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtcPayment(newPaymentParam: NewPaymentParam, options?: any): AxiosPromise<string> {
            return localVarFp.updateEtcPayment(newPaymentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * その他請求マスターを追加・更新する
         * @summary その他請求マスター追加・更新
         * @param {EtcPaymentDetail} etcPaymentDetail その他請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEtcPaymentMaster(etcPaymentDetail: EtcPaymentDetail, options?: any): AxiosPromise<EtcPaymentDetail> {
            return localVarFp.updateEtcPaymentMaster(etcPaymentDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求情報を学会対応の支払い済みで更新する
         * @summary 請求情報を学会対応の支払い済みで更新
         * @param {PaymentOverview} paymentOverview 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentByConference(paymentOverview: PaymentOverview, options?: any): AxiosPromise<PaymentOverview> {
            return localVarFp.updatePaymentByConference(paymentOverview, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定請求情報を追加する
         * @summary 資格・認定請求情報の追加
         * @param {NewPaymentParam} newPaymentParam 請求情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationPayment(newPaymentParam: NewPaymentParam, options?: any): AxiosPromise<string> {
            return localVarFp.updateQualificationPayment(newPaymentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定請求マスターを追加・更新する
         * @summary 資格・認定請求マスター追加・更新
         * @param {PaymentDetail} paymentDetail 請求マスター情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationPaymentMaster(paymentDetail: PaymentDetail, options?: any): AxiosPromise<PaymentDetail> {
            return localVarFp.updateQualificationPaymentMaster(paymentDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentRestControllerApi - object-oriented interface
 * @export
 * @class PaymentRestControllerApi
 * @extends {BaseAPI}
 */
export class PaymentRestControllerApi extends BaseAPI {
    /**
     * コンビニ入金情報をGMOに問い合わせて更新
     * @summary コンビニ入金情報をGMOに問い合わせて更新
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public checkAndUpdateCvsPayment(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).checkAndUpdateCvsPayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した年会費請求情報を削除する
     * @summary 年会費請求情報の削除
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteAnnualFeePayment(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteAnnualFeePayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した複数の年会費請求情報を削除する
     * @summary 指定した複数の年会費請求情報の削除
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteAnnualFeePayments(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteAnnualFeePayments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したその他請求情報を削除する
     * @summary その他請求情報の削除
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteEtcPayment(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteEtcPayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他請求マスターを削除する
     * @summary その他請求マスター削除
     * @param {number} id その他請求マスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteEtcPaymentMaster(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteEtcPaymentMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した複数のその他請求情報を削除する
     * @summary 指定した複数のその他請求情報の削除
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteEtcPayments(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteEtcPayments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した請求情報を削除する
     * @summary 請求情報の削除
     * @param {number} id 請求ID
     * @param {'AnnualFee' | 'Qualification' | 'Etc'} paymentContentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deletePaymentByConference(id: number, paymentContentType: 'AnnualFee' | 'Qualification' | 'Etc', options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deletePaymentByConference(id, paymentContentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した資格請求情報を削除する
     * @summary 資格請求情報の削除
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteQualificationPayment(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteQualificationPayment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定請求マスターを削除する
     * @summary 資格・認定請求マスター削除
     * @param {number} id 資格・認定請求マスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteQualificationPaymentMaster(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteQualificationPaymentMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した複数の資格請求情報を削除する
     * @summary 指定した複数の資格請求情報の削除
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public deleteQualificationPayments(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).deleteQualificationPayments(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定費領収書を一括で発行する
     * @summary 資格・認定費領収書の一括発行
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public downloadAcquisitionQualificationPaymentReceipts(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).downloadAcquisitionQualificationPaymentReceipts(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 年会費領収書を一括で発行する
     * @summary 年会費領収書の一括発行
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public downloadAnnualFeesReceipts(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).downloadAnnualFeesReceipts(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他入金領収書を一括で発行する
     * @summary その他入金領収書の一括発行
     * @param {Array<number>} requestBody 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public downloadSignEtceteraPaymentReceipts(requestBody: Array<number>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).downloadSignEtceteraPaymentReceipts(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定入金状態情報をCSVにエクスポートする
     * @summary 資格・認定入金状態情報をCSVにエクスポート
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {number} [billingId] 資格・認定請求ID
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public exportAcquisitionQualificationPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).exportAcquisitionQualificationPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 年会費入金状態情報をCSVにエクスポートする
     * @summary 年会費入金状態情報をCSVにエクスポート
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public exportAnnualFeeCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).exportAnnualFeeCsv(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他入金状態情報をCSVにエクスポートする
     * @summary その他入金状態情報をCSVにエクスポート
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {number} [billingId] その他請求ID
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public exportSignEtceteraPaymentCsv(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).exportSignEtceteraPaymentCsv(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchAnnualFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchAnnualFeeOverviews(userName, numberString, jobId, fiscalYear, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchAnnualFeePaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchAnnualFeePaymentOverviews1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [billingId] その他請求ID
     * @param {string} [billingDateFrom] 請求開始年月日
     * @param {string} [billingDateTo] 請求終了年月日
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchEtcBillingPersonOverviews(userName?: string, numberString?: string, billingId?: number, billingDateFrom?: string, billingDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchEtcBillingPersonOverviews(userName, numberString, billingId, billingDateFrom, billingDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {number} [billingId] その他請求ID
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchEtcFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchEtcFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の特定のその他請求マスター情報を取得する
     * @summary 指定したその他請求マスター情報取得
     * @param {number} id その他請求マスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchEtcPaymentMaster(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchEtcPaymentMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のその他請求マスター一覧を取得する
     * @summary その他請求マスター一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchEtcPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchEtcPaymentMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchEtcPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchEtcPaymentOverviews1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した請求情報を取得する
     * @summary 請求情報の取得
     * @param {number} id 請求ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchPaymentDetailByConference(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchPaymentDetailByConference(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchPaymentOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchPaymentOverviews(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {string} [workPrefecture] 勤務先の都道府県
     * @param {number} [billingId] 資格・認定請求ID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchQualificationBillingPersonOverviews(userName?: string, numberString?: string, workPrefecture?: string, billingId?: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchQualificationBillingPersonOverviews(userName, numberString, workPrefecture, billingId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {number} [jobId] 職種ID
     * @param {number} [fiscalYear] 年度
     * @param {number} [billingId] 資格・認定請求ID
     * @param {string} [memberStatus] 会員状態
     * @param {string} [paymentStatus] 入金状態
     * @param {string} [paymentType] 入金方法
     * @param {string} [paymentDateFrom] 入金日FROM
     * @param {string} [paymentDateTo] 入金日TO
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchQualificationFeeOverviews(userName?: string, numberString?: string, jobId?: number, fiscalYear?: number, billingId?: number, memberStatus?: string, paymentStatus?: string, paymentType?: string, paymentDateFrom?: string, paymentDateTo?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchQualificationFeeOverviews(userName, numberString, jobId, fiscalYear, billingId, memberStatus, paymentStatus, paymentType, paymentDateFrom, paymentDateTo, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の特定の資格・認定請求マスター情報を取得する
     * @summary 指定した資格・認定請求マスター情報取得
     * @param {number} id 資格・認定請求マスターID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchQualificationPaymentMaster(id: number, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchQualificationPaymentMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の資格・認定請求マスター一覧を取得する
     * @summary 資格・認定請求マスター一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchQualificationPaymentMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchQualificationPaymentMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public fetchQualificationsPaymentOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).fetchQualificationsPaymentOverviews1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定入金状態情報をCSVからインポートする
     * @summary 資格・認定入金状態情報をCSVからインポート
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public importAcquisitionQualificationPaymentCsv(csvFile?: any, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).importAcquisitionQualificationPaymentCsv(csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 年会費入金状態情報をCSVからインポートする
     * @summary 年会費入金状態情報をCSVからインポート
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public importAnnualFeeCsv(csvFile?: any, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).importAnnualFeeCsv(csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他入金状態情報をCSVからインポートする
     * @summary その他入金状態情報をCSVからインポート
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public importSignEtceteraPaymentCsv(csvFile?: any, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).importSignEtceteraPaymentCsv(csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 年会費請求情報を追加する
     * @summary 年会費請求情報の追加
     * @param {NewPaymentParam} newPaymentParam 請求情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updateAnnualFeePayment(newPaymentParam: NewPaymentParam, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updateAnnualFeePayment(newPaymentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他請求情報を追加する
     * @summary その他請求情報の追加
     * @param {NewPaymentParam} newPaymentParam 請求情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updateEtcPayment(newPaymentParam: NewPaymentParam, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updateEtcPayment(newPaymentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * その他請求マスターを追加・更新する
     * @summary その他請求マスター追加・更新
     * @param {EtcPaymentDetail} etcPaymentDetail その他請求マスター情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updateEtcPaymentMaster(etcPaymentDetail: EtcPaymentDetail, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updateEtcPaymentMaster(etcPaymentDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求情報を学会対応の支払い済みで更新する
     * @summary 請求情報を学会対応の支払い済みで更新
     * @param {PaymentOverview} paymentOverview 請求情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updatePaymentByConference(paymentOverview: PaymentOverview, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updatePaymentByConference(paymentOverview, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定請求情報を追加する
     * @summary 資格・認定請求情報の追加
     * @param {NewPaymentParam} newPaymentParam 請求情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updateQualificationPayment(newPaymentParam: NewPaymentParam, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updateQualificationPayment(newPaymentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定請求マスターを追加・更新する
     * @summary 資格・認定請求マスター追加・更新
     * @param {PaymentDetail} paymentDetail 請求マスター情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentRestControllerApi
     */
    public updateQualificationPaymentMaster(paymentDetail: PaymentDetail, options?: any) {
        return PaymentRestControllerApiFp(this.configuration).updateQualificationPaymentMaster(paymentDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionMeetingRestControllerApi - axios parameter creator
 * @export
 */
export const PositionMeetingRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した委員会開催詳細を削除する
         * @summary 指定した委員会開催詳細の削除
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMeeting: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePositionMeeting', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 委員会開催のファイルを削除する
         * @summary 委員会開催のファイルを削除
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePositionMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('deletePositionMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/positions/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した委員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryPositionMeetingCsv: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEntryPositionMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}/entryMeetingMembers/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催の出席者一覧を取得する
         * @summary 指定した委員会開催の出席者一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryPositionMeetingDetails: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchEntryPositionMeetingDetails', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}/entryMeetingMembers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催詳細を取得する
         * @summary 指定した委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingDetail: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMeetingDetail', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の委員会開催一覧を取得する
         * @summary 学会の委員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingDetails: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/positions/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 委員会開催のファイルURLを取得する
         * @summary 委員会開催のファイルURLを取得
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingFile: async (id: number, fileNo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('fetchPositionMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/positions/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 委員会開催のファイルURL一覧を取得する
         * @summary 委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingFiles: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMeetingFiles', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した委員会開催の出席者一覧をCSVからインポート
         * @param {number} id 委員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryPositionMeetingCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importEntryPositionMeetingCsv', 'id', id)
            const localVarPath = `/api/conference/positions/meetings/{id}/entryMeetingMembers/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催の出席者一覧を更新する
         * @summary 指定した委員会開催の出席者一覧の更新
         * @param {number} id 委員会開催ID
         * @param {Array<EntryPositionMeetingDetail>} entryPositionMeetingDetail 委員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryPositionMeeting: async (id: number, entryPositionMeetingDetail: Array<EntryPositionMeetingDetail>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEntryPositionMeeting', 'id', id)
            // verify required parameter 'entryPositionMeetingDetail' is not null or undefined
            assertParamExists('updateEntryPositionMeeting', 'entryPositionMeetingDetail', entryPositionMeetingDetail)
            const localVarPath = `/api/conference/positions/meetings/{id}/entryMeetingMembers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entryPositionMeetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会開催詳細を更新・追加する
         * @summary 指定した委員会開催詳細の更新・追加
         * @param {PositionMeetingDetail} positionMeetingDetail 委員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionMeeting: async (positionMeetingDetail: PositionMeetingDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'positionMeetingDetail' is not null or undefined
            assertParamExists('updatePositionMeeting', 'positionMeetingDetail', positionMeetingDetail)
            const localVarPath = `/api/conference/positions/meetings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionMeetingDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 委員会開催のファイルをアップロードする
         * @summary 委員会開催のファイルをアップロード
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPositionMeetingFile: async (id: number, fileNo: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadPositionMeetingFile', 'id', id)
            // verify required parameter 'fileNo' is not null or undefined
            assertParamExists('uploadPositionMeetingFile', 'fileNo', fileNo)
            const localVarPath = `/api/conference/positions/meetings/{id}/files/{fileNo}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileNo"}}`, encodeURIComponent(String(fileNo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionMeetingRestControllerApi - functional programming interface
 * @export
 */
export const PositionMeetingRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionMeetingRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した委員会開催詳細を削除する
         * @summary 指定した委員会開催詳細の削除
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePositionMeeting(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePositionMeeting(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 委員会開催のファイルを削除する
         * @summary 委員会開催のファイルを削除
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePositionMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePositionMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した委員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEntryPositionMeetingCsv(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEntryPositionMeetingCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催の出席者一覧を取得する
         * @summary 指定した委員会開催の出席者一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchEntryPositionMeetingDetails(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntryPositionMeetingDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchEntryPositionMeetingDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催詳細を取得する
         * @summary 指定した委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMeetingDetail(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMeetingDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の委員会開催一覧を取得する
         * @summary 学会の委員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMeetingDetails(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 委員会開催のファイルURLを取得する
         * @summary 委員会開催のファイルURLを取得
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMeetingFile(id: number, fileNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMeetingFile(id, fileNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 委員会開催のファイルURL一覧を取得する
         * @summary 委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMeetingFiles(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: UploadedFile; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMeetingFiles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した委員会開催の出席者一覧をCSVからインポート
         * @param {number} id 委員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importEntryPositionMeetingCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importEntryPositionMeetingCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催の出席者一覧を更新する
         * @summary 指定した委員会開催の出席者一覧の更新
         * @param {number} id 委員会開催ID
         * @param {Array<EntryPositionMeetingDetail>} entryPositionMeetingDetail 委員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEntryPositionMeeting(id: number, entryPositionMeetingDetail: Array<EntryPositionMeetingDetail>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntryPositionMeeting(id, entryPositionMeetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会開催詳細を更新・追加する
         * @summary 指定した委員会開催詳細の更新・追加
         * @param {PositionMeetingDetail} positionMeetingDetail 委員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePositionMeeting(positionMeetingDetail: PositionMeetingDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePositionMeeting(positionMeetingDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 委員会開催のファイルをアップロードする
         * @summary 委員会開催のファイルをアップロード
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPositionMeetingFile(id: number, fileNo: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPositionMeetingFile(id, fileNo, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionMeetingRestControllerApi - factory interface
 * @export
 */
export const PositionMeetingRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionMeetingRestControllerApiFp(configuration)
    return {
        /**
         * 指定した委員会開催詳細を削除する
         * @summary 指定した委員会開催詳細の削除
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMeeting(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePositionMeeting(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 委員会開催のファイルを削除する
         * @summary 委員会開催のファイルを削除
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePositionMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVにエクスポートする
         * @summary 指定した委員会開催の出席者一覧をCSVにエクスポート
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEntryPositionMeetingCsv(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportEntryPositionMeetingCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催の出席者一覧を取得する
         * @summary 指定した委員会開催の出席者一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchEntryPositionMeetingDetails(id: number, options?: any): AxiosPromise<Array<EntryPositionMeetingDetail>> {
            return localVarFp.fetchEntryPositionMeetingDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催詳細を取得する
         * @summary 指定した委員会開催詳細を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingDetail(id: number, options?: any): AxiosPromise<PositionMeetingDetail> {
            return localVarFp.fetchPositionMeetingDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の委員会開催一覧を取得する
         * @summary 学会の委員会開催一覧を取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionMeetingDetail> {
            return localVarFp.fetchPositionMeetingDetails(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 委員会開催のファイルURLを取得する
         * @summary 委員会開催のファイルURLを取得
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingFile(id: number, fileNo: number, options?: any): AxiosPromise<UploadedFile> {
            return localVarFp.fetchPositionMeetingFile(id, fileNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 委員会開催のファイルURL一覧を取得する
         * @summary 委員会開催のファイルURL一覧を取得
         * @param {number} id 委員会開催ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMeetingFiles(id: number, options?: any): AxiosPromise<{ [key: string]: UploadedFile; }> {
            return localVarFp.fetchPositionMeetingFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催の出席者一覧をCSVからインポートする
         * @summary 指定した委員会開催の出席者一覧をCSVからインポート
         * @param {number} id 委員会開催ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importEntryPositionMeetingCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importEntryPositionMeetingCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催の出席者一覧を更新する
         * @summary 指定した委員会開催の出席者一覧の更新
         * @param {number} id 委員会開催ID
         * @param {Array<EntryPositionMeetingDetail>} entryPositionMeetingDetail 委員会出席者詳細リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEntryPositionMeeting(id: number, entryPositionMeetingDetail: Array<EntryPositionMeetingDetail>, options?: any): AxiosPromise<string> {
            return localVarFp.updateEntryPositionMeeting(id, entryPositionMeetingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会開催詳細を更新・追加する
         * @summary 指定した委員会開催詳細の更新・追加
         * @param {PositionMeetingDetail} positionMeetingDetail 委員会開催詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionMeeting(positionMeetingDetail: PositionMeetingDetail, options?: any): AxiosPromise<PositionMeetingDetail> {
            return localVarFp.updatePositionMeeting(positionMeetingDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 委員会開催のファイルをアップロードする
         * @summary 委員会開催のファイルをアップロード
         * @param {number} id 委員会開催ID
         * @param {number} fileNo ファイル番号
         * @param {any} [file] アップロードファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPositionMeetingFile(id: number, fileNo: number, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.uploadPositionMeetingFile(id, fileNo, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PositionMeetingRestControllerApi - object-oriented interface
 * @export
 * @class PositionMeetingRestControllerApi
 * @extends {BaseAPI}
 */
export class PositionMeetingRestControllerApi extends BaseAPI {
    /**
     * 指定した委員会開催詳細を削除する
     * @summary 指定した委員会開催詳細の削除
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public deletePositionMeeting(id: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).deletePositionMeeting(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 委員会開催のファイルを削除する
     * @summary 委員会開催のファイルを削除
     * @param {number} id 委員会開催ID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public deletePositionMeetingFile(id: number, fileNo: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).deletePositionMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催の出席者一覧をCSVにエクスポートする
     * @summary 指定した委員会開催の出席者一覧をCSVにエクスポート
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public exportEntryPositionMeetingCsv(id: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).exportEntryPositionMeetingCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催の出席者一覧を取得する
     * @summary 指定した委員会開催の出席者一覧を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public fetchEntryPositionMeetingDetails(id: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).fetchEntryPositionMeetingDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催詳細を取得する
     * @summary 指定した委員会開催詳細を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public fetchPositionMeetingDetail(id: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).fetchPositionMeetingDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の委員会開催一覧を取得する
     * @summary 学会の委員会開催一覧を取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public fetchPositionMeetingDetails(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).fetchPositionMeetingDetails(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 委員会開催のファイルURLを取得する
     * @summary 委員会開催のファイルURLを取得
     * @param {number} id 委員会開催ID
     * @param {number} fileNo ファイル番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public fetchPositionMeetingFile(id: number, fileNo: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).fetchPositionMeetingFile(id, fileNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 委員会開催のファイルURL一覧を取得する
     * @summary 委員会開催のファイルURL一覧を取得
     * @param {number} id 委員会開催ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public fetchPositionMeetingFiles(id: number, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).fetchPositionMeetingFiles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催の出席者一覧をCSVからインポートする
     * @summary 指定した委員会開催の出席者一覧をCSVからインポート
     * @param {number} id 委員会開催ID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public importEntryPositionMeetingCsv(id: number, csvFile?: any, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).importEntryPositionMeetingCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催の出席者一覧を更新する
     * @summary 指定した委員会開催の出席者一覧の更新
     * @param {number} id 委員会開催ID
     * @param {Array<EntryPositionMeetingDetail>} entryPositionMeetingDetail 委員会出席者詳細リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public updateEntryPositionMeeting(id: number, entryPositionMeetingDetail: Array<EntryPositionMeetingDetail>, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).updateEntryPositionMeeting(id, entryPositionMeetingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会開催詳細を更新・追加する
     * @summary 指定した委員会開催詳細の更新・追加
     * @param {PositionMeetingDetail} positionMeetingDetail 委員会開催詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public updatePositionMeeting(positionMeetingDetail: PositionMeetingDetail, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).updatePositionMeeting(positionMeetingDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 委員会開催のファイルをアップロードする
     * @summary 委員会開催のファイルをアップロード
     * @param {number} id 委員会開催ID
     * @param {number} fileNo ファイル番号
     * @param {any} [file] アップロードファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionMeetingRestControllerApi
     */
    public uploadPositionMeetingFile(id: number, fileNo: number, file?: any, options?: any) {
        return PositionMeetingRestControllerApiFp(this.configuration).uploadPositionMeetingFile(id, fileNo, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionRestControllerApi - axios parameter creator
 * @export
 */
export const PositionRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した委員会マスタを削除する
         * @summary 指定した委員会マスタの削除
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePositionMaster', 'id', id)
            const localVarPath = `/api/conference/positions/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会名簿詳細を削除する
         * @summary 指定した委員会名簿詳細の削除
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionQualification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePositionQualification', 'id', id)
            const localVarPath = `/api/conference/positions/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会の参加者情報をCSVにエクスポートする
         * @summary 指定した委員会の参加者情報をCSVにエクスポート
         * @param {number} id 委員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPositionQualificationCsv: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportPositionQualificationCsv', 'id', id)
            const localVarPath = `/api/conference/positions/qualifications/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会マスタを取得する
         * @summary 指定した学会の委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMaster', 'id', id)
            const localVarPath = `/api/conference/positions/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会の委員会開催一覧を取得する
         * @summary 指定した学会の委員会の委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasterMeetings: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMasterMeetings', 'id', id)
            const localVarPath = `/api/conference/positions/masters/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会の委員会名簿一覧を取得する
         * @summary 指定した学会の委員会の委員会名簿一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasterQualifications: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionMasterQualifications', 'id', id)
            const localVarPath = `/api/conference/positions/masters/{id}/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の委員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/positions/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会の委員会名簿詳細を取得する
         * @summary 指定した学会の委員会の委員会名簿詳細を取得
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionQualification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionQualification', 'id', id)
            const localVarPath = `/api/conference/positions/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionQualificationOverviews: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchPositionQualificationOverviews', 'id', id)
            const localVarPath = `/api/conference/members/{id}/positions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した委員会の参加者情報をCSVからインポートする
         * @summary 指定した委員会の参加者情報をCSVからインポート
         * @param {number} id 委員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPositionQualificationCsv: async (id: number, csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importPositionQualificationCsv', 'id', id)
            const localVarPath = `/api/conference/positions/qualifications/{id}/import`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会マスタを更新・追加する
         * @summary 指定した学会の委員会マスタの更新・追加
         * @param {PositionDetail} positionDetail 委員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionMaster: async (positionDetail: PositionDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'positionDetail' is not null or undefined
            assertParamExists('updatePositionMaster', 'positionDetail', positionDetail)
            const localVarPath = `/api/conference/positions/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の委員会名簿詳細を更新・追加する
         * @summary 指定した学会の委員会名簿詳細の更新・追加
         * @param {PositionQualificationDetail} positionQualificationDetail 委員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionQualification: async (positionQualificationDetail: PositionQualificationDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'positionQualificationDetail' is not null or undefined
            assertParamExists('updatePositionQualification', 'positionQualificationDetail', positionQualificationDetail)
            const localVarPath = `/api/conference/positions/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionQualificationDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionRestControllerApi - functional programming interface
 * @export
 */
export const PositionRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した委員会マスタを削除する
         * @summary 指定した委員会マスタの削除
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePositionMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePositionMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会名簿詳細を削除する
         * @summary 指定した委員会名簿詳細の削除
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePositionQualification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePositionQualification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会の参加者情報をCSVにエクスポートする
         * @summary 指定した委員会の参加者情報をCSVにエクスポート
         * @param {number} id 委員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPositionQualificationCsv(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPositionQualificationCsv(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会マスタを取得する
         * @summary 指定した学会の委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会の委員会開催一覧を取得する
         * @summary 指定した学会の委員会の委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionMeetingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMasterMeetings(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会の委員会名簿一覧を取得する
         * @summary 指定した学会の委員会の委員会名簿一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMasterQualifications(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の委員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会の委員会名簿詳細を取得する
         * @summary 指定した学会の委員会の委員会名簿詳細を取得
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionQualification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionQualification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPositionQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagePositionQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchPositionQualificationOverviews(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した委員会の参加者情報をCSVからインポートする
         * @summary 指定した委員会の参加者情報をCSVからインポート
         * @param {number} id 委員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPositionQualificationCsv(id: number, csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPositionQualificationCsv(id, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会マスタを更新・追加する
         * @summary 指定した学会の委員会マスタの更新・追加
         * @param {PositionDetail} positionDetail 委員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePositionMaster(positionDetail: PositionDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePositionMaster(positionDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の委員会名簿詳細を更新・追加する
         * @summary 指定した学会の委員会名簿詳細の更新・追加
         * @param {PositionQualificationDetail} positionQualificationDetail 委員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePositionQualification(positionQualificationDetail: PositionQualificationDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePositionQualification(positionQualificationDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionRestControllerApi - factory interface
 * @export
 */
export const PositionRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionRestControllerApiFp(configuration)
    return {
        /**
         * 指定した委員会マスタを削除する
         * @summary 指定した委員会マスタの削除
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePositionMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会名簿詳細を削除する
         * @summary 指定した委員会名簿詳細の削除
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePositionQualification(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePositionQualification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会の参加者情報をCSVにエクスポートする
         * @summary 指定した委員会の参加者情報をCSVにエクスポート
         * @param {number} id 委員会ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPositionQualificationCsv(id: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportPositionQualificationCsv(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会マスタを取得する
         * @summary 指定した学会の委員会マスタ取得
         * @param {number} id 委員会マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMaster(id: number, options?: any): AxiosPromise<PositionDetail> {
            return localVarFp.fetchPositionMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会の委員会開催一覧を取得する
         * @summary 指定した学会の委員会の委員会開催一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionMeetingDetail> {
            return localVarFp.fetchPositionMasterMeetings(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会の委員会名簿一覧を取得する
         * @summary 指定した学会の委員会の委員会名簿一覧取得
         * @param {number} id 委員会マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionQualificationDetail> {
            return localVarFp.fetchPositionMasterQualifications(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の理事･役員会マスタ一覧を取得する
         * @summary 学会の委員会マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionDetail> {
            return localVarFp.fetchPositionMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会の委員会名簿詳細を取得する
         * @summary 指定した学会の委員会の委員会名簿詳細を取得
         * @param {number} id 委員会名簿ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionQualification(id: number, options?: any): AxiosPromise<PositionQualificationDetail> {
            return localVarFp.fetchPositionQualification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPositionQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PagePositionQualificationOverview> {
            return localVarFp.fetchPositionQualificationOverviews(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した委員会の参加者情報をCSVからインポートする
         * @summary 指定した委員会の参加者情報をCSVからインポート
         * @param {number} id 委員会ID
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPositionQualificationCsv(id: number, csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importPositionQualificationCsv(id, csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会マスタを更新・追加する
         * @summary 指定した学会の委員会マスタの更新・追加
         * @param {PositionDetail} positionDetail 委員会マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionMaster(positionDetail: PositionDetail, options?: any): AxiosPromise<PositionDetail> {
            return localVarFp.updatePositionMaster(positionDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の委員会名簿詳細を更新・追加する
         * @summary 指定した学会の委員会名簿詳細の更新・追加
         * @param {PositionQualificationDetail} positionQualificationDetail 委員会名簿詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositionQualification(positionQualificationDetail: PositionQualificationDetail, options?: any): AxiosPromise<PositionQualificationDetail> {
            return localVarFp.updatePositionQualification(positionQualificationDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PositionRestControllerApi - object-oriented interface
 * @export
 * @class PositionRestControllerApi
 * @extends {BaseAPI}
 */
export class PositionRestControllerApi extends BaseAPI {
    /**
     * 指定した委員会マスタを削除する
     * @summary 指定した委員会マスタの削除
     * @param {number} id 委員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public deletePositionMaster(id: number, options?: any) {
        return PositionRestControllerApiFp(this.configuration).deletePositionMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会名簿詳細を削除する
     * @summary 指定した委員会名簿詳細の削除
     * @param {number} id 委員会名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public deletePositionQualification(id: number, options?: any) {
        return PositionRestControllerApiFp(this.configuration).deletePositionQualification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会の参加者情報をCSVにエクスポートする
     * @summary 指定した委員会の参加者情報をCSVにエクスポート
     * @param {number} id 委員会ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public exportPositionQualificationCsv(id: number, options?: any) {
        return PositionRestControllerApiFp(this.configuration).exportPositionQualificationCsv(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会マスタを取得する
     * @summary 指定した学会の委員会マスタ取得
     * @param {number} id 委員会マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionMaster(id: number, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会の委員会開催一覧を取得する
     * @summary 指定した学会の委員会の委員会開催一覧取得
     * @param {number} id 委員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionMasterMeetings(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionMasterMeetings(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会の委員会名簿一覧を取得する
     * @summary 指定した学会の委員会の委員会名簿一覧取得
     * @param {number} id 委員会マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionMasterQualifications(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionMasterQualifications(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の理事･役員会マスタ一覧を取得する
     * @summary 学会の委員会マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会の委員会名簿詳細を取得する
     * @summary 指定した学会の委員会の委員会名簿詳細を取得
     * @param {number} id 委員会名簿ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionQualification(id: number, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionQualification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public fetchPositionQualificationOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return PositionRestControllerApiFp(this.configuration).fetchPositionQualificationOverviews(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した委員会の参加者情報をCSVからインポートする
     * @summary 指定した委員会の参加者情報をCSVからインポート
     * @param {number} id 委員会ID
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public importPositionQualificationCsv(id: number, csvFile?: any, options?: any) {
        return PositionRestControllerApiFp(this.configuration).importPositionQualificationCsv(id, csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会マスタを更新・追加する
     * @summary 指定した学会の委員会マスタの更新・追加
     * @param {PositionDetail} positionDetail 委員会マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public updatePositionMaster(positionDetail: PositionDetail, options?: any) {
        return PositionRestControllerApiFp(this.configuration).updatePositionMaster(positionDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の委員会名簿詳細を更新・追加する
     * @summary 指定した学会の委員会名簿詳細の更新・追加
     * @param {PositionQualificationDetail} positionQualificationDetail 委員会名簿詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionRestControllerApi
     */
    public updatePositionQualification(positionQualificationDetail: PositionQualificationDetail, options?: any) {
        return PositionRestControllerApiFp(this.configuration).updatePositionQualification(positionQualificationDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicRestControllerApi - axios parameter creator
 * @export
 */
export const PublicRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * パスワードリセットトークンの有効期限をチェックする
         * @summary パスワードリセットトークンの有効期限チェック
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswordToken: async (passwordResetInfo: PasswordResetInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetInfo' is not null or undefined
            assertParamExists('checkPasswordToken', 'passwordResetInfo', passwordResetInfo)
            const localVarPath = `/api/public/checkPasswordToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会のプロファイル情報取得
         * @summary 学会のプロファイル情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/conferences/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが存在する場合パスワード再設定メールを送信する
         * @summary パスワード再設定
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetPassword: async (passwordResetInfo: PasswordResetInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetInfo' is not null or undefined
            assertParamExists('forgetPassword', 'passwordResetInfo', passwordResetInfo)
            const localVarPath = `/api/public/forgetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードをリセットする
         * @summary パスワードリセット
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetInfo: PasswordResetInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetInfo' is not null or undefined
            assertParamExists('resetPassword', 'passwordResetInfo', passwordResetInfo)
            const localVarPath = `/api/public/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicRestControllerApi - functional programming interface
 * @export
 */
export const PublicRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * パスワードリセットトークンの有効期限をチェックする
         * @summary パスワードリセットトークンの有効期限チェック
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPasswordToken(passwordResetInfo: PasswordResetInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetTokenDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPasswordToken(passwordResetInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会のプロファイル情報取得
         * @summary 学会のプロファイル情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConferenceProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが存在する場合パスワード再設定メールを送信する
         * @summary パスワード再設定
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgetPassword(passwordResetInfo: PasswordResetInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgetPassword(passwordResetInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワードをリセットする
         * @summary パスワードリセット
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetInfo: PasswordResetInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordResetInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicRestControllerApi - factory interface
 * @export
 */
export const PublicRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicRestControllerApiFp(configuration)
    return {
        /**
         * パスワードリセットトークンの有効期限をチェックする
         * @summary パスワードリセットトークンの有効期限チェック
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswordToken(passwordResetInfo: PasswordResetInfo, options?: any): AxiosPromise<PasswordResetTokenDetail> {
            return localVarFp.checkPasswordToken(passwordResetInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会のプロファイル情報取得
         * @summary 学会のプロファイル情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceProfile(options?: any): AxiosPromise<ConferenceProfile> {
            return localVarFp.fetchConferenceProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが存在する場合パスワード再設定メールを送信する
         * @summary パスワード再設定
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgetPassword(passwordResetInfo: PasswordResetInfo, options?: any): AxiosPromise<string> {
            return localVarFp.forgetPassword(passwordResetInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードをリセットする
         * @summary パスワードリセット
         * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordResetInfo: PasswordResetInfo, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(passwordResetInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicRestControllerApi - object-oriented interface
 * @export
 * @class PublicRestControllerApi
 * @extends {BaseAPI}
 */
export class PublicRestControllerApi extends BaseAPI {
    /**
     * パスワードリセットトークンの有効期限をチェックする
     * @summary パスワードリセットトークンの有効期限チェック
     * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRestControllerApi
     */
    public checkPasswordToken(passwordResetInfo: PasswordResetInfo, options?: any) {
        return PublicRestControllerApiFp(this.configuration).checkPasswordToken(passwordResetInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会のプロファイル情報取得
     * @summary 学会のプロファイル情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRestControllerApi
     */
    public fetchConferenceProfile(options?: any) {
        return PublicRestControllerApiFp(this.configuration).fetchConferenceProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが存在する場合パスワード再設定メールを送信する
     * @summary パスワード再設定
     * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRestControllerApi
     */
    public forgetPassword(passwordResetInfo: PasswordResetInfo, options?: any) {
        return PublicRestControllerApiFp(this.configuration).forgetPassword(passwordResetInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードをリセットする
     * @summary パスワードリセット
     * @param {PasswordResetInfo} passwordResetInfo パスワードリセット情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicRestControllerApi
     */
    public resetPassword(passwordResetInfo: PasswordResetInfo, options?: any) {
        return PublicRestControllerApiFp(this.configuration).resetPassword(passwordResetInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QualificationRestControllerApi - axios parameter creator
 * @export
 */
export const QualificationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した資格・認定保持者詳細を削除する
         * @summary 指定した資格・認定保持者詳細の削除
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcquisitionQualification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAcquisitionQualification', 'id', id)
            const localVarPath = `/api/conference/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した複数の資格・認定保持者詳細を削除する
         * @summary 指定した複数の資格・認定保持者詳細の削除
         * @param {Array<number>} requestBody 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcquisitionQualifications: async (requestBody: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteAcquisitionQualifications', 'requestBody', requestBody)
            const localVarPath = `/api/conference/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した資格・認定マスタを削除する
         * @summary 指定した資格・認定マスタの削除
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteQualificationMaster', 'id', id)
            const localVarPath = `/api/conference/qualifications/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定保持者をCSVにエクスポートする
         * @summary 資格・認定保持者をCSVにエクスポート
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAcquisitionQualificationCsv: async (qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/qualifications/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (qualificationsId !== undefined) {
                localVarQueryParameter['qualificationsId'] = qualificationsId;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (workOfficeName !== undefined) {
                localVarQueryParameter['workOfficeName'] = workOfficeName;
            }

            if (acquisitionYear !== undefined) {
                localVarQueryParameter['acquisitionYear'] = acquisitionYear;
            }

            if (updatedYear !== undefined) {
                localVarQueryParameter['updatedYear'] = updatedYear;
            }

            if (nextUpdateYear !== undefined) {
                localVarQueryParameter['nextUpdateYear'] = nextUpdateYear;
            }

            if (qualificationNumber !== undefined) {
                localVarQueryParameter['qualificationNumber'] = qualificationNumber;
            }

            if (qualificationStatus !== undefined) {
                localVarQueryParameter['qualificationStatus'] = qualificationStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した資格・認定保持者情報詳細を取得する
         * @summary 指定した資格・認定保持者情報詳細を取得
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAcquisitionQualificationInfo: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAcquisitionQualificationInfo', 'id', id)
            const localVarPath = `/api/conference/qualifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAcquisitionQualificationOverviews: async (qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (qualificationsId !== undefined) {
                localVarQueryParameter['qualificationsId'] = qualificationsId;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (numberString !== undefined) {
                localVarQueryParameter['numberString'] = numberString;
            }

            if (workOfficeName !== undefined) {
                localVarQueryParameter['workOfficeName'] = workOfficeName;
            }

            if (acquisitionYear !== undefined) {
                localVarQueryParameter['acquisitionYear'] = acquisitionYear;
            }

            if (updatedYear !== undefined) {
                localVarQueryParameter['updatedYear'] = updatedYear;
            }

            if (nextUpdateYear !== undefined) {
                localVarQueryParameter['nextUpdateYear'] = nextUpdateYear;
            }

            if (qualificationNumber !== undefined) {
                localVarQueryParameter['qualificationNumber'] = qualificationNumber;
            }

            if (qualificationStatus !== undefined) {
                localVarQueryParameter['qualificationStatus'] = qualificationStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の資格・認定マスタを取得する
         * @summary 指定した学会の資格・認定マスタ取得
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMaster: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchQualificationMaster', 'id', id)
            const localVarPath = `/api/conference/qualifications/masters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の資格・認定の資格・認定者一覧を取得する
         * @summary 指定した学会の資格・認定の資格・認定者一覧取得
         * @param {number} id 資格・認定マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMasterAcquisitions: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchQualificationMasterAcquisitions', 'id', id)
            const localVarPath = `/api/conference/qualifications/masters/{id}/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 学会の資格・認定マスタ一覧を取得する
         * @summary 資格・認定マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMasters: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/qualifications/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationOverviews1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchQualificationOverviews1', 'id', id)
            const localVarPath = `/api/conference/members/{id}/qualifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定保持者情報をCSVからインポートする
         * @summary 資格・認定保持者情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAcquisitionQualificationCsv: async (csvFile?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conference/qualifications/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (csvFile !== undefined) { 
                localVarFormParams.append('csvFile', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資格・認定保持者詳細を追加・更新する
         * @summary 資格・認定保持者詳細追加・更新
         * @param {AcquisitionQualificationInfo} acquisitionQualificationInfo 資格・認定保持者詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcquisitionQualification: async (acquisitionQualificationInfo: AcquisitionQualificationInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'acquisitionQualificationInfo' is not null or undefined
            assertParamExists('updateAcquisitionQualification', 'acquisitionQualificationInfo', acquisitionQualificationInfo)
            const localVarPath = `/api/conference/qualifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acquisitionQualificationInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した学会の資格・認定マスタを更新・追加する
         * @summary 指定した学会の資格・認定マスタの更新・追加
         * @param {QualificationDetail} qualificationDetail 資格・認定マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationMaster: async (qualificationDetail: QualificationDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'qualificationDetail' is not null or undefined
            assertParamExists('updateQualificationMaster', 'qualificationDetail', qualificationDetail)
            const localVarPath = `/api/conference/qualifications/masters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(qualificationDetail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QualificationRestControllerApi - functional programming interface
 * @export
 */
export const QualificationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QualificationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した資格・認定保持者詳細を削除する
         * @summary 指定した資格・認定保持者詳細の削除
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAcquisitionQualification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAcquisitionQualification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した複数の資格・認定保持者詳細を削除する
         * @summary 指定した複数の資格・認定保持者詳細の削除
         * @param {Array<number>} requestBody 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAcquisitionQualifications(requestBody: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAcquisitionQualifications(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した資格・認定マスタを削除する
         * @summary 指定した資格・認定マスタの削除
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteQualificationMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteQualificationMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定保持者をCSVにエクスポートする
         * @summary 資格・認定保持者をCSVにエクスポート
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportAcquisitionQualificationCsv(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportAcquisitionQualificationCsv(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した資格・認定保持者情報詳細を取得する
         * @summary 指定した資格・認定保持者情報詳細を取得
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAcquisitionQualificationInfo(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcquisitionQualificationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAcquisitionQualificationInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAcquisitionQualificationOverviews(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAcquisitionQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAcquisitionQualificationOverviews(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の資格・認定マスタを取得する
         * @summary 指定した学会の資格・認定マスタ取得
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationMaster(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationMaster(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の資格・認定の資格・認定者一覧を取得する
         * @summary 指定した学会の資格・認定の資格・認定者一覧取得
         * @param {number} id 資格・認定マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationMasterAcquisitions(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAcquisitionQualificationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationMasterAcquisitions(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 学会の資格・認定マスタ一覧を取得する
         * @summary 資格・認定マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationMasters(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationMasters(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchQualificationOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageQualificationOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchQualificationOverviews1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定保持者情報をCSVからインポートする
         * @summary 資格・認定保持者情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importAcquisitionQualificationCsv(csvFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importAcquisitionQualificationCsv(csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資格・認定保持者詳細を追加・更新する
         * @summary 資格・認定保持者詳細追加・更新
         * @param {AcquisitionQualificationInfo} acquisitionQualificationInfo 資格・認定保持者詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAcquisitionQualification(acquisitionQualificationInfo: AcquisitionQualificationInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcquisitionQualificationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAcquisitionQualification(acquisitionQualificationInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した学会の資格・認定マスタを更新・追加する
         * @summary 指定した学会の資格・認定マスタの更新・追加
         * @param {QualificationDetail} qualificationDetail 資格・認定マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQualificationMaster(qualificationDetail: QualificationDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQualificationMaster(qualificationDetail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QualificationRestControllerApi - factory interface
 * @export
 */
export const QualificationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QualificationRestControllerApiFp(configuration)
    return {
        /**
         * 指定した資格・認定保持者詳細を削除する
         * @summary 指定した資格・認定保持者詳細の削除
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcquisitionQualification(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAcquisitionQualification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した複数の資格・認定保持者詳細を削除する
         * @summary 指定した複数の資格・認定保持者詳細の削除
         * @param {Array<number>} requestBody 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAcquisitionQualifications(requestBody: Array<number>, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAcquisitionQualifications(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した資格・認定マスタを削除する
         * @summary 指定した資格・認定マスタの削除
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQualificationMaster(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteQualificationMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定保持者をCSVにエクスポートする
         * @summary 資格・認定保持者をCSVにエクスポート
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAcquisitionQualificationCsv(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportAcquisitionQualificationCsv(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した資格・認定保持者情報詳細を取得する
         * @summary 指定した資格・認定保持者情報詳細を取得
         * @param {number} id 資格・認定保持者詳細ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAcquisitionQualificationInfo(id: number, options?: any): AxiosPromise<AcquisitionQualificationInfo> {
            return localVarFp.fetchAcquisitionQualificationInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [qualificationsId] 資格・認定ID
         * @param {string} [userName] 会員名(漢字 カナ 英字)
         * @param {string} [numberString] 会員番号または国家資格番号
         * @param {string} [workOfficeName] 勤務先
         * @param {number} [acquisitionYear] 取得年
         * @param {number} [updatedYear] 更新年
         * @param {number} [nextUpdateYear] 次回更新年
         * @param {string} [qualificationNumber] 資格・認定番号
         * @param {string} [qualificationStatus] 資格・認定状態
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAcquisitionQualificationOverviews(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAcquisitionQualificationOverview> {
            return localVarFp.fetchAcquisitionQualificationOverviews(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の資格・認定マスタを取得する
         * @summary 指定した学会の資格・認定マスタ取得
         * @param {number} id 資格・認定マスタID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMaster(id: number, options?: any): AxiosPromise<QualificationDetail> {
            return localVarFp.fetchQualificationMaster(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の資格・認定の資格・認定者一覧を取得する
         * @summary 指定した学会の資格・認定の資格・認定者一覧取得
         * @param {number} id 資格・認定マスタID
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMasterAcquisitions(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageAcquisitionQualificationInfo> {
            return localVarFp.fetchQualificationMasterAcquisitions(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 学会の資格・認定マスタ一覧を取得する
         * @summary 資格・認定マスタ一覧取得
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationMasters(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQualificationDetail> {
            return localVarFp.fetchQualificationMasters(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQualificationOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageQualificationOverview> {
            return localVarFp.fetchQualificationOverviews1(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定保持者情報をCSVからインポートする
         * @summary 資格・認定保持者情報をCSVからインポート
         * @param {any} [csvFile] CSVファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAcquisitionQualificationCsv(csvFile?: any, options?: any): AxiosPromise<CsvImportResult> {
            return localVarFp.importAcquisitionQualificationCsv(csvFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 資格・認定保持者詳細を追加・更新する
         * @summary 資格・認定保持者詳細追加・更新
         * @param {AcquisitionQualificationInfo} acquisitionQualificationInfo 資格・認定保持者詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcquisitionQualification(acquisitionQualificationInfo: AcquisitionQualificationInfo, options?: any): AxiosPromise<AcquisitionQualificationInfo> {
            return localVarFp.updateAcquisitionQualification(acquisitionQualificationInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した学会の資格・認定マスタを更新・追加する
         * @summary 指定した学会の資格・認定マスタの更新・追加
         * @param {QualificationDetail} qualificationDetail 資格・認定マスタ詳細
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQualificationMaster(qualificationDetail: QualificationDetail, options?: any): AxiosPromise<QualificationDetail> {
            return localVarFp.updateQualificationMaster(qualificationDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QualificationRestControllerApi - object-oriented interface
 * @export
 * @class QualificationRestControllerApi
 * @extends {BaseAPI}
 */
export class QualificationRestControllerApi extends BaseAPI {
    /**
     * 指定した資格・認定保持者詳細を削除する
     * @summary 指定した資格・認定保持者詳細の削除
     * @param {number} id 資格・認定保持者詳細ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public deleteAcquisitionQualification(id: number, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).deleteAcquisitionQualification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した複数の資格・認定保持者詳細を削除する
     * @summary 指定した複数の資格・認定保持者詳細の削除
     * @param {Array<number>} requestBody 資格・認定保持者詳細ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public deleteAcquisitionQualifications(requestBody: Array<number>, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).deleteAcquisitionQualifications(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した資格・認定マスタを削除する
     * @summary 指定した資格・認定マスタの削除
     * @param {number} id 資格・認定マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public deleteQualificationMaster(id: number, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).deleteQualificationMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定保持者をCSVにエクスポートする
     * @summary 資格・認定保持者をCSVにエクスポート
     * @param {number} [qualificationsId] 資格・認定ID
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {string} [workOfficeName] 勤務先
     * @param {number} [acquisitionYear] 取得年
     * @param {number} [updatedYear] 更新年
     * @param {number} [nextUpdateYear] 次回更新年
     * @param {string} [qualificationNumber] 資格・認定番号
     * @param {string} [qualificationStatus] 資格・認定状態
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public exportAcquisitionQualificationCsv(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).exportAcquisitionQualificationCsv(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した資格・認定保持者情報詳細を取得する
     * @summary 指定した資格・認定保持者情報詳細を取得
     * @param {number} id 資格・認定保持者詳細ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchAcquisitionQualificationInfo(id: number, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchAcquisitionQualificationInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [qualificationsId] 資格・認定ID
     * @param {string} [userName] 会員名(漢字 カナ 英字)
     * @param {string} [numberString] 会員番号または国家資格番号
     * @param {string} [workOfficeName] 勤務先
     * @param {number} [acquisitionYear] 取得年
     * @param {number} [updatedYear] 更新年
     * @param {number} [nextUpdateYear] 次回更新年
     * @param {string} [qualificationNumber] 資格・認定番号
     * @param {string} [qualificationStatus] 資格・認定状態
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchAcquisitionQualificationOverviews(qualificationsId?: number, userName?: string, numberString?: string, workOfficeName?: string, acquisitionYear?: number, updatedYear?: number, nextUpdateYear?: number, qualificationNumber?: string, qualificationStatus?: string, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchAcquisitionQualificationOverviews(qualificationsId, userName, numberString, workOfficeName, acquisitionYear, updatedYear, nextUpdateYear, qualificationNumber, qualificationStatus, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の資格・認定マスタを取得する
     * @summary 指定した学会の資格・認定マスタ取得
     * @param {number} id 資格・認定マスタID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchQualificationMaster(id: number, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchQualificationMaster(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の資格・認定の資格・認定者一覧を取得する
     * @summary 指定した学会の資格・認定の資格・認定者一覧取得
     * @param {number} id 資格・認定マスタID
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchQualificationMasterAcquisitions(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchQualificationMasterAcquisitions(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 学会の資格・認定マスタ一覧を取得する
     * @summary 資格・認定マスタ一覧取得
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchQualificationMasters(page?: number, size?: number, sort?: Array<string>, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchQualificationMasters(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public fetchQualificationOverviews1(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).fetchQualificationOverviews1(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定保持者情報をCSVからインポートする
     * @summary 資格・認定保持者情報をCSVからインポート
     * @param {any} [csvFile] CSVファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public importAcquisitionQualificationCsv(csvFile?: any, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).importAcquisitionQualificationCsv(csvFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資格・認定保持者詳細を追加・更新する
     * @summary 資格・認定保持者詳細追加・更新
     * @param {AcquisitionQualificationInfo} acquisitionQualificationInfo 資格・認定保持者詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public updateAcquisitionQualification(acquisitionQualificationInfo: AcquisitionQualificationInfo, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).updateAcquisitionQualification(acquisitionQualificationInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した学会の資格・認定マスタを更新・追加する
     * @summary 指定した学会の資格・認定マスタの更新・追加
     * @param {QualificationDetail} qualificationDetail 資格・認定マスタ詳細
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualificationRestControllerApi
     */
    public updateQualificationMaster(qualificationDetail: QualificationDetail, options?: any) {
        return QualificationRestControllerApiFp(this.configuration).updateQualificationMaster(qualificationDetail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StopHistoryRestControllerApi - axios parameter creator
 * @export
 */
export const StopHistoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorStopHistoryOverviews: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceDoctorStopHistoryOverviews', 'id', id)
            const localVarPath = `/api/conference/members/{id}/stopHistories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StopHistoryRestControllerApi - functional programming interface
 * @export
 */
export const StopHistoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StopHistoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceDoctorStopHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageStopHistoryOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceDoctorStopHistoryOverviews(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StopHistoryRestControllerApi - factory interface
 * @export
 */
export const StopHistoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StopHistoryRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorStopHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageStopHistoryOverview> {
            return localVarFp.fetchConferenceDoctorStopHistoryOverviews(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StopHistoryRestControllerApi - object-oriented interface
 * @export
 * @class StopHistoryRestControllerApi
 * @extends {BaseAPI}
 */
export class StopHistoryRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StopHistoryRestControllerApi
     */
    public fetchConferenceDoctorStopHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return StopHistoryRestControllerApiFp(this.configuration).fetchConferenceDoctorStopHistoryOverviews(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpdateHistoryRestControllerApi - axios parameter creator
 * @export
 */
export const UpdateHistoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorUpdateHistoryOverviews: async (id: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchConferenceDoctorUpdateHistoryOverviews', 'id', id)
            const localVarPath = `/api/conference/members/{id}/updateHistories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateHistoryRestControllerApi - functional programming interface
 * @export
 */
export const UpdateHistoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateHistoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchConferenceDoctorUpdateHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUpdateHistoryOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchConferenceDoctorUpdateHistoryOverviews(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateHistoryRestControllerApi - factory interface
 * @export
 */
export const UpdateHistoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateHistoryRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchConferenceDoctorUpdateHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageUpdateHistoryOverview> {
            return localVarFp.fetchConferenceDoctorUpdateHistoryOverviews(id, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateHistoryRestControllerApi - object-oriented interface
 * @export
 * @class UpdateHistoryRestControllerApi
 * @extends {BaseAPI}
 */
export class UpdateHistoryRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateHistoryRestControllerApi
     */
    public fetchConferenceDoctorUpdateHistoryOverviews(id: number, page?: number, size?: number, sort?: Array<string>, options?: any) {
        return UpdateHistoryRestControllerApiFp(this.configuration).fetchConferenceDoctorUpdateHistoryOverviews(id, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}


