const _livingAbroad = [
  { text: '', value: undefined },
  { text: '海外', value: true },
  { text: '国内', value: false },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      livingAbroadItems: _livingAbroad,
    }
  },
}
