const _attendStatus = [
  { text: '未登録', value: 'UNREGISTERED' },
  { text: '出席', value: 'ATTENDANCE' },
  { text: '欠席', value: 'ABSENCE' },
]

export interface selectData {
  text: string
  value?: string
}

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      attendStatus: _attendStatus,
    }
  },
  methods: {
    judgeAttendStatus(value: string): string {
      const statusDic = this.generateAttendStatusDic(_attendStatus)
      return statusDic[value]
    },
    generateAttendStatusDic(statuses: selectData[]): { [key: string]: string } {
      const dic: { [key: string]: string } = {}
      for (const status of statuses) {
        if (status.value) {
          dic[status.value] = status.text
        } else {
          dic['UNREGISTERED'] = '未登録'
        }
      }
      return dic
    },
  },
}
