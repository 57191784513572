const _memberStatus = [
  { text: '', value: undefined },
  { text: '有効会員', value: '有効会員' },
  { text: '休会', value: '休会' },
  { text: 'その他停止', value: 'その他停止' },
  { text: '自主退会', value: '自主退会' },
  { text: '死亡退会', value: '死亡退会' },
  { text: '会費未納退会', value: '会費未納退会' },
  { text: 'その他退会', value: 'その他退会' },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      memberStatus: _memberStatus,
    }
  },
}
