const _sex = [
  { text: '', value: undefined },
  { text: '男性', value: '男性' },
  { text: '女性', value: '女性' },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      sexItems: _sex,
    }
  },
}
