

































import Vue from 'vue'
import DateInput from '@/components/DateInput.vue'
import { OfficerDetail, PositionDetail } from '@/types/api-docs'

export interface DataType {
  committee: OfficerDetail | PositionDetail
  isFormValid: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

export default Vue.extend({
  components: {
    DateInput,
  },
  props: {
    committeeProp: {
      type: Object,
      required: true,
    },
    isFormValidProp: {
      type: Boolean,
      required: true,
    },
    isConference: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      committee: this.committeeProp,
      isFormValid: this.isFormValidProp,
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'この項目は必須です',
      },
    }
  },
  watch: {
    committee: {
      handler(newDetail: OfficerDetail | PositionDetail) {
        this.$emit('update:committeeProp', newDetail)
      },
      deep: true,
    },
    isFormValid: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
  },
})
