










































import Vue from 'vue'
import Utils from '@/utils/utils'

export type DataType = {
  loginId: string
  password: string
  valid: boolean
  showPassword: boolean
  loginFail: boolean
}

export default Vue.extend({
  name: 'Login',
  props: {
    userType: {
      type: String,
      required: true,
    },
  },
  data(): DataType {
    return {
      loginId: '',
      password: '',
      valid: true,
      showPassword: false,
      loginFail: false,
    }
  },
  methods: {
    async login() {
      this.loginFail = false
      try {
        await this.$store.dispatch('login', {
          loginId: this.loginId,
          password: this.password,
        })
      } catch (e) {
        this.loginFail = true
      }
    },
    logout(): void {
      try {
        this.$store.dispatch('logout')
      } catch (e) {
        /* empty statement */
      }
    },
    async jumpForgetPassword() {
      await this.$router.push({ path: `/${this.userType}/forgetPassword`, query: Utils.getQueryParams() })
    },
  },
})
