































































































import Vue from 'vue'
import DateInput from '@/components/DateInput.vue'
import {
  Configuration,
  MeetingDetail,
  CommonConferenceRestControllerApi,
  PositionDetail,
  PositionMeetingDetail,
} from '@/types/api-docs'
import prefectures from '@/mixins/prefectures'
import TimeInput from '@/components/TimeInput.vue'

export interface DataType {
  meeting: PositionMeetingDetail
  positions?: PositionDetail[]
  isFormValid: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

const commonConferenceApi = new CommonConferenceRestControllerApi(
  new Configuration({ basePath: `${window.location.origin}` })
)
export default Vue.extend({
  mixins: [prefectures],
  components: {
    TimeInput,
    DateInput,
  },
  props: {
    meetingProp: {
      type: Object,
      required: true,
    },
    isFormValidProp: {
      type: Boolean,
      required: true,
    },
    isConference: {
      type: Boolean,
      default: false,
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      meeting: this.meetingProp,
      positions: [],
      isFormValid: this.isFormValidProp,
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'この項目は必須です',
      },
    }
  },
  watch: {
    meeting: {
      handler(newMeetingDetail: MeetingDetail) {
        this.$emit('update:meetingProp', newMeetingDetail)
      },
      deep: true,
    },
    isFormValid: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
  },
  async mounted() {
    await this.fetchPositionDetails()
  },
  methods: {
    async fetchPositionDetails() {
      const { data } = await commonConferenceApi.fetchConferencePositionDetails(this.$store.getters.conferencesId)
      this.positions = data
    },
  },
})
