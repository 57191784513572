const _studyAbroad = [
  { text: '', value: undefined },
  { text: '留学中', value: true },
  { text: '留学なし', value: false },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      studyAbroadItems: _studyAbroad,
    }
  },
}
