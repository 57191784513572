









































































































































































































































































































































































































































































































































import Vue from 'vue'
import DateInput from '@/components/DateInput.vue'
import { MeetingDetail } from '@/types/api-docs'
import prefectures from '@/mixins/prefectures'
import TimeInput from '@/components/TimeInput.vue'
import Initializer from "@/utils/initializer";
import Utils from "@/utils/utils"

export interface DataType {
  pageName: string
  meeting: MeetingDetail
  isFormValid: boolean
  isExistUrlArray: Array<boolean>
  isExistDateFromArray: Array<boolean>
  isExistDateToArray: Array<boolean>
  isExistUrl: boolean
  isExistDateFrom: boolean
  isExistDateTo: boolean
  isIndicateUrl: Array<boolean>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

export default Vue.extend({
  mixins: [prefectures],
  components: {
    TimeInput,
    DateInput,
  },
  props: {
    meetingProp: {
      type: Object,
      required: true,
    },
    isFormValidProp: {
      type: Boolean,
      required: true,
    },
    isConference: {
      type: Boolean,
      default: false,
    },
    hasDisplayableUrl: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      pageName: Utils.replaceOrganization(Utils.switchOrganization(this.$store.getters.organization, '学術集会', 'イベント'), this.$route.meta.title),
      meeting: this.meetingProp,
      isFormValid: this.isFormValidProp,
      isExistUrlArray: Array(this.meetingProp.sharedUrls ? this.meetingProp.sharedUrls.length : 0).fill(true),
      isExistDateFromArray: Array(this.meetingProp.sharedUrls ? this.meetingProp.sharedUrls.length : 0).fill(true),
      isExistDateToArray: Array(this.meetingProp.sharedUrls ? this.meetingProp.sharedUrls.length : 0).fill(true),
      isExistUrl: true,
      isExistDateFrom: true,
      isExistDateTo: true,
      isIndicateUrl: Array(this.meetingProp.sharedUrls ? this.meetingProp.sharedUrls.length : 0).fill(false),
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'この項目は必須です',
        email: (value: string) => {
          const pattern =
            /^([a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,})*$/
          return !value || pattern.test(value) || 'メールアドレスが不正です'
        },
        zipcode: (value: string) => {
          const pattern = /^([0-9]{3}-[0-9]{4})*$/
          return !value || pattern.test(value) || 'ハイフン(-)を含む半角数字8桁で入力してください'
        },
        tel: (value: string) => {
          const pattern = /^[0-9+-]*$/
          return !value || pattern.test(value) || '電話番号の形式が不正です(半角のみ)'
        },
      },
    }
  },
  watch: {
    meeting: {
      handler(newMeetingDetail: MeetingDetail) {
        this.$emit('update:meetingProp', newMeetingDetail)
      },
      deep: true,
    },
    isFormValid: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
    isExistUrl: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
    isExistDateFrom: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
    isExistDateTo: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
  },
  mounted() {
    this.$watch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (vm: DataType) => [vm.isFormValid, vm.isExistUrl, vm.isExistDateFrom, vm.isExistDateTo],
      () => {
        this.$emit(
          'update:isFormValidProp',
          this.$data.isFormValid && this.$data.isExistUrl && this.$data.isExistDateFrom && this.$data.isExistDateTo
        )
      },
      {
        deep: true,
      }
    )
  },
  methods: {
    indicateUrl(sharedUrlData: any): boolean {
      let disclosureDateFrom = new Date().toLocaleDateString()
      if (sharedUrlData.disclosureDateFrom != undefined) {
        disclosureDateFrom = sharedUrlData.disclosureDateFrom
      }
      let disclosureTimeFrom = '00:00:00'
      if (sharedUrlData.disclosureTimeFrom != undefined) {
        disclosureTimeFrom = sharedUrlData.disclosureTimeFrom
      }
      const disclosureFrom = new Date(disclosureDateFrom + ' ' + disclosureTimeFrom)
      let disclosureDateTo = new Date().toLocaleDateString()
      if (sharedUrlData.disclosureDateTo != undefined) {
        disclosureDateTo = sharedUrlData.disclosureDateTo
      }
      let disclosureTimeTo = '23:59:59'
      if (sharedUrlData.disclosureTimeTo != undefined) {
        disclosureTimeTo = sharedUrlData.disclosureTimeTo
      }
      const disclosureTo = new Date(disclosureDateTo + ' ' + disclosureTimeTo)
      const nowDate = new Date()
      return disclosureFrom <= nowDate && nowDate <= disclosureTo
    },
    removeSharedUrl(index: number) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
      this.$data.isExistUrlArray.splice(index, 1)
      this.$data.isExistDateFromArray.splice(index, 1)
      this.$data.isExistDateToArray.splice(index, 1)
      this.$data.meeting.sharedUrls.splice(index, 1)
      this.isExistUrl = this.$data.isExistUrlArray.every((b: boolean) => b)
      this.isExistDateFrom = this.$data.isExistDateFromArray.every((b: boolean) => b)
      this.isExistDateTo = this.$data.isExistDateToArray.every((b: boolean) => b)
    },
    addSharedUrl() {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
      const emptyArray: any =Initializer.emptySharedUrls(this.$data.meeting.id)
      if (!this.$data.meeting.sharedUrls) {
        this.$data.meeting.sharedUrls = []
      }
      this.$data.meeting.sharedUrls.push(emptyArray)
      let index = this.$data.meeting.sharedUrls.length -1;
      this.$data.isExistUrlArray[index] = true
      this.$data.isExistDateFromArray[index] = true
      this.$data.isExistDateToArray[index] = true
      this.isExistUrl = this.$data.isExistUrlArray.every((b: boolean) => b)
      this.isExistDateFrom = this.$data.isExistDateFromArray.every((b: boolean) => b)
      this.isExistDateTo = this.$data.isExistDateToArray.every((b: boolean) => b)
    },
    existSharedUrl(index: number, sharedUrlData: any) {
      this.$data.isExistUrlArray[index] = !!sharedUrlData.sharedUrl;
      this.isExistUrl = this.$data.isExistUrlArray.every((b: boolean) => b)
    },
    existDisclosureDateFrom(index: number, sharedUrlData: any) {
      let exist = true
      if (!sharedUrlData.disclosureDateFrom && sharedUrlData.disclosureTimeFrom) {
        exist = false
      }
      this.$data.isExistDateFromArray[index] = exist;
      this.isExistDateFrom = this.$data.isExistDateFromArray.every((b: boolean) => b)
    },
    existDisclosureDateTo(index: number, sharedUrlData: any) {
      let exist = true
      if (!sharedUrlData.disclosureDateTo && sharedUrlData.disclosureTimeTo) {
        exist = false
      }
      this.$data.isExistDateToArray[index] = exist;
      this.isExistDateTo = this.$data.isExistDateToArray.every((b: boolean) => b)
    },
  },
})
