const _items = [
  { text: 'クレジットカード', value: 'C' },
  { text: 'コンビニ決済', value: 'CONVINI' },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      paymentMethodItems: _items,
    }
  },
}
