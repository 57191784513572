





































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import DateInput from '@/components/DateInput.vue'
import {
  Configuration,
  ConferenceDoctorDetail,
  SelectValueInt,
  CommonRestControllerApi,
  CommonConferenceRestControllerApi,
  MemberTypeItem,
  ConferenceRestControllerApi,
  CheckEmailParam,
  CheckConferenceNumberParam,
  MemberTypeRestControllerApi,
} from '@/types/api-docs'
import prefectures from '@/mixins/prefectures'
import sexItems from '@/mixins/sex'
import livingAbroadItems from '@/mixins/livingAbroad'
import studyAbroadItems from '@/mixins/studyAbroad'
import memberStatus from '@/mixins/memberStatus'
import finalEducation from '@/mixins/finalEducation'
import Initializer from '@/utils/initializer'
import Utils from '@/utils/utils'

export interface DataType {
  user: ConferenceDoctorDetail
  conferenceDoctorsId?: number
  basicMedicalSpecialties?: Array<SelectValueInt>
  basicMedicalAreas?: Array<SelectValueInt>
  memberTypes?: Array<MemberTypeItem>
  memberTypeName?: string
  jobItems?: Array<SelectValueInt>
  years: Array<number>
  isFormValid: boolean
  isDuplicateEmail: boolean
  isDuplicateConferenceNumber: boolean
  isSubMailValid: boolean
  isDuplicateSubMail: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

const conferenceApi = new ConferenceRestControllerApi(new Configuration({ basePath: `${window.location.origin}` }))

const commonApi = new CommonRestControllerApi(new Configuration({ basePath: `${window.location.origin}` }))

const commonConferenceApi = new CommonConferenceRestControllerApi(
  new Configuration({ basePath: `${window.location.origin}` })
)

const memberTypeApi = new MemberTypeRestControllerApi(new Configuration({ basePath: `${window.location.origin}` }))

export default Vue.extend({
  mixins: [prefectures, sexItems, livingAbroadItems, studyAbroadItems, memberStatus, finalEducation],
  components: {
    DateInput,
  },
  props: {
    userProp: {
      type: Object,
      required: true,
    },
    isFormValidProp: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    destinationItemsProp: {
      type: Array,
      required: true,
    },
  },
  data(): DataType {
    return {
      user: this.userProp,
      conferenceDoctorsId: (this.userProp as ConferenceDoctorDetail).id,
      basicMedicalSpecialties: undefined,
      basicMedicalAreas: undefined,
      memberTypes: undefined,
      memberTypeName: undefined,
      jobItems: undefined,
      years: [...Array(new Date().getFullYear() - 1900 + 2).keys()].map((i) => 1900 + i),
      isFormValid: this.isFormValidProp,
      isDuplicateEmail: false,
      isDuplicateConferenceNumber: false,
      isSubMailValid: true,
      isDuplicateSubMail: false,
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'この項目は必須です',
        email: (value: string) => {
          const pattern =
            /^([a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,})*$/
          return !value || pattern.test(value) || 'メールアドレスが不正です'
        },
        zipcode: (value: string) => {
          const pattern = /^([0-9]{3}-[0-9]{4})*$/
          return !value || pattern.test(value) || 'ハイフン(-)を含む半角数字8桁で入力してください'
        },
        tel: (value: string) => {
          const pattern = /^[0-9+-]*$/
          return !value || pattern.test(value) || '電話番号の形式が不正です(半角のみ)'
        },
        alphaSymbol: (value: string) => {
          const pattern = /^[ -~]*$/
          return !value || pattern.test(value) || '半角で入力してください'
        },
      },
    }
  },
  watch: {
    user: {
      handler(newUser: ConferenceDoctorDetail) {
        this.$emit('update:userProp', newUser)
      },
      deep: true,
    },
    isFormValid: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      },
    },
    isSubMailValid: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', valid)
      }
    },
    isDuplicateSubMail: {
      handler(valid: boolean) {
        this.$emit('update:isFormValidProp', !valid)
      }
    },
  },
  async mounted() {
    this.modifiedToString()
    await this.fetchBasicMedicalSpecialties()
    await this.fetchBasicMedicalAreas()
    await this.fetchJobs()
    await this.fetchMemberTypes()
    await this.fetchMemberTypeName()

    this.$watch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (vm: DataType) => [vm.isFormValid, vm.isDuplicateEmail, vm.isDuplicateConferenceNumber, vm.isSubMailValid, vm.isDuplicateSubMail],
      () => {
        this.$emit(
          'update:isFormValidProp',
          this.isFormValid && !this.isDuplicateEmail && !this.isDuplicateConferenceNumber && this.isSubMailValid && !this.isDuplicateSubMail
        )
      },
      {
        deep: true,
      }
    )
  },
  methods: {
    async fetchBasicMedicalSpecialties() {
      const { data } = await commonApi.fetchBasicMedicalSpecialties(this.$store.getters.conferencesId)
      this.basicMedicalSpecialties = data
      this.basicMedicalSpecialties.unshift({ text: '', value: undefined })
    },
    async fetchBasicMedicalAreas() {
      const { data } = await commonConferenceApi.fetchBasicMedicalAreas(this.$store.getters.conferencesId)
      this.basicMedicalAreas = data
      if (this.basicMedicalAreas?.length !== 0) {
        this.basicMedicalAreas?.unshift({ text: '', value: undefined })
      }
    },
    async fetchMemberTypes() {
      if (!this.isAdmin) {
        return Promise.resolve()
      }
      const { data } = await conferenceApi.fetchConferenceMemberTypes()
      this.memberTypes = data
    },
    async fetchMemberTypeName() {
      if (!this.user || this.user.memberTypesId == undefined) {
        return
      }
      const { data } = await memberTypeApi.fetchMemberTypeName(this.user.memberTypesId)
      this.memberTypeName = data
    },
    async fetchJobs() {
      const { data } = await commonApi.fetchJobs(this.$store.getters.conferencesId)
      this.jobItems = data
      this.jobItems.unshift({ text: '', value: undefined })
    },
    removeWorkAddress: function (index: number) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
      (this.user?.workAddresses as any).splice(index, 1)
    },
    addWorkAddress: function () {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
      (this.user?.workAddresses as any).push(Initializer.emptyWorkAddress(false, this.conferenceDoctorsId))
    },
    checkMainOffice(index: number, checked: boolean) {
      if (!this.user) {
        return
      }
      const workAddresses = this.user.workAddresses
      if (checked && workAddresses) {
        for (let i = 0; i < workAddresses.length; i++) {
          if (i !== index) {
            workAddresses[i].isDestination = false
          }
        }
        this.user.workAddresses = workAddresses
      }
    },
    async checkDuplicateEmail(email: string) {
      if (!(this.$refs.email as Vue & { validate: () => boolean }).validate()) {
        return Promise.resolve()
      }

      if (!email) {
        return Promise.resolve()
      }

      const param: CheckEmailParam = {
        email: email,
        ignoreId: this.conferenceDoctorsId,
      }

      this.isDuplicateSubMail = email === this.user.subMail;

      await commonConferenceApi
        .checkConferenceDoctorDuplicateEmail(this.$store.getters.conferencesId, param)
        .then(() => {
          this.isDuplicateEmail = false
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          const res = err.response
          const status = res === undefined ? undefined : res.status
          if (status === 409) {
            this.isDuplicateEmail = true
          }
        })
    },
    async checkDuplicateConferenceNumber(conferenceNumber: string) {
      if (!(this.$refs.conferenceNumber as Vue & { validate: () => boolean }).validate()) {
        return Promise.resolve()
      }

      if (!conferenceNumber) {
        return Promise.resolve()
      }

      const param: CheckConferenceNumberParam = {
        conferenceNumber: conferenceNumber,
        ignoreId: this.conferenceDoctorsId,
      }

      await commonConferenceApi
        .checkConferenceDoctorDuplicateConferenceNumber(this.$store.getters.conferencesId, param)
        .then(() => {
          this.isDuplicateConferenceNumber = false
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((err: any) => {
          const res = err.response
          const status = res === undefined ? undefined : res.status
          if (status === 409) {
            this.isDuplicateConferenceNumber = true
          }
        })
    },
    changeStatus(status: string) {
      if (status === '有効会員') {
        this.user.stopDate = undefined
      } else if (status) {
        let msg = `状態を "${status}" に変更します。`

        if (status === '自主退会' || status === '死亡退会' || status === '会費未納退会' || status === 'その他退会') {
          msg += '\n退会状態の場合以下のようになります。\n・マイページログイン不可\n・クレジットカード情報削除'
        }
        alert(msg)
        if (!this.user.stopDate) {
          this.user.stopDate = Utils.generateTodayString()
        }
      }
    },
    checkValidSubMail() {
      if (this.user.subMail) {
        this.isSubMailValid = true
        this.isDuplicateSubMail = this.user.mail === this.user.subMail;
      } else {
        this.isDuplicateSubMail = false
        if (this.user.sendToSubMailFlag) {
          this.isSubMailValid = !!this.user.subMail;
        } else {
          this.isSubMailValid = true
        }
      }
    },
    modifiedToString() {
      const modified = this.user.modified;
      if (modified !== "" && modified !== undefined) {
        return modified.replace("T", " ");
      }
      return modified;
    },
  },
})
