<template>
  <div class="loading-mask">
    <div class="loading-spacer"></div>
    <vue-loading type="spiningDubbles" color="#aaa" :size="{ width: '100px', height: '100px' }"> </vue-loading>
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'loading',
  components: {
    VueLoading,
  },
}
</script>

<style scoped>
.loading-mask {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spacer {
  height: 30%;
}
</style>
