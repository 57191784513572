



























import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Password from 'vue-password-strength-meter'

export type DataType = {
  showNewPassword: boolean
  strengthPassword: string
  confirmPassword: string
  showConfirmPassword: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

export default Vue.extend({
  components: {
    Password,
  },
  props: {
    newPassword: {
      type: String,
      required: true,
    },
    passwordValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data(): DataType {
    return {
      showNewPassword: false,
      strengthPassword: '',
      confirmPassword: '',
      showConfirmPassword: false,
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'パスワードを入力してください',
        min: (v: string) => v.length >= 8 || '8文字以上で英字と数字の両方を含めてください',
        max: (v: string) => v.length <= 255 || '255文字以下で入力してください',
        strength: (v: string) => (/[a-zA-Z]/.test(v) && /[0-9]/.test(v)) || '英字と数字の両方を含めてください',
      },
    }
  },
  computed: {
    inputNewPassword: {
      get(): string {
        return this.newPassword
      },
      set(value: string) {
        this.strengthPassword = value
        this.$emit('update:newPassword', value)
      },
    },
    isPasswordValid: {
      get(): boolean {
        return this.passwordValid
      },
      set(value: boolean) {
        this.$emit('update:passwordValid', value)
      },
    },
  },
})
