const _bankAccountType = [
  { text: '', value: undefined },
  { text: '普通', value: '普通' },
  { text: '当座', value: '当座' },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      bankAccountTypeItems: _bankAccountType,
    }
  },
}
