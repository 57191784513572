const _finalEducation = [
    { text: '', value: undefined },
    { text: '大学院卒', value: '大学院卒' },
    { text: '大学卒', value: '大学卒' },
    { text: '短大・高専卒', value: '短大・高専卒' },
    { text: '専門学校卒', value: '専門学校卒' },
    { text: '高校卒', value: '高校卒' },
    { text: 'その他', value: 'その他' },
]

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            finalEducationItems: _finalEducation,
        }
    },
}
