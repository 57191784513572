import { ConferenceDoctorDetail, DoctorDetail } from '@/types/api-docs'
import { DataOptions } from 'vuetify'
import DOMPurify from 'dompurify'

export default class Utils {
  public static parseSortQueryParams(options: DataOptions): string[] | undefined {
    if (options.sortBy === undefined || options.sortDesc == undefined) {
      return undefined
    }
    const sortQueryParams: string[] = []
    if (options.sortBy.length > 0) {
      options.sortBy.forEach((value: string, index: number) => {
        sortQueryParams.push(`${value}${options.sortDesc[index] ? ',DESC' : ''}`)
      })
    }
    return sortQueryParams
  }

  public static generateUsername(detail?: ConferenceDoctorDetail): string {
    if (!detail) {
      return ''
    }
    return `${detail.lastName} ${detail.firstName}\n` + `${detail.lastNameKana} ${detail.firstNameKana}`
  }

  public static generateUserFullName(detail?: ConferenceDoctorDetail): string {
    if (!detail) {
      return ''
    }
    return `${detail.lastName} ${detail.firstName}`
  }

  public static generateUsernameByDoctorDetail(detail?: DoctorDetail): string {
    if (!detail) {
      return ''
    }
    return `${detail.lastName} ${detail.firstName}\n` + `${detail.lastNameKana} ${detail.firstNameKana}`
  }

  public static generateGeneralUsername(
    lastName?: string,
    firstName?: string,
    lastNameKana?: string,
    firstNameKana?: string
  ): string {
    return `${lastName} ${firstName}\n` + `${lastNameKana} ${firstNameKana}`
  }

  public static parseYear(str?: string): string {
    if (!str) {
      return ''
    }
    const year = str.match(/(\d+)-\d+-\d+/)
    if (!year) {
      return ''
    }
    return year[1]
  }

  public static formatDate(date: Date, format: string): string {
    format = format.replace(/yyyy/g, date.getFullYear().toString())
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
    return format
  }

  public static formatStringDate(date: string, format: string): string {
    return this.formatDate(new Date(date), format)
  }

  public static hasProperty<K extends string>(x: unknown, name: K): x is { [M in K]: unknown } {
    return x instanceof Object && name in x
  }

  static appName = 'Member Tree System'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public static setPageTitle(to: any): void {
    const pageTitle = !to.meta.title ? Utils.appName : to.meta.title + ' | ' + Utils.appName
    document.title = pageTitle
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getQueryParams(): any {
    let queryString = window.location.search
    const queryObject = new Object()
    if (queryString) {
      queryString = queryString.substring(1)
      const parameters = queryString.split('&')

      for (let i = 0; i < parameters.length; i++) {
        const element = parameters[i].split('=')

        const paramName = decodeURIComponent(element[0])
        const paramValue = decodeURIComponent(element[1])

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryObject[paramName] = paramValue
      }
    }
    return queryObject
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  public static printType(x: any) {
    console.log(`${typeof x} ${Object.prototype.toString.call(x)}`)
  }

  public static getUrlLastSegment(path: string): string {
    return path.substring(path.lastIndexOf('/') + 1)
  }

  public static generateTodayString(): string {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
  }

  public static sanitizeText(baseContent?: string): string {
    const content = baseContent == undefined ? '' : baseContent
    return DOMPurify.sanitize(content, {ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href','target']})
  }

  public static isValidUrl(baseContent?: string): boolean {
    const content = baseContent == undefined ? '' : baseContent
    const regex1 = /href=/i
    if (!regex1.test(content)) return true

    const regex2 = /(href=".*")/ig
    const hrefContent = DOMPurify.sanitize(content, {ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['href']})
    const match = hrefContent.match(regex2)
    try {
      if (match == null) return false
      for (let i = 0; i < match.length; i++) {
        const matchUrl = match[i]
        const urlText = matchUrl.replace('href=', '').replaceAll('"', '')
        new URL(urlText)
      }
      return true
    } catch (err) {
      return false
    }
  }

  public static switchOrganization(organization: string, conference: string, others: string): string {
    return organization === 'conference' ? conference : others
  }

  public static replaceOrganization(organization: string, title: string): string {
    const pageTitle = title.replace('organization', organization)
    document.title = pageTitle + ' | ' + Utils.appName
    return pageTitle
  }
}
