import {
  AdminDetail,
  AdminInfoDetail,
  AdminUserDetail,
  ConferenceDoctorDetail,
  ConferenceInfoDetail,
  ConferenceInfoSendListDetail,
  ConferenceManagementDetail,
  ConferenceSpecializedAreaDetail,
  ConferenceUserDetail,
  EntryMeetingDetail,
  EntryMeetingDetailProbablyAttendEnum,
  EtcPaymentDetail,
  JobDetail,
  MedicalSpecialtyCategoryDetail,
  MeetingDetail,
  MeetingsSharedUrl,
  MemberTypeDetail,
  OfficerDetail,
  OfficerMeetingDetail,
  OfficerQualificationDetail,
  PaymentDetail,
  PositionDetail,
  PositionMeetingDetail,
  PositionQualificationDetail,
  QualificationDetail,
  WorkAddress,
} from '@/types/api-docs'

export default class Initializer {
  public static initConferenceDoctorDetail(conferencesId: number): ConferenceDoctorDetail {
    return {
      // 会員情報
      id: undefined,
      conferencesId: conferencesId,
      lastName: undefined,
      firstName: undefined,
      lastNameKana: undefined,
      firstNameKana: undefined,
      lastNameEn: undefined,
      firstNameEn: undefined,
      mail: '',
      birthday: undefined,
      sex: undefined,
      jobId: undefined,
      doctorNumber: undefined,
      graduatedUniversity: undefined,
      graduatedYear: undefined,
      getDoctorLicenseYear: undefined,
      medicalSpecialtyCategoriesId: undefined,
      medicalSpecialtyDetailsIdCsv: undefined,
      medicalSpecialty: undefined,
      conferenceSpecializedAreasId: undefined,
      destination: undefined,

      // 自宅
      isLivingAbroad: false,
      postalCode: undefined,
      prefectures: undefined,
      address1: undefined,
      address2: undefined,
      tel: undefined,
      fax: undefined,

      // その他送付先
      destinationIsLivingAbroad: false,
      destinationPostalCode: undefined,
      destinationPrefectures: undefined,
      destinationAddress1: undefined,
      destinationAddress2: undefined,

      // 会員ステータス
      status: '有効会員',
      stopDate: undefined,
      stopReason: undefined,
      approvalFlag: undefined,
      entryDate: '',
      memberYears: undefined,
      expirationDate: undefined,
      memberTypesId: undefined,
      studyAbroad: undefined,
      remarks1: undefined,
      remarks2: undefined,
      remarks3: undefined,
      remarks4: undefined,
      remarks5: undefined,
      recurringCreditStatus: undefined,
      gmoMemberId: undefined,

      // 勤務先
      workAddresses: new Array(Initializer.emptyWorkAddress(true, undefined)),
    }
  }

  public static emptyWorkAddress(isMainOffice: boolean, conferenceDoctorsId?: number): WorkAddress {
    return {
      id: undefined,
      conferenceDoctorsId: conferenceDoctorsId,
      facilitiesId: undefined,
      medicalOfficesId: undefined,
      faciltyFreeTerm: undefined,
      medicalOfficeTerm: undefined,
      isDestination: isMainOffice,
      medicalOfficePostalCode: undefined,
      medicalOfficePrefectures: undefined,
      medicalOfficeAddress1: undefined,
      medicalOfficeAddress2: undefined,
      medicalOfficeTel: undefined,
      medicalOfficeFax: undefined,
      isLivingAbroad: false,
    }
  }

  public static initMeetingDetail(conferencesId: number): MeetingDetail {
    return {
      conferencesId: conferencesId,
      term: '',
      termKana: '',
      venue: '',
      dateFrom: '',
      dateTo: '',
      sharedUrls: undefined,
    } as MeetingDetail
  }

  public static initPositionMeetingDetail(positionsId?: number): PositionMeetingDetail {
    return {
      positionsId: positionsId || -1,
      term: '',
      meetingDate: '',
      meetingTimeFrom: '',
      meetingTimeTo: '',
      releaseDate: '',
    } as PositionMeetingDetail
  }

  public static initOfficerMeetingDetail(officersId?: number): OfficerMeetingDetail {
    return {
      officersId: officersId || -1,
      term: '',
      meetingDate: '',
      meetingTimeFrom: '',
      meetingTimeTo: '',
      releaseDate: '',
    } as OfficerMeetingDetail
  }

  public static initPositionDetail(conferencesId: number): PositionDetail {
    return {
      conferencesId: conferencesId,
      term: '',
    } as PositionDetail
  }

  public static initOfficerDetail(conferencesId: number): OfficerDetail {
    return {
      conferencesId: conferencesId,
      term: '',
    } as OfficerDetail
  }

  public static initPositionQualificationDetail(positionsId: number): PositionQualificationDetail {
    return {
      conferenceDoctorsId: -1,
      positionsId: positionsId,
      tenureDateFrom: '',
    } as PositionQualificationDetail
  }

  public static initOfficerQualificationDetail(officersId: number): OfficerQualificationDetail {
    return {
      conferenceDoctorsId: -1,
      officersId: officersId,
      tenureDateFrom: '',
    } as OfficerQualificationDetail
  }

  public static initQualificationDetail(conferencesId: number): QualificationDetail {
    return {
      conferencesId: conferencesId,
      term: '',
    } as QualificationDetail
  }

  public static initMemberTypeDetail(conferencesId: number): MemberTypeDetail {
    return {
      conferencesId: conferencesId,
      term: '',
      fee: 0,
      doctorPaymentCreateMonth: 0,
      doctorPaymentCreateDay: 0,
      doctorPaymentMonth: 0,
      doctorPaymentDay: 0,
    } as MemberTypeDetail
  }

  public static initConferenceSpecializedAreaDetail(conferencesId: number): ConferenceSpecializedAreaDetail {
    return {
      conferencesId: conferencesId,
      term: '',
    } as ConferenceSpecializedAreaDetail
  }

  public static initEntryMeetingDetail(
    meetingsId: number,
    probablyAttend?: EntryMeetingDetailProbablyAttendEnum
  ): EntryMeetingDetail {
    return {
      conferenceDoctorsId: -1,
      meetingsId: meetingsId,
      probablyAttend: probablyAttend,
    } as EntryMeetingDetail
  }

  public static initEtcPaymentDetail(conferencesId: number): EtcPaymentDetail {
    return {
      conferencesId: conferencesId,
      term: '',
      etceteraFee: 0,
    } as EtcPaymentDetail
  }

  public static initPaymentDetail(conferencesId: number): PaymentDetail {
    return {
      conferencesId: conferencesId,
      term: '',
      fee: 0,
    } as PaymentDetail
  }

  public static initConferenceInfoDetail(conferencesId: number): ConferenceInfoDetail {
    return {
      conferencesId: conferencesId,
      title: '',
    } as ConferenceInfoDetail
  }

  public static initAdminInfoDetail(): AdminInfoDetail {
    return {
      title: '',
    } as AdminInfoDetail
  }

  public static initConferenceManagementDetail(): ConferenceManagementDetail {
    return {
      term: '',
      gmoShopId: '',
      gmoShopPass: '',
      gmoSiteId: '',
      gmoSitePass: '',
      subdomain: '',
    } as ConferenceManagementDetail
  }

  public static initConferenceUserDetail(conferencesId: number): ConferenceUserDetail {
    return {
      conferencesId: conferencesId,
      user: '',
      mail: '',
    } as ConferenceUserDetail
  }

  public static initAdminDetail(): AdminDetail {
    return {
      companyName: '',
      officeName: '',
      postalCode: '',
      prefectures: '',
      address1: '',
      tel: '',
      mail: '',
      receptionTimeFrom: '',
      receptionTimeTo: '',
    } as AdminDetail
  }

  public static initAdminUserDetail(): AdminUserDetail {
    return {
      user: '',
      mail: '',
    } as AdminUserDetail
  }

  public static initMedicalSpecialtyCategoryDetail(): MedicalSpecialtyCategoryDetail {
    return {
      term: '',
    } as MedicalSpecialtyCategoryDetail
  }

  public static initJobDetail(): JobDetail {
    return {
      term: '',
    } as JobDetail
  }

  public static initConferenceInfoSendListDetail(conferencesId: number): ConferenceInfoSendListDetail {
    return {
      conferencesId: conferencesId,
      title: '',
      conferenceDoctorsIdCsv: '',
      conferenceDoctorDetails: new Array(Initializer.initConferenceDoctorDetail(conferencesId)),
    } as ConferenceInfoSendListDetail
  }

  public static emptySharedUrls(meetingsId?: number): MeetingsSharedUrl {
    return {
      id: undefined,
      meetingsId: meetingsId,
      sharedUrlName: undefined,
      sharedUrl: undefined,
      disclosureDateFrom: undefined,
      disclosureTimeFrom: undefined,
      disclosureDateTo: undefined,
      disclosureTimeTo: undefined,
    }
  }
}
