const _organization = [
  { text: '学会', value: 'conference' },
  { text: 'その他組織', value: 'others' },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      organizationItems: _organization,
    }
  },
}
