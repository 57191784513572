









































import { Configuration, PasswordResetInfo, PublicRestControllerApi } from '@/types/api-docs'
import Vue from 'vue'
import Loading from '@/components/Loading.vue'
import Utils from '@/utils/utils'

const api = new PublicRestControllerApi(new Configuration({ basePath: `${window.location.origin}` }))

export interface DataType {
  pageName: string
  passwordResetInfo: PasswordResetInfo
  isFormValid: boolean
  loading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any
}

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    userType: {
      type: String,
      required: true,
    },
    conferencesId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      pageName: this.$route.meta.title,
      passwordResetInfo: {
        userType: this.userType,
        conferencesId: this.conferencesId,
      } as PasswordResetInfo,
      isFormValid: true,
      loading: false,
      rules: {
        required: (value: string) => (value && !!String(value).length) || 'この項目は必須です',
        email: (value: string) => {
          const pattern =
            /^([a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,})*$/
          return !value || pattern.test(value) || 'メールアドレスが不正です'
        },
      },
    }
  },
  methods: {
    async forgetPassword() {
      this.loading = true
      if (!confirm('パスワードの再設定を行ってもよろしいですか？')) {
        this.loading = false
        return
      }

      await api
        .forgetPassword(this.passwordResetInfo)
        .then(() => {
          alert('入力されたメールアドレスにメールを送信しました。URLをクリックしてパスワードの再設定を行ってください。')
          this.$router.go(0)
        })
        .catch(() => {
          alert('入力されたメールアドレスが正しくありません。')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async backLoginPage() {
      await this.$router.push({ path: `/${this.userType}/login`, query: Utils.getQueryParams() })
    },
  },
})
