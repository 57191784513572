






























import { Configuration, PasswordResetInfo, PasswordResetTokenDetail, PublicRestControllerApi } from '@/types/api-docs'
import Vue from 'vue'
import Loading from '@/components/Loading.vue'
import PasswordForm from '@/components/PasswordForm.vue'
import Utils from '@/utils/utils'

const api = new PublicRestControllerApi(new Configuration({ basePath: `${window.location.origin}` }))

export interface DataType {
  pageName: string
  passwordResetInfo: PasswordResetInfo
  passwordResetTokenDetail: PasswordResetTokenDetail
  passwordValid: boolean
  loading: boolean
}

export default Vue.extend({
  components: {
    Loading,
    PasswordForm,
  },
  props: {
    userType: {
      type: Object,
      required: true,
    },
    conferencesId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      pageName: this.$route.meta.title,
      passwordResetInfo: {
        userType: this.userType,
        conferencesId: this.conferencesId,
        token: this.$route.query.token,
      } as PasswordResetInfo,
      passwordResetTokenDetail: {
        userType: this.userType,
      } as PasswordResetTokenDetail,
      passwordValid: true,
      loading: false,
    }
  },
  mounted() {
    this.checkPasswordToken()
  },
  methods: {
    async resetPassword() {
      this.loading = true
      if (!confirm('新しいパスワードの設定を行ってもよろしいですか？')) {
        this.loading = false
        return
      }

      await api
        .resetPassword(this.passwordResetInfo)
        .then(() => {
          alert('パスワードの設定が完了しました。')
          this.$router.push({ path: `/${this.userType}/login`, query: Utils.getQueryParams() })
        })
        .catch(() => {
          alert('パスワードの設定に失敗しました。')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async checkPasswordToken() {
      await api
        .checkPasswordToken(this.passwordResetInfo)
        .then((res) => {
          this.passwordResetTokenDetail = res.data
        })
        .catch(() => {
          // トークンの期限切れ
          alert('パスワード再設定手続きの期限が過ぎています。再度、パスワード再設定手続きを開始してください。')
          this.$router.push({ path: `/${this.userType}/login`, query: Utils.getQueryParams() })
        })
    },
  },
})
