const _resultStatus = [
  { text: '', value: undefined },
  { text: '未登録', value: null },
  { text: '合格(修了証なし)', value: 0 },
  { text: '合格(修了証あり)', value: 1 },
  { text: '合格(修了証別途発行済)', value: 3 },
  { text: '不合格', value: 2 },
]

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      resultStatus: _resultStatus,
    }
  },
}
