












































import Vue from 'vue'

export type DataType = {
  menu: boolean
}

export default Vue.extend({
  props: {
    label: String,
    value: String,
    hint: String,
    persistentHint: Boolean,
    outlined: Boolean,
    dense: Boolean,
    readonly: Boolean,
    clearable: Boolean,
    rules: Array,
    className: Object,
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data(): DataType {
    return {
      menu: false,
    }
  },
  computed: {
    picker: {
      get(): string {
        return this.value
      },
      set(val: string) {
        this.menu = false
        this.$emit('input', val)
      },
    },
  },
})
