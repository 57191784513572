



















































import Vue from 'vue'
import { UploadedFile } from '@/types/api-docs'
import Loading from '@/components/Loading.vue'

export interface DataType {
  uploadedFiles?: { [p: string]: UploadedFile }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: Array<any>
  loading: boolean
}

export default Vue.extend({
  components: {
    Loading,
  },
  props: {
    uploadedFilesProp: {
      type: Object,
      required: true,
    },
    uploadFileMethod: {
      type: Function,
      required: false,
    },
    deleteFileMethod: {
      type: Function,
      required: false,
    },
    isConference: {
      type: Boolean,
      default: false,
    },
  },
  data(): DataType {
    return {
      uploadedFiles: this.uploadedFilesProp,
      files: Array(Object.keys(this.uploadedFilesProp).length),
      loading: false,
    }
  },
  methods: {
    async uploadFile(fileNo: number, file?: File) {
      console.log('fileNo=' + fileNo)
      if (this.uploadFileMethod === null) {
        return
      }
      this.loading = true
      if (!confirm('ファイルをアップロードします。よろしいですか？')) {
        this.loading = false
        return
      }

      await this.uploadFileMethod(fileNo, file)
        .then(() => {
          alert('アップロードしました')
          this.$router.go(0)
        })
        .catch(() => {
          alert('アップロードに失敗しました')
        })
        .finally(() => {
          this.loading = false
        })
    },
    isUploaded(path: string) {
      const pattern = /^(https?:\/\/[^\s]+)+$/
      return pattern.test(path)
    },
    async deleteFile(fileNo: number) {
      if (this.deleteFileMethod === null) {
        return
      }
      this.loading = true
      if (!confirm('ファイルを削除します。よろしいですか？')) {
        this.loading = false
        return
      }

      await this.deleteFileMethod(fileNo)
        .then(() => {
          alert('削除しました')
          this.$router.go(0)
        })
        .catch(() => {
          alert('削除に失敗しました')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
