<template>
  <div class="NotFound">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <div class="display-1 mt-5">ページが見つかりません</div>
            <div class="grey--text lighten-5">お探しのページは、移動または削除された可能性があります。</div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style scoped>
.paragraph-text {
  font-size: 18px;
}
</style>
